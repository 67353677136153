import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

export interface IEditEbayCategoryModalProps {
  row?: any;
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = (fields: any[], dataObject: any) => {
  if (dataObject) {
    return dataObject;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

export default function EditEbayCategoryModal(props: IEditEbayCategoryModalProps) {
  const {title, onSubmit, onCancel, row} = props;
  const [initialValues, setInitialValues] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    const data = generateInitialValues(fields, row);
    setInitialValues(data);
  }, [row]);

  const fields = [
    {
      label: t('pricing.excludeFromPricing'),
      placeholder: t('pricing.excludeFromPricing'),
      fieldKey: 'excludeFromPricing',
      type: 'checkbox',
    },
    {
      label: t('pricing.categoryId'),
      placeholder: t('pricing.categoryId'),
      fieldKey: 'categoryId',
      type: 'autocomplete',
      options: [],
      endpoint: 'ebay_category_autocomplete_list',
      defaultValue: row?.categoryId || '',
    },
  ] as any;

  return (
    <div>
      <Typography className="modal-title">{title}</Typography>
      {initialValues ? (
        <Form
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
          minWidth="800px"
        ></Form>
      ) : null}
    </div>
  );
}
