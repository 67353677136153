import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../../components/Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';
import * as Yup from 'yup';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';
import {useFetch} from '../../hooks/fetch';
import {IShopConfig} from '../../types/ShopConfig/IShopConfig';
import {ICustomField, ICustomFieldType} from '../../types/CustomFields/CustomField';
import {getType} from '../../lib/customFields';

export interface INewProductForm {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  productObject?: any;
  customFields?: ICustomField[];
}

const getDefaultFieldValue = (field: any) => {
  if (field.fieldKeyType === 'number') {
    return 0;
  } else if (field.type === 'checkbox') {
    return false;
  } else {
    return '';
  }
};

const generateInitialValues = (fields: any[], productObject: IProduct, shopConfigs?: IShopConfig[]) => {
  if (productObject) {
    const defaults = _.omit(productObject, ['_id', '__v', 'supplierArticle']);
    return defaults;
  } else {
    let defaults = {};
    fields
      .filter((item) => !item.fieldKey.includes('productInformation'))
      .filter((item) => !item.fieldKey.includes('packageDimension'))
      .forEach((field) => {
        if (!['skus', 'eans', 'barcodes', 'mpns', 'amazonExcludedSkus'].includes(field.fieldKey)) {
          defaults = {...defaults, [field.fieldKey]: getDefaultFieldValue(field)};
        } else {
          defaults = {...defaults, [field.fieldKey]: []};
        }
      });

    const shopProps = shopConfigs
      ? shopConfigs.map((shopConfig) => {
          return {
            shopId: shopConfig._id,
            shopName: shopConfig.aliasName,
            excludeItemsPricingShop: false,
            status: ProductStatus.ACTIVE,
          };
        })
      : [];

    return {
      ...defaults,
      productId: null,
      productInformation: {
        colour: '',
        compatibility: '',
        content: '',
        doublePackage: '',
        manufacturer: '',
        minimumStock: 0,
        minimumStockReach: 0,
        minimumStockOrder: 0,
        numberOfItems: 0,
        packType: '',
        pageYield: '',
        productType: '',
      },
      options: {
        ignoreForExpired: false,
        showActualStockDDA: false,
        showActualStockSP: false,
        showActualStockAmazon: false,
        doNotCalculateAmazonMinimumMargin: false,
        excludedFromKauflandPricing: false,
        excludedFromMetroPricing: false,
        excludedFromFnacPricing: false,
        isCloseout: false,
      },
      packageDimensions: {
        height: 0,
        length: 0,
        width: 0,
        weight: 0,
      },
      lastGoodsRecipient: new Date().toISOString(),
      supplierArticle: [],
      shopProps,
      eBayProps: {
        excludedFromPricing: false,
      },
    };
  }
};

const NewProductForm = (props: INewProductForm) => {
  const {title, onSubmit, onCancel, productObject, customFields} = props;
  const [shopConfigs, getShopConfigs] = useFetch<any>('shop_config');
  const [selectedShops, setSelectedShops] = useState<IShopConfig[]>([]);
  const {t} = useTranslation();
  const fields = [];

  useEffect(() => {
    if (!productObject) {
      getShopConfigs();
    }
  }, []);

  useEffect(() => {
    if (shopConfigs.data) {
      const data = shopConfigs.data.map((item: IShopConfig) => item);
      setSelectedShops(data);
    }
  }, [shopConfigs]);

  if (productObject) {
    fields.push({
      label: t('newProductForm.title'),
      placeholder: t('newProductForm.title'),
      fieldKey: 'title',
      type: 'input',
      disabled: true,
    });
  }

  fields.push(
    ...[
      {
        label: t('newProductForm.manualTitle'),
        placeholder: t('newProductForm.manualTitle'),
        fieldKey: 'manualTitle',
        type: 'input',
      },
      {
        label: t('newProductForm.productType'),
        placeholder: t('newProductForm.productType'),
        fieldKey: 'productInformation.productType',
        type: 'input',
        required: true,
      },
      {
        label: t('newProductForm.compatibility'),
        placeholder: t('newProductForm.compatibility'),
        fieldKey: 'productInformation.compatibility',
        type: 'input',
        required: true,
      },
      {
        label: t('newProductForm.manufacturer'),
        placeholder: t('newProductForm.manufacturer'),
        fieldKey: 'productInformation.manufacturer',
        type: 'input',
        required: true,
      },
      {
        label: t('newProductForm.colour'),
        placeholder: t('newProductForm.colour'),
        fieldKey: 'productInformation.colour',
        type: 'input',
      },
      {
        label: t('newProductForm.numberOfItems'),
        placeholder: t('newProductForm.numberOfItems'),
        fieldKey: 'productInformation.numberOfItems',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.content'),
        placeholder: t('newProductForm.content'),
        fieldKey: 'productInformation.content',
        type: 'input',
      },
      {
        label: t('newProductForm.pageYield'),
        placeholder: t('newProductForm.pageYield'),
        fieldKey: 'productInformation.pageYield',
        type: 'input',
      },
      {
        label: t('productDetails.pageYieldContent'),
        placeholder: t('productDetails.pageYieldContent'),
        fieldKey: 'productInformation.pageYieldContent',
        type: 'input',
      },
      {
        label: t('newProductForm.doublePackage'),
        placeholder: t('newProductForm.doublePackage'),
        fieldKey: 'productInformation.doublePackage',
        type: 'input',
      },
      {
        label: t('newProductForm.packType'),
        placeholder: t('newProductForm.packType'),
        fieldKey: 'productInformation.packType',
        type: 'input',
      },
      {
        placeholder: t('newProductForm.image'),
        fieldKey: 'productInformation.image',
        label: t('newProductForm.image'),
        type: 'image',
      },
      {
        label: t('newProductForm.width'),
        placeholder: t('newProductForm.width'),
        fieldKey: 'packageDimensions.width',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.height'),
        placeholder: t('newProductForm.height'),
        fieldKey: 'packageDimensions.height',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.length'),
        placeholder: t('newProductForm.length'),
        fieldKey: 'packageDimensions.length',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.weight'),
        placeholder: t('newProductForm.weight'),
        fieldKey: 'packageDimensions.weight',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.minimumStock'),
        placeholder: t('newProductForm.minimumStock'),
        fieldKey: 'productInformation.minimumStock',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.minimumStockReach'),
        placeholder: t('newProductForm.minimumStockReach'),
        fieldKey: 'productInformation.minimumStockReach',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.minimumStockOrder'),
        placeholder: t('newProductForm.minimumStockOrder'),
        fieldKey: 'productInformation.minimumStockOrder',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.calculatedPrice'),
        placeholder: t('newProductForm.calculatedPrice'),
        fieldKey: 'calculatedPrice',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.shopwareId'),
        placeholder: t('newProductForm.shopwareId'),
        fieldKey: 'shopwareId',
        type: 'input',
      },
      {
        label: t('newProductForm.ddaShopwareFactor'),
        placeholder: t('newProductForm.ddaShopwareFactor'),
        fieldKey: 'ddaShopwareFactor',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.spShopwareFactor'),
        placeholder: t('newProductForm.spShopwareFactor'),
        fieldKey: 'spShopwareFactor',
        type: 'input',
        fieldKeyType: 'number',
      },
      {
        label: t('newProductForm.bechlemId'),
        placeholder: t('newProductForm.bechlemId'),
        fieldKey: 'bechlemId',
        type: 'input',
      },
      {
        label: t('newProductForm.printerIds'),
        placeholder: t('newProductForm.printerIds'),
        fieldKey: 'printerIds',
        type: 'input',
      },
      {
        label: t('newProductForm.disableBechlemSync'),
        placeholder: t('newProductForm.disableBechlemSync'),
        fieldKey: 'disableBechlemSync',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.excludeItemsPricingAmazon'),
        placeholder: t('newProductForm.excludeItemsPricingAmazon'),
        fieldKey: 'excludeItemsPricingAmazon',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.excludeItemsPricingShop'),
        placeholder: t('newProductForm.excludeItemsPricingShop'),
        type: 'checkbox-array',
        fieldKey: 'shopProps',
        checkBoxValueLabel: 'shopName',
        checkBoxValueKey: 'excludeItemsPricingShop',
      },
      {
        label: t('newProductForm.excludeItemsPricingEbay'),
        placeholder: t('newProductForm.excludeItemsPricingEbay'),
        fieldKey: 'eBayProps.excludedFromPricing',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.excludedFromKauflandPricing'),
        placeholder: t('newProductForm.excludedFromKauflandPricing'),
        fieldKey: 'options.excludedFromKauflandPricing',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.excludedFromMetroPricing'),
        placeholder: t('newProductForm.excludedFromMetroPricing'),
        fieldKey: 'options.excludedFromMetroPricing',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.excludedFromFnacPricing'),
        placeholder: t('newProductForm.excludedFromFnacPricing'),
        fieldKey: 'options.excludedFromFnacPricing',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.ignoreForExpiredProducts'),
        placeholder: t('newProductForm.ignoreForExpiredProducts'),
        fieldKey: 'options.ignoreForExpired',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.showActualStockDDA'),
        placeholder: t('newProductForm.showActualStockDDA'),
        fieldKey: 'options.showActualStockDDA',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.showActualStockSP'),
        placeholder: t('newProductForm.showActualStockSP'),
        fieldKey: 'options.showActualStockSP',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.showActualStockAmazon'),
        placeholder: t('newProductForm.showActualStockAmazon'),
        fieldKey: 'options.showActualStockAmazon',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.withoutAmazonMinimumMargin'),
        placeholder: t('newProductForm.withoutAmazonMinimumMargin'),
        fieldKey: 'options.doNotCalculateAmazonMinimumMargin',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.isCloseout'),
        placeholder: t('newProductForm.isCloseout'),
        fieldKey: 'options.isCloseout',
        type: 'checkbox',
      },
      {
        label: t('newProductForm.skus'),
        placeholder: t('newProductForm.skus'),
        fieldKey: 'skus',
        type: 'chipsInput',
      },
      {
        label: t('newProductForm.amazonExcludedSkus'),
        placeholder: t('newProductForm.amazonExcludedSkus'),
        fieldKey: 'amazonExcludedSkus',
        type: 'chipsInput',
      },
      {
        label: t('newProductForm.eans'),
        placeholder: t('newProductForm.eans'),
        fieldKey: 'eans',
        type: 'chipsInput',
      },
      {
        label: t('newProductForm.barcodes'),
        placeholder: t('newProductForm.barcodes'),
        fieldKey: 'barcodes',
        type: 'chipsInput',
      },
      {
        label: t('newProductForm.mpns'),
        placeholder: t('newProductForm.mpns'),
        fieldKey: 'mpns',
        type: 'chipsInput',
      },
    ],
  );

  if (!productObject) {
    fields.splice(11, 0, {
      label: t('newProductForm.stockDDA'),
      placeholder: t('newProductForm.stockDDA'),
      fieldKey: 'stockDDA',
      type: 'input',
      fieldKeyType: 'number',
    });
  }

  if (customFields) {
    customFields.forEach((field: ICustomField) => {
      const type = getType(field.type);
      fields.push({
        label: field.fieldName,
        placeholder: field.fieldName,
        fieldKey: `customFields.${field.fieldName}`,
        type: type,
        ...(type === 'input' ? {fieldKeyType: field.type === ICustomFieldType.NUMBER ? 'number' : 'string'} : {}),
      });
    });
  }

  const initialValues = generateInitialValues(fields, productObject, selectedShops);

  const productValidationSchema = Yup.object().shape({
    productInformation: Yup.object().shape({
      productType: Yup.string().typeError('must be a string').required('Required').nullable(),
      compatibility: Yup.string().typeError('must be a string').required('Required').nullable(),
      manufacturer: Yup.string().typeError('must be a string').required('Required').nullable(),
      minimumStock: Yup.number().typeError('must be a number').required('Required'),
      minimumStockReach: Yup.number().typeError('must be a number').required('Required'),
      minimumStockOrder: Yup.number().typeError('must be a number').required('Required'),
    }),
  });

  return (!productObject && selectedShops.length > 0) || productObject ? (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={productValidationSchema}
      ></Form>
    </>
  ) : null;
};

export default NewProductForm;
