import React, {useRef, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TableFooter,
  TablePagination,
  TableSortLabel,
  TextField,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {KeyboardArrowRight, KeyboardArrowLeft, GetApp, Done} from '@material-ui/icons';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {selectModal} from '../../modules/modal/selectors';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {useSelection} from '../../hooks/selection';
import {useTranslation} from 'react-i18next';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {DateRangeComponent} from '../DateRangeComponent/DateRangeComponent';
import {Link} from 'react-router-dom';
import {IDataTable} from './interfaces/IDataTable';
import {IHeader} from './interfaces/IHeader';
import SortIcon from './components/SortIcon';
import QueryButtonsComponents from './components/QueryButtonsComponents';
import {USER_ROLE} from '../../types/User/UserRole';
import withRoleGuard from '../../guards/Role-Guard';
import {DataTableItemType, IHeaderAccessor} from './interfaces/IHeaderAccessor';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 255,
      top: 232,
      left: '0 !important',
      right: 0,
      tranformOrigin: '0 190px',
      tranform: 'none',
      transition: 'none',
      variant: 'menu',
    },
  },
  getContentAnchorEl: null,
};

const DataTable = ({
  headers,
  endpoint,
  options,
  target,
  additionalFields,
  defaultParams,
  defaultSort,
  canShowCheckbox,
  selectionKey,
  refreshData,
  selectRowClickId,
  enableExportToCsv,
  queryButtons,
  csvFilename,
  onExport,
  numberOfItemsPerPage,
  queryParams,
  hideFilters,
  marginTop,
  storlessFetch = false,
  enableBackendCsvExport,
  isCsvLoading,
  title,
  getRowColor,
  actionButtons,
  tableHeight,
  titleComponent: TitleComponent,
  firstRowComponent: FirstRowComponent,
  aboveTableComponent: AboveTableComponent,
  additionalRowClickId,
  onDataChange,
  dataFilter,
}: IDataTable) => {
  const theme = useTheme();
  const modal = useSelector(selectModal);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numberOfItemsPerPage || 10);
  const [data, getData] = storlessFetch ? useStorlessFetch(endpoint) : useFetch(endpoint);
  const [selected, setSelected] = useSelection(selectionKey);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selection, setSelection] = useState([] as any);
  const [isAllSelected, setAllSelected] = useState(false);
  const [selectAccessor, setSelectAccessor] = useState(false);
  const {t} = useTranslation();
  const [csvData, setCsvData] = useState<any[]>([]);
  const [filter, setFilter] = useState({} as any);
  const [sort, setSort] = useState({key: defaultSort?.key, value: defaultSort?.value});
  const isFrontendCsvExportLoading = useRef(false);
  const [additionalData, setAdditionalData] = useState<any>(null);

  useEffect(() => {
    if (enableExportToCsv && data?.data?.data) {
      prepareCsvData(headers, data, setCsvData);
    }

    if (onDataChange && data?.data?.data) {
      onDataChange(data.data.data);
    }
  }, [data?.data?.data]);

  const refreshTable = () => {
    fetchData({
      page: 1,
      itemsPerPage: itemsPerPage,
      sort: {[sort.key]: sort.value},
      conditions: {...options, ...defaultParams, ...filter},
      additionalFields: additionalFields,
    });
    setPage(1);
    setItemsPerPage(numberOfItemsPerPage || 10);
  };

  const handleSortChange = async (key: string) => {
    setSelectAccessor(true);
    if (sort.key !== key) {
      setSort({key, value: 1});
    } else {
      setSort({key, value: sort.value === 1 ? -1 : 1});
    }
  };

  useEffect(() => {
    if (sort.key && selectAccessor) {
      refreshTable();
    }
  }, [sort]);

  useEffect(() => {
    refreshTable();
  }, [refreshData]);

  useEffect(() => {
    if (modal.modalStatus === 'submited') {
      refreshTable();
    }
  }, [modal.modalStatus]);

  const handleSelectAllClick = () => {
    const isChecked = !selectedAll;
    setSelectedAll(isChecked);
    if (isChecked) {
      const newSelecteds = data?.data?.data.map((row: any) => row._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const fetchData = (params: any) => {
    getData(params);
  };

  useEffect(() => {
    if (data.data?.additionalData) {
      setAdditionalData(data.data?.additionalData);
    }
  }, [data.data?.additionalData]);

  useEffect(() => {
    const data: any = {
      itemsPerPage,
      page,
      conditions: {...options, ...defaultParams, ...filter},
      additionalFields,
    };
    if (sort.key) {
      data.sort = {[sort.key]: sort.value};
    }
    fetchData({...data});
  }, [JSON.stringify(filter), JSON.stringify(options)]);

  const selectItem = (id: string, e: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    e.stopPropagation();
    setSelected(newSelected);
    setSelectedAll(newSelected.length === data?.data?.data.length);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handlePagination = async (newPage: number, newItemsPerPage?: number) => {
    setPage(newPage);
    newItemsPerPage && setItemsPerPage(newItemsPerPage);
    const sortOptions = sort.key ? {[sort.key]: sort.value} : undefined;

    fetchData({
      page: newPage,
      itemsPerPage: newItemsPerPage || itemsPerPage,
      sort: sortOptions,
      conditions: {...options, ...defaultParams, ...filter},
      additionalFields,
    });
  };
  const handleEventChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    await handlePagination(newPage);
  };

  const handleEventChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    await handlePagination(1, +event.target.value);
  };

  const convertUTCDateToLocalDate = (dateString: string, timeZone = 'Europe/Berlin') => {
    if (dateString && dateString.length > 0) {
      const dateOptions: Intl.DateTimeFormatOptions = {
        timeZone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };
      const dateFormatter = new Intl.DateTimeFormat('de-DE', dateOptions);
      const dateAsFormattedString = dateFormatter.format(new Date(dateString));
      return dateAsFormattedString;
    }
    return '';
  };

  const generateActionComponent = () => {
    const handleFirstPageButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      await handlePagination(1);
    };

    const handleBackButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      await handlePagination(page - 1);
    };

    const handleNextButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      await handlePagination(page + 1);
      await setSelectedAll(false);
    };

    const handleLastPageButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      await handlePagination(Math.ceil(data?.data?.total / itemsPerPage));
      await setSelectedAll(false);
    };

    return (
      <div className="pagination">
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 1} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon fontSize="small" /> : <FirstPageIcon fontSize="small" />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="small" /> : <KeyboardArrowLeft fontSize="small" />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(data?.data?.total / itemsPerPage)}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="small" /> : <KeyboardArrowRight fontSize="small" />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(data?.data?.total / itemsPerPage)}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon fontSize="small" /> : <LastPageIcon fontSize="small" />}
        </IconButton>
      </div>
    );
  };

  const onExportToCsvClick = () => {
    if (data?.data?.data && !enableBackendCsvExport) {
      isFrontendCsvExportLoading.current = true;
      prepareCsvData(headers, data, setCsvData);
    }

    if (onExport) {
      onExport({...options, ...defaultParams, ...filter});
      if (!enableBackendCsvExport) refreshTable();
    }
  };

  const formatColumn = (item: any, header: any) => {
    const text =
      _.get(item, header.accessor) !== undefined ? _.get(item, header.accessor) : _.get(item, header.optionalAccessor);

    if (header.callback) {
      return header.callback(item);
    } else if (header.type === DataTableItemType.DATE) {
      const timeZone = item.orderType === 'Amazon' ? 'Europe/Berlin' : header.timeZone;
      return convertUTCDateToLocalDate(_.get(item, header.accessor), timeZone);
    } else if (header.type === DataTableItemType.BOOLEAN) {
      return text ? <Done className="green" /> : '';
    } else if (header.type === DataTableItemType.IMAGE) {
      return (
        <Box className="thumbnail">
          <img src={text} className="thumbnailImage" />
        </Box>
      );
    } else if (header.format === 'price') {
      return text?.toLocaleString('de-DE', {
        style: 'currency',
        currency: header.currencyCode || 'EUR',
        minimumFractionDigits: 2,
      });
    } else if (header.translationPrefixKey) {
      return t(`${header.translationPrefixKey}.${text}`);
    } else if (header.type === DataTableItemType.LINK) {
      return (
        <a
          href={text}
          rel="noreferrer"
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {text}
        </a>
      );
    }

    return text?.toString();
  };

  const onFilterChange = (e: any, isDate?: boolean) => {
    setPage(1);
    if (!isDate && e.target?.value !== '') {
      const inputValue = e.target.type === 'number' ? +e.target.value : e.target.value;
      setFilter({...filter, [`${e.target.id}`]: inputValue});
    } else if (isDate) {
      setFilter({...filter, [`date`]: e});
    } else {
      const state: any = {...filter};
      delete state[e.target.id];
      setFilter(state);
    }
  };

  const onDropDownChange = (fieldName: string, fieldValue: string) => {
    setPage(1);
    if (fieldValue === '' || fieldValue === 'all') {
      const state: any = {...filter};
      delete state[fieldName];
      setFilter(state);
    } else {
      setFilter({...filter, [`${fieldName}`]: fieldValue});
    }
  };

  const onMultiSelectionChange = (fieldName: string, value: string) => {
    setSelection(value);
    setFilter({...filter, [`${fieldName}`]: {$in: value}});
  };

  const prepareCsvData = (headers: IHeader[], data: any, setCsvData: React.Dispatch<React.SetStateAction<any[]>>) => {
    headers = headers.filter((item) => item.name !== 'Actions');
    const csvHeader = headers.map((x) => x.name);
    const csvAccessor = headers?.map((x: any) => x.accessor);
    const csvContent = [];
    csvContent.push(csvHeader);

    csvContent.push(
      ...data?.data?.data?.map((x: any) => {
        const items: any = [];
        csvAccessor.forEach((acc) => {
          const isKeyExisting = Object.keys(x).includes(acc);

          isKeyExisting
            ? Object.values(_.pick(x, acc)).forEach((item) => {
                if (item !== null) {
                  const isKeyExisting = Object.keys(item).includes(acc);
                  isKeyExisting ? Object.values(item).forEach((y) => items.push(y)) : items.push(item);
                } else {
                  items.push('');
                }
              })
            : items.push('');
        });

        return items;
      }),
    );
    setCsvData(csvContent);
    isFrontendCsvExportLoading.current = false;
  };

  const CsvExportButton = () => {
    if (enableBackendCsvExport) {
      return (
        <Button
          variant="contained"
          className="blue-button"
          startIcon={<GetApp />}
          onClick={onExportToCsvClick}
          disabled={isCsvLoading}
        >
          CSV
        </Button>
      );
    }

    if (enableExportToCsv && !enableBackendCsvExport) {
      return (
        <CSVLink className="export__link" filename={`${csvFilename}.csv`} data={csvData}>
          <Button
            variant="contained"
            className="blue-button"
            endIcon={<GetApp />}
            onClick={onExportToCsvClick}
            disabled={isFrontendCsvExportLoading.current}
          >
            CSV
          </Button>
        </CSVLink>
      );
    }

    return <></>;
  };

  const filteredData = (data: any) => {
    if (dataFilter) {
      return dataFilter(data);
    }
    return data;
  };

  const CsvExportButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], CsvExportButton, false);
  const shouldShowActionButtonSection = title || queryButtons || enableBackendCsvExport || actionButtons;

  return (
    <>
      {additionalData && TitleComponent && <TitleComponent additionalData={additionalData} />}
      <div className="data-table-wrapper" style={{height: tableHeight || `calc(100% - ${marginTop || 0}px)`}}>
        {shouldShowActionButtonSection && (
          <div className="data-table-wrapper__action-buttons">
            {title && <div className="data-table-wrapper__title">{title}</div>}
            <CsvExportButtonWithGuard />
            <QueryButtonsComponents
              filter={filter}
              queryButtons={queryButtons}
              options={options}
              headers={headers}
              refreshTable={refreshTable}
            />
            {actionButtons}
          </div>
        )}

        {additionalData && AboveTableComponent && <AboveTableComponent additionalData={additionalData} />}
        <TableContainer
          component={Paper}
          className="table-scroll"
          style={{
            height: shouldShowActionButtonSection ? tableHeight || 'calc(100% - 65px)' : tableHeight || 'calc(100%)',
            borderRadius: title ? '0px' : '12px',
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {canShowCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedAll} onChange={handleSelectAllClick} color="primary" />
                  </TableCell>
                )}
                {headers.map((header: IHeader, index: number) => {
                  const getFilterComponent = (): React.ReactNode => {
                    const headerItem = header as IHeaderAccessor;

                    if (headerItem.type === DataTableItemType.DATE) {
                      return <DateRangeComponent name={headerItem.accessor} setState={setFilter} state={filter} />;
                    } else if (headerItem.type === DataTableItemType.MULTIPLE_SELECT) {
                      return (
                        <Select
                          multiple
                          labelId="mutiple-select-label"
                          name={headerItem.accessor}
                          id={headerItem.accessor}
                          value={selection}
                          onChange={(e) => onMultiSelectionChange(headerItem.accessor, e.target.value as any)}
                          renderValue={(selection: any) => {
                            const selectionsString = selection?.join(', ');
                            if (selectionsString.length > 18) {
                              return selectionsString.substr(0, 15) + '...';
                            }
                            return selectionsString;
                          }}
                          inputProps={{name: selection}}
                          className="multipleSelect"
                          MenuProps={MenuProps}
                        >
                          {headerItem.options?.map((item, index) => (
                            <MenuItem
                              key={item.value}
                              value={item.value}
                              onClick={(e) => {
                                setTimeout(() => {
                                  if (index === 0) {
                                    if (isAllSelected) {
                                      setAllSelected(false);
                                      setSelection([]);
                                      setFilter({
                                        ...filter,
                                        [`${headerItem.accessor}`]: {$in: []},
                                      });
                                    } else {
                                      setAllSelected(true);
                                      setSelection(headerItem.options?.map((item) => item.value));
                                      setFilter({
                                        ...filter,
                                        [`${headerItem.accessor}`]: {
                                          $in: headerItem.options?.map((item) => item.value),
                                        },
                                      });
                                    }
                                  } else {
                                    if (selection.length === Number(headerItem.options?.length)) {
                                      if (isAllSelected) {
                                        setAllSelected(false);
                                        setSelection(
                                          selection.filter((elem: any) => elem !== 'all' && elem !== item.value),
                                        );
                                        setFilter({
                                          ...filter,
                                          [`${headerItem.accessor}`]: {
                                            $in: selection.filter((elem: any) => elem !== 'all' && elem !== item.value),
                                          },
                                        });
                                      }
                                    } else {
                                      if (
                                        !selection.includes('all') &&
                                        selection.length === Number(headerItem.options?.length) - 2 &&
                                        !selection.includes(item.value)
                                      ) {
                                        setAllSelected(true);
                                        setSelection([...selection, 'all', item.value]);
                                        setFilter({
                                          ...filter,
                                          [`${headerItem.accessor}`]: {$in: [...selection, 'all', item.value]},
                                        });
                                      }
                                    }
                                  }
                                }, 0);
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox checked={isAllSelected ? selection : selection.indexOf(item.value) > -1} />
                              </ListItemIcon>
                              <ListItemText primary={item.value} />
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    } else if (headerItem.type === DataTableItemType.SELECT) {
                      return (
                        <Select
                          onChange={(e) => onDropDownChange(headerItem.accessor, e.target.value as any)}
                          value={filter[headerItem.accessor] || headerItem.options?.[0]}
                          name={headerItem.accessor}
                          id={headerItem.accessor}
                        >
                          {headerItem.options?.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    } else if (headerItem.type === DataTableItemType.BOOLEAN) {
                      const options: any[] = [
                        {value: 'all', label: 'All'},
                        {value: true, label: t('general.yes')},
                        {value: false, label: t('general.no')},
                      ];

                      return (
                        <Select
                          onChange={(e) => onDropDownChange(headerItem.accessor, e.target.value as any)}
                          value={filter[headerItem.accessor] ?? options?.[0]}
                          name={headerItem.accessor}
                          id={headerItem.accessor}
                        >
                          {options?.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    } else {
                      return (
                        <TextField
                          type={headerItem.type === 'number' ? 'number' : 'text'}
                          datatype={headerItem.type}
                          id={headerItem.accessor}
                          onChange={_.debounce(onFilterChange, 500)}
                        />
                      );
                    }
                  };

                  return header.kind === 'accessor' && header.sortable ? (
                    <TableCell key={index} style={{width: '20px !important'}}>
                      <TableSortLabel
                        hideSortIcon={true}
                        className="sort-icons"
                        onClick={() => handleSortChange(header.accessor)}
                      >
                        {header.name}
                        <SortIcon accessor={header.accessor} sort={sort} />
                      </TableSortLabel>
                      {!hideFilters ? <div>{getFilterComponent()}</div> : null}
                    </TableCell>
                  ) : (
                    <TableCell key={index} style={{width: '20px !important'}}>
                      {header.name}
                      {!hideFilters ? (
                        <div>
                          <TextField type="text" style={{visibility: 'hidden'}} />
                        </div>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {FirstRowComponent && (
                <TableRow>
                  <TableCell colSpan={headers.length}>
                    <FirstRowComponent additionalData={additionalData} />
                  </TableCell>
                </TableRow>
              )}
              {filteredData(data?.data?.data || []).map((item: any, index: number) => {
                const isItemSelected = isSelected(item._id);
                const queryString = queryParams ? `?${queryParams}` : '';
                const rowColor = getRowColor ? getRowColor(item) : 'inherit';
                const rowTargetId = additionalRowClickId ?? selectRowClickId;
                const rowLink = _.get(item, rowTargetId) ?? _.get(item, selectRowClickId);

                return (
                  <TableRow
                    style={{cursor: 'pointer', textDecoration: 'none', backgroundColor: rowColor}}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    key={index}
                    component={Link}
                    to={target ? `/${target}/${rowLink ? rowLink : item._id}${queryString}` : '#'}
                  >
                    {canShowCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} color="primary" onClick={(e) => selectItem(item._id, e)} />
                      </TableCell>
                    )}
                    {headers.map((header: IHeader, index: number) => {
                      const formattedCell = formatColumn(item, header);
                      moment.locale('de');
                      return header.kind === 'accessor' ? (
                        <TableCell key={index} title={typeof formattedCell !== 'object' ? formattedCell : ''}>
                          {formattedCell}
                        </TableCell>
                      ) : (
                        <TableCell key={index}>
                          <header.component
                            {...header.props}
                            fetchData={() => {
                              fetchData({
                                ...header.onFetchProps,
                                page,
                                itemsPerPage,
                                conditions: {...options, ...defaultParams, ...filter},
                                additionalFields,
                              });
                            }}
                            id={item._id}
                            row={item}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {((data?.data?.data || []).length === 0 || data.loading) && (
                <TableRow>
                  <TableCell
                    colSpan={canShowCheckbox ? headers.length + 1 : headers.length}
                    style={{textAlign: 'center'}}
                  >
                    {data.loading ? <CircularProgress /> : t('general.noData')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={canShowCheckbox ? headers.length + 1 : headers.length}
                count={data?.data?.total || 0}
                rowsPerPage={itemsPerPage}
                page={page - 1}
                onPageChange={handleEventChangePage}
                onRowsPerPageChange={handleEventChangeRowsPerPage}
                ActionsComponent={generateActionComponent}
                labelRowsPerPage={t('general.rowsPerPage')}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default DataTable;
