import {useDispatch, useSelector} from 'react-redux';

import {useState} from 'react';
import _ from 'lodash';
import {apiActions} from '../modules/api/actions';
import {api} from '../modules/api/ApiFactory';
import {Endpoint} from '../modules/api/endpoints';
import {selectApiState} from '../modules/api/selectors';
import {appActions} from '../modules/app/actions';

const useFetch = <T>(endpoint: Endpoint) => {
  const dispatch = useDispatch();
  const apiState = useSelector(selectApiState);
  const doFetch = (data?: any) => dispatch(apiActions.fetch(endpoint, data));
  const response = apiState[_.camelCase(endpoint)] as T;
  const remove = () => dispatch(apiActions.remove(endpoint));
  return [response, doFetch, remove];
};
const useStorlessFetch = (endpoint: Endpoint) => {
  const [fetchState, setFetchState] = useState<any>({loading: false, data: null, loaded: false, error: null});
  const dispatch = useDispatch();

  const doFetch: any = (fetchData?: any) => {
    setFetchState({...fetchState, loading: true});
    api
      .fetch(endpoint, fetchData)
      .then((res) => {
        setFetchState({
          ...fetchState,
          loading: false,
          data: res.data,
          loaded: true,
        });
      })
      .catch((e) => {
        if (api.checkIsUserUnauthorized(e.response.data?.error?.name)) {
          dispatch(appActions.doLogout());
        }
        setFetchState({...fetchState, loading: false, loaded: false, error: e.data});
      });
  };
  return [fetchState, doFetch];
};

const useStorlessUpload = (endpoint: Endpoint) => {
  const [fetchState, setFetchState] = useState<any>({loading: false, data: null, loaded: false, error: null});
  const dispatch = useDispatch();
  const doFetch: any = (fetchData?: any) => {
    setFetchState({...fetchState, loading: true});
    api
      .upload(endpoint, fetchData)
      .then((res) => {
        setFetchState({...fetchState, loading: false, data: res.data, loaded: true});
      })
      .catch((e) => {
        if (api.checkIsUserUnauthorized(e.response.data?.error?.name)) {
          dispatch(appActions.doLogout());
        }
        setFetchState({...fetchState, loading: false, loaded: false, error: e.data});
      });
  };
  return [fetchState, doFetch];
};

export {useFetch, useStorlessFetch, useStorlessUpload};
