import {Table, TableHead, TableRow, TableCell, TableBody, TextField, Select, MenuItem} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import ReturnOrderScanningContext from '../../../screens/Orders/Context/ReturnOrderScanningContext ';
import {CustomerReturnStatus} from '../../../types/CustomerReturn/CustomerReturnStatus';
import {IScannedOrderItem} from '../../../types/OrderItems/IScannedOrderItem';
import MultiBoxReturnOrderItemsTable from './MultiBoxReturnOrderItemsTable';

const ReturnedOrderItemsTable = (): ReactElement => {
  const {t} = useTranslation();
  const {scannedProducts, setScannedProducts, setProduct} = useContext(ReturnOrderScanningContext);

  const onChangeReturnedQuantity = (e: any) => {
    const quantity = +e.target.value;
    const scannedProduct = scannedProducts.find((item) => item.productId === e.target.id) as IScannedOrderItem;

    if (quantity >= 0 && quantity <= scannedProduct.quantity) {
      scannedProduct.returnQuantityScanned = quantity;
      setProduct(scannedProduct);
      setScannedProducts([...scannedProducts]);
    }
  };

  return scannedProducts?.length ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('scanner.productId')}</TableCell>
          <TableCell>EAN</TableCell>
          <TableCell>{t('scanner.product')}</TableCell>
          <TableCell>{t('scanner.returnType')}</TableCell>
          <TableCell>{t('scanner.complaintMessage')}</TableCell>
          <TableCell>{t('scanner.quantity')}</TableCell>
          <TableCell>{t('scanner.quantityScanned')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {scannedProducts.map((scannedProduct) => (
          <React.Fragment key={scannedProduct.productId}>
            <>
              <TableRow>
                <TableCell>{scannedProduct.id}</TableCell>
                <TableCell>{scannedProduct.ean}</TableCell>
                <TableCell>{scannedProduct.productTitle}</TableCell>
                <TableCell>
                  {!scannedProduct.scannedAsMultiBox && (
                    <Select
                      id="returnStatus"
                      value={scannedProduct.returnType}
                      onChange={(e: any) => {
                        if (e.target.value) {
                          scannedProduct.returnType = e.target.value;
                          setScannedProducts([...scannedProducts]);
                        }
                      }}
                      defaultValue={CustomerReturnStatus.returnedToStock}
                    >
                      {Object.values(CustomerReturnStatus)?.map((item: any) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </TableCell>
                <TableCell>
                  {!scannedProduct.scannedAsMultiBox &&
                    scannedProduct.returnType === CustomerReturnStatus.createdSupplierComplaint && (
                      <TextField
                        type="text"
                        variant="outlined"
                        value={scannedProduct.complaintMessage}
                        onChange={(e) => {
                          scannedProduct.complaintMessage = e.target.value;
                          setScannedProducts([...scannedProducts]);
                        }}
                      ></TextField>
                    )}
                </TableCell>
                <TableCell>{scannedProduct.quantity}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    id={scannedProduct.productId}
                    variant="outlined"
                    value={scannedProduct.returnQuantityScanned || 0}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: scannedProduct.quantityScanned,
                      },
                    }}
                    onChange={onChangeReturnedQuantity}
                  ></TextField>
                </TableCell>
              </TableRow>
              {scannedProduct.scannedAsMultiBox && <MultiBoxReturnOrderItemsTable product={scannedProduct} />}
            </>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  ) : (
    <></>
  );
};

export default ReturnedOrderItemsTable;
