import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {ReturnOrderScanningProvider} from '../../screens/Orders/Context/ReturnOrderScanningContext ';
import {CustomerReturnStatus} from '../../types/CustomerReturn/CustomerReturnStatus';
import {IScannedOrderItem} from '../../types/OrderItems/IScannedOrderItem';
import ReturnedOrderItemsTable from './ReturnOrderScanner/ReturnedOrderItemsTable';
import ReturnSubmitButton from './ReturnOrderScanner/ReturnSubmitButton';
import ScannedReturnedItemInfo from './ReturnOrderScanner/ScannedReturnedItemInfo';

export interface ICustomerReturnOrderScannerModal {
  onScan: (value: string) => void;
  orderId: string;
  hideDefaultCloseButton?: boolean;
}

const CustomerReturnOrderScannerModal = ({orderId, onScan}: ICustomerReturnOrderScannerModal): JSX.Element => {
  const [barcode, setBarcode] = useState('');
  const [returnedOrderScanConfirmed, setReturnedOrderScannedConfirmed] = useState(false);
  const [product, setProduct] = useState<IScannedOrderItem>(null as any);
  const [scannedProducts, setScannedProducts] = useState<IScannedOrderItem[]>([]);
  const [orderItemsByOrderId, getOrderItemsByOrderId] = useFetch<any>('orders_get_order_items');
  const [returnOrderItemStatus, setReturnOrderItemStatus] = useState<string>(CustomerReturnStatus.returnedToStock);
  const [reasonForComplaint, setReasonForComplaint] = useState('');
  const [discount, setDiscount] = useState(0);
  const [handleReturnedOrderItems, doHandleReturnedOrderItems] = useFetch<any>(
    'orders_handle_returned_scanned_order_items',
  );
  const [initialScannedProducts, setInitialScannedProducts] = useState<IScannedOrderItem[]>([]);
  const [refundShippingCosts, setRefundShippingCosts] = useState(false);
  const [skipCreationOfCreditNote, setSkipCreationOfCreditNote] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const initializeOrderItems = () => {
    getOrderItemsByOrderId({id: orderId});
  };

  const resetBarcodeInput = () => {
    const inputBarcode = document.getElementById('input-bar-code') as HTMLInputElement;
    if (inputBarcode) {
      inputBarcode.value = '';
      inputBarcode.focus();
      setBarcode('');
    }
  };

  const handleBarcodeChange = () => {
    scannedProducts
      .filter((x) => x.quantity !== x.returnQuantityScanned)
      .some((item) => {
        let articleBarCodes, productFound;

        if (item.scannedAsMultiBox) {
          articleBarCodes = item.barcodeArticleNumbers.multiBoxArticleNumbers;
          productFound = articleBarCodes.find((item) => item.barcodeArticleNumbers.includes(barcode));
        } else {
          articleBarCodes = item.barcodeArticleNumbers.barcodeArticleNumbers;
          productFound = articleBarCodes.find((code) => code === barcode);
        }

        if (productFound) {
          item.scannedAsMultiBox ? updateMultiBoxItemQuantity(item) : updateReturnScannedItemQuantity(item);
          setProduct(item);
          setScannedProducts([...scannedProducts]);
          resetBarcodeInput();
          return true;
        } else {
          if (barcode !== '') {
            setProduct(null as any);
          }
          return false;
        }
      });
  };

  const handleConfirmReturnItem = () => {
    if (returnedOrderScanConfirmed) {
      onScan(barcode);
      dispatch(appActions.showSnackBar({text: t('scanner.scanSuccess'), options: {severity: 'success'}}));
      dispatch(modalActions.closeModal());
    }

    return () => {
      setBarcode('');
      setReturnedOrderScannedConfirmed(false);
    };
  };

  const onGetOrderItems = () => {
    if (orderItemsByOrderId.data) {
      setInitialScannedProducts(_.cloneDeep(orderItemsByOrderId.data));
      setScannedProducts(orderItemsByOrderId.data);
    }
  };

  const handleReturnOrderItems = () => {
    if (handleReturnedOrderItems.data) {
      setReturnedOrderScannedConfirmed(true);
    }

    if (handleReturnedOrderItems.error) {
      dispatch(appActions.showSnackBar({text: handleReturnedOrderItems.error.name, options: {severity: 'error'}}));
    }

    return () => {
      handleReturnedOrderItems.data = null;
      handleReturnedOrderItems.error = null;
      handleReturnedOrderItems.loading = null;
    };
  };

  const updateReturnScannedItemQuantity = (item: IScannedOrderItem) => {
    if (isNaN(item.returnQuantityScanned)) {
      item.returnQuantityScanned = 0;
    }
    if (item.returnQuantityScanned < item.quantity) {
      item.returnQuantityScanned += 1;
    }
  };

  const updateMultiBoxItemQuantity = (mainProduct: IScannedOrderItem) => {
    if (mainProduct.returnQuantityScanned >= mainProduct.quantity) {
      return;
    }
    const multiBoxProductToUpdate = mainProduct.barcodeArticleNumbers.multiBoxArticleNumbers.find((mItem) =>
      mItem.barcodeArticleNumbers.find((code) => code === barcode),
    );

    if (multiBoxProductToUpdate) {
      if (isNaN(multiBoxProductToUpdate.returnQuantityScanned)) {
        multiBoxProductToUpdate.returnQuantityScanned = 0;
      }
      if (multiBoxProductToUpdate.returnQuantityScanned < multiBoxProductToUpdate.numberOfProducts) {
        multiBoxProductToUpdate.returnQuantityScanned += 1;
      }

      const isMainProductScanned = checkIfOneMainProductScanned();
      if (isMainProductScanned) {
        if (isNaN(mainProduct.returnQuantityScanned)) {
          mainProduct.returnQuantityScanned = 0;
        }
        mainProduct.returnQuantityScanned += 1;
        mainProduct.barcodeArticleNumbers.multiBoxArticleNumbers.forEach((childProduct) => {
          childProduct.returnQuantityScanned = 0;
        });
      }

      dispatch(appActions.showSnackBar({text: t('scanner.scanSuccess'), options: {severity: 'success'}}));
    }

    function checkIfOneMainProductScanned() {
      const articleScannedStatus: boolean[] = [];
      mainProduct.barcodeArticleNumbers.multiBoxArticleNumbers.forEach((item) => {
        if (item.returnQuantityScanned === item.numberOfProducts) {
          articleScannedStatus.push(true);
        } else {
          articleScannedStatus.push(false);
        }
      });
      return articleScannedStatus.every((scanStatus) => scanStatus === true);
    }
  };

  useEffect(initializeOrderItems, []);
  useEffect(onGetOrderItems, [orderItemsByOrderId]);
  useEffect(handleConfirmReturnItem, [returnedOrderScanConfirmed]);
  useEffect(handleBarcodeChange, [barcode]);
  useEffect(handleReturnOrderItems, [handleReturnedOrderItems]);

  return (
    <div className="scanner-body">
      <ReturnOrderScanningProvider
        value={{
          product,
          returnOrderItemStatus,
          reasonForComplaint,
          orderId,
          scannedProducts,
          initialScannedProducts,
          refundShippingCosts,
          skipCreationOfCreditNote,
          discount,
          setReturnOrderItemStatus,
          setBarcode,
          setReasonForComplaint,
          setScannedProducts,
          setProduct,
          setReturnedOrderScannedConfirmed,
          setRefundShippingCosts,
          setSkipCreationOfCreditNote,
          handleReturnedOrderItems,
          doHandleReturnedOrderItems,
          setDiscount,
        }}
      >
        <ScannedReturnedItemInfo />
        <ReturnedOrderItemsTable />
        <ReturnSubmitButton />
      </ReturnOrderScanningProvider>
    </div>
  );
};

export default CustomerReturnOrderScannerModal;
