import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import {
  Accordion,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  Link,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {IShopwareVariable} from '../../types/ShopConfig/IShopConfig';
import CONFIG from '../../lib/config';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';

const GalaxusPricing = () => {
  const [pricingFactorsData, getPricingFactorsData] = useStorlessFetch('pricing_factors');
  const [sendUpdatePrices, sendUpdates] = useStorlessFetch('pricing_factors_update');
  const [updateExcludedCategoriesRes, updateExcludedCategories] = useStorlessFetch('update_excluded_categories');
  const [updateCustomConfigRes, updateCustomConfig] = useStorlessFetch('update_custom_config');
  const [pricingFactors, setPricingFactors] = useState<IShopwareVariable[]>([]);
  const [excludedCategories, setExcludedCategories] = useState<any>({});
  const [sortedProducts, sortProducts] = useState(null);
  const [changedRows, setChangedRows] = useState<{[key: string]: boolean}>({});
  const [configObject, setConfigObject] = useState<any>(null);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getPricingFactorsData({featureName: 'galaxusConfig'});
  }, []);

  useEffect(() => {
    if (pricingFactorsData.data?.priceFactors) {
      setPricingFactors(pricingFactorsData.data.priceFactors);
      setExcludedCategories(pricingFactorsData.data.excludedCategories);
      setConfigObject(pricingFactorsData.data.customConfig);
    }
  }, [pricingFactorsData]);

  useEffect(() => {
    if (pricingFactors) {
      const data = groupBy(pricingFactors, 'compatibility');
      sortProducts(data);
    }
  }, [pricingFactors]);

  useEffect(() => {
    if (sendUpdatePrices.error) {
      dispatch(appActions.showSnackBar({text: sendUpdatePrices.error.name, options: {severity: 'error'}}));
    }

    if (sendUpdatePrices.loaded && !sendUpdatePrices.error) {
      dispatch(appActions.showSnackBar({text: t('pricing.dataSent'), options: {severity: 'success'}}));
    }
  }, [sendUpdatePrices]);

  useEffect(() => {
    if (updateExcludedCategoriesRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (updateExcludedCategoriesRes.error) {
      dispatch(appActions.showSnackBar({text: updateExcludedCategoriesRes.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      updateExcludedCategoriesRes.data = null;
      updateExcludedCategoriesRes.error = null;
    };
  }, [updateExcludedCategoriesRes]);

  useEffect(() => {
    if (updateCustomConfigRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setEditing(false);
    } else if (updateCustomConfigRes.error) {
      dispatch(appActions.showSnackBar({text: updateCustomConfigRes.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      updateCustomConfigRes.data = null;
      updateCustomConfigRes.error = null;
    };
  }, [updateCustomConfigRes]);

  const groupBy = (items: any[], property: any) => {
    return items.reduce((accumulator: any, current: any) => {
      const key = current[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(current);
      return accumulator;
    }, {});
  };

  const sendPrices = (item: any) => {
    if (item.compatibility === 'undefined') {
      item.compatibility = undefined;
    }
    sendUpdates({featureName: 'galaxusConfig', ...item});
  };

  const productTypeSortCondition = (a: any, b: any) => (a[0] > b[0] ? 1 : -1);
  const manufacturerConditionSort = (a: any, b: any) => (a.manufacturer > b.manufacturer ? 1 : -1);

  const handleCategoryCheckboxChange = (itemElement: any, isChecked: boolean) => {
    setExcludedCategories((prevState: any) => ({
      ...prevState,
      [itemElement]: isChecked,
    }));

    setChangedRows((prevChangedRows) => ({
      ...prevChangedRows,
      [itemElement]: true,
    }));
  };

  const handleSaveExcludedCategories = (categoryKey: string) => {
    updateExcludedCategories({featureName: 'galaxusConfig', [categoryKey]: excludedCategories[categoryKey]});

    setChangedRows((prevChangedRows) => ({
      ...prevChangedRows,
      [categoryKey]: false,
    }));
  };

  const IconEditButton = () => (
    <IconButton
      onClick={() => {
        setEditing((prevState) => {
          return !prevState;
        });
      }}
    >
      <EditIcon htmlColor={editing ? '#5cabef' : 'none'} style={{fontSize: 20}} />
    </IconButton>
  );

  const updateObjectState = (value: any, fieldName: string, nestedObjectName?: string) => {
    setConfigObject((prevState: any) => {
      const updateData = nestedObjectName
        ? {
            [nestedObjectName]: {
              ...prevState[nestedObjectName],
              [fieldName]: value,
            },
          }
        : {[fieldName]: value};

      return {...prevState, ...updateData};
    });
  };

  const onConfigObjectSave = () => {
    updateCustomConfig({featureName: 'galaxusConfig', customConfig: {...configObject}});
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.UPDATE_SHOP_FACTORS, {
        featureName: 'galaxusConfig',
        callback: () => {
          getPricingFactorsData({featureName: 'galaxusConfig'});
          dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    configObject && (
      <>
        <div className="customer-details" style={{width: 'unset'}}>
          <div className="center-content">
            <div className="customer-details__header">
              <p>{t('general.galaxusPricing')}</p>
              <Button variant="contained" color="primary" className="blue-button" onClick={handleClick}>
                {t('pricing.updateShopFactors')}
              </Button>
            </div>
          </div>
          <div className="order-details">
            <div className="order-info">
              <Grid container spacing={0} alignItems="center">
                <Grid xs={6}>
                  <div className="order-info-title">FTP Config</div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  alignContent="flex-end"
                  alignItems="flex-end"
                  style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
                ></Grid>
                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">FTP server:</span>
                  ftp.digitecgalaxus.ch
                </Grid>
                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">FTP username</span>
                  druckdichaus
                </Grid>
                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">FTP password</span>
                  1E5i43raFR3dESTlthuG
                </Grid>
                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}></Grid>
              </Grid>
            </div>
            <div className="order-info">
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={6}>
                  <div className="order-info-title">Config</div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  alignContent="flex-end"
                  alignItems="flex-end"
                  style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
                >
                  <IconEditButton />
                  {editing && (
                    <Button className="blue-button" variant="contained" onClick={onConfigObjectSave}>
                      {t('general.save')}
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">{t('pricing.taxPercentage')}</span>
                  {editing ? (
                    <TextField
                      className="product-info-input"
                      variant="standard"
                      size="small"
                      type="number"
                      value={configObject?.taxPercentage}
                      InputProps={{inputProps: {min: 0, step: 0.01}}}
                      onChange={(event) => {
                        updateObjectState(+event.target.value, 'taxPercentage');
                      }}
                    />
                  ) : (
                    <div className="order-info-label">{configObject?.taxPercentage}</div>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">{t('pricing.feePercentage')}</span>
                  {editing ? (
                    <TextField
                      className="product-info-input"
                      variant="standard"
                      size="small"
                      type="number"
                      value={configObject?.fee}
                      InputProps={{inputProps: {min: 0, step: 0.01}}}
                      onChange={(event) => {
                        updateObjectState(+event.target.value, 'fee');
                      }}
                    />
                  ) : (
                    <div className="order-info-label">{configObject?.fee}</div>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">{t('pricing.shippingCost')}</span>
                  {editing ? (
                    <TextField
                      className="product-info-input"
                      variant="standard"
                      size="small"
                      type="number"
                      value={configObject?.shippingCost}
                      InputProps={{inputProps: {min: 0, step: 1}}}
                      onChange={(event) => {
                        updateObjectState(+event.target.value, 'shippingCost');
                      }}
                    />
                  ) : (
                    <div className="order-info-label">{configObject?.shippingCost}</div>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">{t('pricing.priceList')}</span>
                  <Typography style={{alignSelf: 'center'}}>
                    <Link href={`${CONFIG.api}static/ProductData_druckdichaus.csv`}>Download Preislist</Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">{t('pricing.priceType')}</span>
                  <div className="product-info-input">
                    <FormControl component="fieldset" disabled={!editing}>
                      <RadioGroup
                        row
                        aria-label="priceType"
                        name="priceType"
                        value={configObject?.priceType}
                        onChange={(event) => updateObjectState(event.target.value, 'priceType')}
                      >
                        <FormControlLabel value="net" control={<Radio />} label="Net price" />
                        <FormControlLabel value="gross" control={<Radio />} label="Gross price" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="order-info">
              <div className="pricing">
                {Object.entries(sortedProducts || [])
                  .sort(([key], [key2]) => key2.localeCompare(key))
                  .map((item: any, index: any) => (
                    <Accordion className="productsContent" key={index} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h1 className="productType" key={index}>
                          {item[0] !== 'undefined' ? item[0] : t('pricing.undefined')}
                        </h1>
                      </AccordionSummary>
                      <div>
                        {(Object.entries(groupBy(item[1], 'productType')) || [])
                          .sort(productTypeSortCondition)
                          .map((itemElement: any, inx: any) => {
                            const itemElementValue = `${itemElement?.[1]?.[0]?.compatibility};${itemElement[0]}`;

                            return (
                              <Accordion key={inx}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div style={{display: 'flex', width: '100%', alignItems: 'center', height: '20px'}}>
                                    <h2 className="productType" key={index} style={{width: '100%'}}>
                                      {itemElement[0]}
                                    </h2>
                                    <span style={{float: 'right', width: '30%', textAlign: 'right'}}>
                                      <Checkbox
                                        color="primary"
                                        name="excludeFromPricing"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e: any) => {
                                          handleCategoryCheckboxChange(itemElementValue, e.target.checked);
                                        }}
                                        checked={excludedCategories[itemElementValue]}
                                      />
                                      {excludedCategories[itemElementValue]}
                                      Excluded?
                                      {changedRows[itemElementValue] && (
                                        <Button
                                          variant="contained"
                                          className="blue-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleSaveExcludedCategories(itemElementValue);
                                          }}
                                        >
                                          Save
                                        </Button>
                                      )}
                                    </span>
                                  </div>
                                </AccordionSummary>
                                <div className="productPricing productHead">
                                  <p className="manufacturer">{t('pricing.manufacturer')}</p>
                                  <p style={{textAlign: 'right'}}>{t('pricing.shopFactor')}</p>
                                  <p></p>
                                </div>
                                {itemElement[1]
                                  .sort(manufacturerConditionSort)
                                  .map((singleProduct: IShopwareVariable, index: any) => (
                                    <div className="productPricing" key={index}>
                                      <p className="manufacturer">{singleProduct.manufacturer}</p>
                                      <p style={{textAlign: 'right'}}>
                                        <input
                                          id={'shopFactor' + index}
                                          type="number"
                                          min="0"
                                          step="0.01"
                                          value={singleProduct.shopFactor}
                                          onChange={(e: any) => {
                                            const newPricingFactors = [...pricingFactors];
                                            const pricingFactor = newPricingFactors.find(
                                              (item: IShopwareVariable) =>
                                                item.productType === singleProduct.productType &&
                                                item.manufacturer === singleProduct.manufacturer &&
                                                item.compatibility === singleProduct.compatibility,
                                            );
                                            if (pricingFactor) {
                                              pricingFactor.shopFactor = e.target.value;
                                              setPricingFactors(newPricingFactors);
                                            }
                                          }}
                                        />
                                      </p>
                                      <p>
                                        <Button
                                          variant="contained"
                                          className="blue-button"
                                          onClick={() => {
                                            sendPrices({
                                              manufacturer: singleProduct.manufacturer,
                                              productType: itemElement[0],
                                              compatibility: item[0],
                                              shopFactor: singleProduct.shopFactor,
                                            });
                                          }}
                                        >
                                          {t('pricing.submitChanges')}
                                        </Button>
                                      </p>
                                    </div>
                                  ))}
                              </Accordion>
                            );
                          })}
                      </div>
                    </Accordion>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
export default GalaxusPricing;
