import {Field, Formik, Form as FormikForm, FormikHelpers, FastField} from 'formik';
import {
  Checkbox,
  FormLabel,
  FormControl,
  TextField,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputLabel,
  OutlinedInput,
  Select,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker, DateTimePicker} from '@material-ui/pickers';
import React, {useEffect, useRef, useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {findDOMNode} from 'react-dom';
import ChipsInput from '../ChipsInput/ChipsInput';
import AutocompleteComponent from '../Autocomplete/Autocomplete';
import * as Yup from 'yup';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import FormImage from './FormImage';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {Endpoint} from '../../modules/api/endpoints';
import DescriptionCounter from '../Product/ShopDescriptions/DescriptionCounter';

interface IFieldBase {
  label?: string;
  placeholder?: string | string[];
  fieldKey: string | string[];
  type: string;
  fieldKeyType?: string;
  disabled?: boolean;
  step?: number;
  required?: boolean;
  checkBoxValueKey?: string;
  checkBoxValueLabel?: string;
  defaultValue?: any;
  endpoint?: Endpoint;
  itemStyle?: {xs: number; sm: number; md: number};
  /**Show chars counter component if the maxChars has a value bigger than 0 */
  maxChars?: number;
  onChange?: any;
}

interface IOption {
  value: string;
  label: string;
}

interface IFieldSelect extends IFieldBase {
  options: IOption[];
}

interface IFieldAutocomplete extends IFieldBase {
  options: IOption[];
  onInputChange?: (searchText: string) => void;
  defaultValue?: any;
  filters?: any;
}

export type IField = IFieldBase | IFieldSelect | IFieldAutocomplete;

interface IFormProps {
  initialValues: any; //no need since it will be regulated over store
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;
  onCancel?: () => void;
  className?: string;
  fields: IField[];
  submitButtonText: string;
  cancelButtonText?: string;
  resetlButtonText?: string;
  validationSchema?: Yup.ObjectSchema<any>;
  onChange?: (data: any) => void;
  values?: any;
  isFilter?: boolean;
  isFilterReset?: boolean;
  resetFilters?: () => void;
  title?: string;
  triggerSubmit?: number;
  isLoading?: boolean;
  width?: string;
  minWidth?: string;
  triggerOnCloseAfterSubmit?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 255,
      top: 200,
      left: ' 0 !important',
      right: 0,
      tranformOrigin: 'none',
      tranform: 'none',
      transition: 'none',
    },
  },
};

const Form = (props: IFormProps) => {
  const [selected, setSelected] = useState<any>([]);
  const {t} = useTranslation();
  const {
    initialValues,
    onSubmit,
    onCancel,
    resetFilters,
    fields,
    validationSchema,
    cancelButtonText = t('general.cancel'),
    resetlButtonText = t('general.resetAll'),
    title,
    minWidth,
    triggerOnCloseAfterSubmit = true,
  } = props;

  const HandleChange = (event: any) => {
    event.stopPropagation();
    const value = event.target.value;
    if (value !== undefined) setSelected(value);
  };

  //dynamic width for label on select in top left corner
  const inputLabelRefs: any = {};
  const [labelWidths, setLabelWidths] = useState({});
  const useStyles = makeStyles(() => ({
    gridContainer: {
      maxWidth: fields.length < 9 ? '30em' : 'auto',
      margin: '0 auto',
      minWidth: minWidth,
    },
  }));
  const classes = useStyles();
  const formRef = useRef(null);
  const submitForm = () => {
    const current = formRef.current as any;
    current.submitForm();
  };
  useEffect(() => {
    if (props?.triggerSubmit && props?.triggerSubmit > 0) {
      submitForm();
    }
  }, [props.triggerSubmit]);

  useEffect(() => {
    Object.keys(inputLabelRefs).forEach(async (ref: string) => {
      // eslint-disable-next-line react/no-find-dom-node
      const width = await (findDOMNode(inputLabelRefs[ref]) as HTMLInputElement)?.offsetWidth;
      await setLabelWidths({
        ...labelWidths,
        [`${ref}`]: width,
      });
    });
  }, []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const {resetForm} = actions;
        if (onSubmit) {
          await onSubmit(values, actions);
          triggerOnCloseAfterSubmit && resetForm();
        }
      }}
      onReset={async () => {
        if (onCancel) await onCancel();
      }}
    >
      {({handleChange, values, touched, errors, setFieldValue}) => {
        if (props.isFilter) {
          //@ts-ignore
          handleChange = props.onChange;
          values = props.values;
        }

        const getItemStyle = (itemStyle?: {xs: number; sm: number; md: number}) => {
          if (itemStyle) {
            return itemStyle;
          }

          return fields.length > 12 ? {xs: 12, sm: 6, md: 3} : {xs: 12, sm: 12, md: 12};
        };

        const shouldComponentUpdate = (nextProps: any, currentProps: any) => {
          return nextProps.value !== currentProps.value || nextProps.onChange !== currentProps.onChange;
        };

        return (
          <>
            <FormikForm>
              <Grid container spacing={0} className={classes.gridContainer}>
                {title && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="modal-title">{title}</Typography>
                  </Grid>
                )}
                <Grid className="modal-fields" item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    {(fields || []).map((field: IField, fieldIndex: number) => {
                      const itemStyle: any = getItemStyle(field.itemStyle);

                      const {
                        type,
                        label,
                        placeholder,
                        fieldKey,
                        fieldKeyType = 'text',
                        disabled = false,
                        step,
                        required = false,
                        checkBoxValueKey,
                        checkBoxValueLabel,
                        defaultValue,
                        endpoint,
                      } = field;
                      const key = fieldIndex + '_' + type;
                      const displayValidationErrorMessage = _.get(errors, fieldKey) && (
                        <span style={{color: 'red'}}>{_.get(errors, fieldKey)}</span>
                      );

                      switch (type) {
                        case 'input':
                          return (
                            <Grid
                              item
                              xs={itemStyle.xs}
                              sm={itemStyle.sm}
                              md={itemStyle.md}
                              key={key}
                              style={{display: 'flex', alignItems: 'center'}}
                            >
                              <FormControl>
                                <FastField
                                  className="input"
                                  size="small"
                                  component={TextField}
                                  id={fieldKey}
                                  name={fieldKey}
                                  disabled={disabled}
                                  value={_.get(values, fieldKey)}
                                  placeholder={placeholder || ''}
                                  onChange={handleChange}
                                  variant="outlined"
                                  label={label}
                                  type={fieldKeyType}
                                  shouldUpdate={shouldComponentUpdate}
                                  inputProps={{name: fieldKey, step: step}}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                  }}
                                  error={Boolean(touched.name && errors.name)}
                                  required={required}
                                  helperText={touched.name && errors.name}
                                />
                                {displayValidationErrorMessage}
                              </FormControl>
                              {field.maxChars && (
                                <DescriptionCounter
                                  text={_.get(values, fieldKey)}
                                  maxChars={field.maxChars}
                                  shippingType={_.get(values, 'shippingType')}
                                />
                              )}
                            </Grid>
                          );
                        case 'multiline':
                          return (
                            <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md} key={key}>
                              <FormControl>
                                <FastField
                                  className="input"
                                  size="small"
                                  component={TextField}
                                  multiline
                                  minRows={7}
                                  id={fieldKey}
                                  name={fieldKey}
                                  disabled={disabled}
                                  value={_.get(values, fieldKey)}
                                  placeholder={placeholder || ''}
                                  onChange={handleChange}
                                  variant="outlined"
                                  label={label}
                                  type={fieldKeyType}
                                  shouldUpdate={shouldComponentUpdate}
                                  inputProps={{name: fieldKey, step: step}}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                  }}
                                  error={Boolean(touched.name && errors.name)}
                                  required={required}
                                  helperText={touched.name && errors.name}
                                />
                                {displayValidationErrorMessage}
                              </FormControl>
                            </Grid>
                          );
                        case 'select':
                          return (
                            <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md} key={key}>
                              <FormControl key={key} size="small">
                                <TextField
                                  variant="outlined"
                                  className="input"
                                  size="small"
                                  label={label}
                                  select
                                  disabled={disabled}
                                  value={_.get(values, fieldKey)}
                                  onChange={handleChange}
                                  inputProps={{name: fieldKey}}
                                  error={Boolean(touched.name && errors.name)}
                                  required={required}
                                >
                                  {((field as IFieldSelect).options || []).map((option: IOption) => {
                                    const {value, label} = option;
                                    return (
                                      <MenuItem key={value} value={value} style={{minHeight: '25px'}}>
                                        {label}
                                      </MenuItem>
                                    );
                                  })}
                                </TextField>
                                {displayValidationErrorMessage}
                              </FormControl>
                            </Grid>
                          );
                        case 'multipleselection': {
                          return (
                            <Grid
                              item
                              xs={itemStyle.xs}
                              sm={itemStyle.sm}
                              md={itemStyle.md}
                              key={key}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <FormControl
                                key={key}
                                variant="outlined"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                size="small"
                              >
                                <InputLabel
                                  id={`${fieldKey as string}Label`}
                                  ref={(ref) => {
                                    inputLabelRefs[`${fieldKey as string}Label`] = ref;
                                  }}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {label}
                                </InputLabel>
                                <Select
                                  variant="outlined"
                                  className="input"
                                  labelId="mutiple-select-label"
                                  multiple
                                  value={_.get(values, fieldKey) || []}
                                  onChange={handleChange}
                                  onClick={HandleChange}
                                  renderValue={(selected: any) => selected.join(', ')}
                                  inputProps={{name: fieldKey}}
                                  input={
                                    <OutlinedInput
                                      // @ts-ignore
                                      labelWidth={labelWidths[`${fieldKey as string}Label`]}
                                      name={`${fieldKey as string}Label`}
                                      id={`${fieldKey as string}Label`}
                                    />
                                  }
                                  MenuProps={MenuProps}
                                  error={Boolean(touched.name && errors.name)}
                                  required={required}
                                >
                                  {((field as IFieldSelect).options || []).map((option: IOption, index: any) => {
                                    if (option.value == 'all') return;
                                    const {value, label} = option;
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                        onChange={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            checked={
                                              selected == 'all'
                                                ? value != undefined
                                                : _.get(values, fieldKey)?.includes(value)
                                            }
                                          />
                                        </ListItemIcon>
                                        <ListItemText primary={value} />
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          );
                        }

                        case 'autocomplete':
                          return (
                            <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md} key={key}>
                              <FormControl key={key} variant="outlined">
                                <Field
                                  variant="outlined"
                                  className="input"
                                  size="small"
                                  defaultValue={defaultValue}
                                  component={AutocompleteComponent}
                                  id={fieldKey}
                                  placeholder={placeholder}
                                  label={label}
                                  options={(field as IFieldAutocomplete).options}
                                  filters={(field as IFieldAutocomplete).filters}
                                  onInputChange={(field as IFieldAutocomplete).onInputChange}
                                  onChange={(value: any) => {
                                    if (field.onChange) {
                                      setFieldValue(fieldKey as string, value);
                                      field.onChange(null, value, setFieldValue);
                                    } else {
                                      setFieldValue(fieldKey as string, value);
                                    }
                                  }}
                                  endpoint={endpoint}
                                  error={Boolean(touched.name && errors.name)}
                                  required={required}
                                  helperText={touched.name && errors.name}
                                />
                                {displayValidationErrorMessage}
                              </FormControl>
                            </Grid>
                          );
                        case 'date':
                          return (
                            <MuiPickersUtilsProvider utils={DateFnsUtils} key={key}>
                              <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md}>
                                <FormControl>
                                  <Field
                                    variant="outlined"
                                    className="input"
                                    size="small"
                                    component={KeyboardDatePicker}
                                    id={fieldKey}
                                    name={fieldKey}
                                    placeholder={placeholder}
                                    autoOk
                                    format="dd.MM.yyyy"
                                    InputAdornmentProps={{position: 'start'}}
                                    label={label}
                                    value={_.get(values, fieldKey) ? (_.get(values, fieldKey) as Date) : null}
                                    inputVariant="outlined"
                                    onChange={(value: any) => {
                                      setFieldValue(fieldKey as string, value ? (value as Date) : null);
                                    }}
                                    error={Boolean(touched.name && errors.name)}
                                    required={required}
                                    helperText={touched.name && errors.name}
                                  />
                                  {displayValidationErrorMessage}
                                </FormControl>
                              </Grid>
                            </MuiPickersUtilsProvider>
                          );
                        case 'dateTime':
                          return (
                            <MuiPickersUtilsProvider utils={DateFnsUtils} key={key}>
                              <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md}>
                                <FormControl>
                                  <Field
                                    variant="outlined"
                                    className="input"
                                    size="small"
                                    component={DateTimePicker}
                                    ampm={false}
                                    id={fieldKey}
                                    name={fieldKey}
                                    placeholder={placeholder}
                                    autoOk
                                    format="dd/MM/yyyy HH:mm"
                                    InputAdornmentProps={{position: 'start'}}
                                    label={label}
                                    value={_.get(values, fieldKey)}
                                    inputVariant="outlined"
                                    onChange={(value: any) => {
                                      setFieldValue(fieldKey as string, value);
                                    }}
                                    error={Boolean(touched.name && errors.name)}
                                    required={required}
                                    helperText={touched.name && errors.name}
                                  />
                                  {displayValidationErrorMessage}
                                </FormControl>
                              </Grid>
                            </MuiPickersUtilsProvider>
                          );
                        case 'dateRange':
                          return (
                            <MuiPickersUtilsProvider utils={DateFnsUtils} key={key}>
                              <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md}>
                                <FormControl>
                                  <Field
                                    variant="outlined"
                                    className="input"
                                    size="small"
                                    component={DateRangePicker}
                                    id={fieldKey}
                                    placeholder={placeholder}
                                    autoOk
                                    format="dd/MM/yyyy"
                                    InputAdornmentProps={{position: 'start'}}
                                    label={label}
                                    value={_.get(values, fieldKey)}
                                    inputVariant="outlined"
                                    onChange={(value: any) => {
                                      setFieldValue(fieldKey as string, value);
                                    }}
                                    error={Boolean(touched.name && errors.name)}
                                    required={required}
                                    helperText={touched.name && errors.name}
                                  />
                                  {displayValidationErrorMessage}
                                </FormControl>
                              </Grid>
                            </MuiPickersUtilsProvider>
                          );
                        case 'chipsInput':
                          return (
                            <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md} key={key}>
                              <FormControl size="small">
                                <FastField
                                  className="input"
                                  size="small"
                                  component={ChipsInput}
                                  id={fieldKey}
                                  placeholder={placeholder}
                                  label={label}
                                  value={_.get(values, fieldKey)}
                                  variant="outlined"
                                  onChange={(value: any) => {
                                    setFieldValue(fieldKey as string, value);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          );
                        case 'checkbox':
                          return (
                            <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md} key={key}>
                              <FormControl>
                                <span style={{display: 'inline-flex', alignItems: 'center'}}>
                                  <Field
                                    component={Checkbox}
                                    name="checkbox"
                                    id={fieldKey}
                                    disabled={disabled}
                                    value={_.get(values, fieldKey)}
                                    onChange={handleChange}
                                    variant="outlined"
                                    label={label}
                                    type="checkbox"
                                    color="primary"
                                    checked={_.get(values, fieldKey)}
                                  />
                                  <span>{label}</span>
                                </span>
                                {displayValidationErrorMessage}
                              </FormControl>
                            </Grid>
                          );
                        case 'checkbox-array':
                          return _.get(values, fieldKey)?.map((item: any, index: any) => {
                            const onChangeCheckBoxArray = (e: any) => {
                              const newState = [..._.get(values, fieldKey)];
                              const newItem: any = newState.find(
                                (element: any) =>
                                  element[checkBoxValueLabel as any] === item[checkBoxValueLabel as any],
                              );
                              if (newItem) {
                                newItem[checkBoxValueKey as any] = e.target.checked;
                                setFieldValue(fieldKey as string, newState);
                              }
                            };
                            return (
                              <Grid
                                item
                                xs={itemStyle.xs}
                                sm={itemStyle.sm}
                                md={itemStyle.md}
                                key={index + item[checkBoxValueLabel as any]}
                              >
                                <FormControl>
                                  <span style={{display: 'inline-flex', alignItems: 'center'}}>
                                    <Field
                                      component={Checkbox}
                                      name="checkbox"
                                      id={fieldKey + item[checkBoxValueLabel as any]}
                                      disabled={disabled}
                                      value={item[checkBoxValueKey as any]}
                                      onChange={onChangeCheckBoxArray}
                                      variant="outlined"
                                      label={label}
                                      type="checkbox"
                                      color="primary"
                                      checked={item[checkBoxValueKey as any]}
                                    />
                                    <span>{`${label}  ${
                                      item[checkBoxValueLabel as any] ? '| ' + item[checkBoxValueLabel as any] : ''
                                    }`}</span>
                                  </span>
                                  {displayValidationErrorMessage}
                                </FormControl>
                              </Grid>
                            );
                          });
                        case 'image': {
                          const imageValue = _.get(values, fieldKey);
                          return (
                            <Grid item xs={itemStyle.xs} sm={itemStyle.sm} md={itemStyle.md} key={key}>
                              <FormControl>
                                <FormLabel style={{textAlign: 'left'}}> {label}</FormLabel>
                                <br />
                                <Field
                                  component={FormImage}
                                  name="checkbox"
                                  id={fieldKey}
                                  disabled={disabled}
                                  value={imageValue}
                                  onChange={(value: any) => {
                                    setFieldValue(fieldKey as string, value);
                                  }}
                                  variant="outlined"
                                  label={label}
                                  color="primary"
                                  image={imageValue}
                                />
                                {displayValidationErrorMessage}
                              </FormControl>
                            </Grid>
                          );
                        }
                        case 'section-title':
                          return (
                            <Grid item xs={12} sm={12} md={12} key={key}>
                              <div className="section-title">{label}</div>
                            </Grid>
                          );
                      }
                    })}
                  </Grid>
                </Grid>
                {!props.isFilter || (props.isFilter && props.isFilterReset) || props.onCancel ? (
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    alignContent={'center'}
                    className="action-buttons"
                    style={{paddingRight: '10px'}}
                  >
                    {!props.isFilter && (
                      <Grid>
                        <Button
                          disabled={props.isLoading || Object.keys(errors).length > 0}
                          type={'submit'}
                          variant="contained"
                          className={Object.keys(errors).length ? 'inactive-button' : 'blue-button'}
                        >
                          {props.submitButtonText}
                        </Button>
                      </Grid>
                    )}
                    {props.isFilter && props.isFilterReset && (
                      <Button type={'button'} onClick={resetFilters} variant="contained" className="yellow-button">
                        {resetlButtonText}
                      </Button>
                    )}
                    {props.onCancel && (
                      <Button className="yellow-button" variant="contained" type={'reset'}>
                        {cancelButtonText}
                      </Button>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </FormikForm>
          </>
        );
      }}
    </Formik>
  );
};

export default Form;
