import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import OpenInvoicesActions from '../../components/SupplierOrder/OpenInvoicesAction';
import {MenuItem, Select} from '@material-ui/core';
import {PaymentMethodType} from '../../types/Supplier/PaymentMethodType';
import {formatCurrency} from '../../lib/helpers';

export interface FilterButtonProps {
  paymentMethod: string;
  setPaymentMethod: (value: string) => void;
}

const HeaderComponent = ({additionalData}: any) => {
  const {t} = useTranslation();
  return (
    <p style={{padding: '5px'}}>
      {t('supplierOrders.totalOnPage')}: {formatCurrency(additionalData?.total)}
    </p>
  );
};

const FilterButton = ({paymentMethod, setPaymentMethod}: FilterButtonProps) => {
  const {t} = useTranslation();

  const paymentMethods: string[] = Object.values(PaymentMethodType);

  return (
    <span style={{marginLeft: '20px'}}>
      <Select
        value={paymentMethod}
        onChange={(e) => setPaymentMethod(e.target.value as string)}
        label={t('general.paymentType')}
        style={{minWidth: '150px'}}
      >
        <MenuItem key="all" value="all">
          {t('general.all')}
        </MenuItem>
        {paymentMethods.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </span>
  );
};

const SupplierOrdersDue = () => {
  const {t} = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<string>('all');
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    setRefresh(!refresh);
  }, [paymentMethod]);

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('supplierOrders.supplier'),
            accessor: 'supplierName',
            sortable: false,
          },
          {
            kind: 'component',
            name: t('general.openInvoices'),
            component: OpenInvoicesActions,
            props: {setRefresh: () => setRefresh(!refresh)},
            onFetchProps: {},
          },
        ]}
        endpoint={'supplier_orders_due_report'}
        target={'suppliers'}
        selectRowClickId={'supplierId'}
        numberOfItemsPerPage={100}
        title={t('general.openInvoices')}
        actionButtons={<FilterButton paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />}
        additionalFields={{paymentMethod}}
        refreshData={refresh}
        aboveTableComponent={HeaderComponent}
      />
    </div>
  );
};

export default SupplierOrdersDue;
