import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DataTableItemType} from '../../../components/Table/interfaces/IHeaderAccessor';
import {ProductCompatibility} from '../../../types/Product/ProductCompatibility';
import ProductStatusButtons from './ProductStatusButtons';
import useUser from '../../../hooks/user';
import {useStorlessFetch} from '../../../hooks/fetch';
import {IProductScreen, initProductScreenConfig} from '../../../types/CustomConfig/IProductConfig';

export const useProductHeaders = (): any => {
  const user = useUser() as any;
  const userEmail = user?.email || '';
  const featureName = userEmail ? `customConfig-${userEmail}` : 'default';
  const [getConfigRes, getConfig] = useStorlessFetch('custom_config');
  const [configObject, setConfigObject] = useState<IProductScreen>(initProductScreenConfig());
  const {t} = useTranslation();

  useEffect(() => {
    getConfig({featureName});
  }, []);

  useEffect(() => {
    if (getConfigRes?.data?.data?.productScreen) {
      setConfigObject(() => getConfigRes?.data?.data?.productScreen);
    }
  }, [getConfigRes]);

  const headers = [
    configObject?.displayedFields?.productId && {
      kind: 'accessor',
      name: t('products.productId'),
      accessor: 'productId',
      sortable: true,
      type: 'number',
    },
    configObject?.displayedFields?.title && {
      kind: 'accessor',
      name: t('products.title'),
      accessor: 'title',
      sortable: true,
    },
    configObject?.displayedFields?.manualTitle && {
      kind: 'accessor',
      name: t('productDetails.manualTitle'),
      accessor: 'manualTitle',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.compatibility'] && {
      kind: 'accessor',
      name: t('productDetails.compatibility'),
      accessor: 'productInformation.compatibility',
      sortable: true,
      type: DataTableItemType.SELECT,
      options: ([{value: 'all', label: 'All'}] as any).concat(
        Object.values(ProductCompatibility).map((item) => {
          return {value: item, label: item};
        }),
      ),
    },
    configObject?.displayedFields?.['productInformation.productType'] && {
      kind: 'accessor',
      name: t('productDetails.productType'),
      accessor: 'productInformation.productType',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.manufacturer'] && {
      kind: 'accessor',
      name: t('productDetails.manufacturer'),
      accessor: 'productInformation.manufacturer',
      sortable: true,
    },
    configObject?.displayedFields?.shopwareId && {
      kind: 'accessor',
      name: t('productDetails.shopwareId'),
      accessor: 'shopwareId',
      sortable: true,
    },
    configObject?.displayedFields?.calculatedPrice && {
      kind: 'accessor',
      name: t('productDetails.calculatedPrice'),
      accessor: 'calculatedPrice',
      type: DataTableItemType.NUMBER,
      format: 'price',
      sortable: true,
    },
    configObject?.displayedFields?.averagePrice && {
      kind: 'accessor',
      name: t('productDetails.average-price'),
      accessor: 'averagePrice',
      type: DataTableItemType.NUMBER,
      format: 'price',
      sortable: true,
    },
    configObject?.displayedFields?.stockDDA && {
      kind: 'accessor',
      name: t('productDetails.stock'),
      accessor: 'stockDDA',
      type: DataTableItemType.NUMBER,
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.minimumStock'] && {
      kind: 'accessor',
      name: t('productDetails.minimumStock'),
      accessor: 'productInformation.minimumStock',
      type: DataTableItemType.NUMBER,
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.minimumStockReach'] && {
      kind: 'accessor',
      name: t('productDetails.minimumStockReach'),
      type: DataTableItemType.NUMBER,
      fieldKeyType: 'number',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.minimumStockOrder'] && {
      kind: 'accessor',
      name: t('productDetails.minimumStockOrder'),
      accessor: 'productInformation.minimumStockOrder',
      type: DataTableItemType.NUMBER,
      sortable: true,
    },
    configObject?.displayedFields?.bechlemId && {
      kind: 'accessor',
      name: t('productDetails.bechlemId'),
      accessor: 'bechlemId',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.colour'] && {
      kind: 'accessor',
      name: t('productDetails.colour'),
      accessor: 'productInformation.colour',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.numberOfItems'] && {
      kind: 'accessor',
      name: t('productDetails.numberOfItems'),
      accessor: 'productInformation.numberOfItems',
      type: DataTableItemType.NUMBER,
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.content'] && {
      kind: 'accessor',
      name: t('productDetails.content'),
      accessor: 'productInformation.content',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.pageYield'] && {
      kind: 'accessor',
      name: t('productDetails.pageYield'),
      accessor: 'productInformation.pageYield',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.doublePackage'] && {
      kind: 'accessor',
      name: t('productDetails.doublePackage'),
      accessor: 'productInformation.doublePackage',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.packType'] && {
      kind: 'accessor',
      name: t('productDetails.packType'),
      accessor: 'productInformation.packType',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.image'] && {
      kind: 'accessor',
      name: t('productDetails.image'),
      accessor: 'productInformation.image',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.imageDDA'] && {
      kind: 'accessor',
      name: t('productDetails.imageDDA'),
      accessor: 'productInformation.imageDDA',
      sortable: true,
    },
    configObject?.displayedFields?.['productInformation.imageSP'] && {
      kind: 'accessor',
      name: t('productDetails.imageSP'),
      accessor: 'productInformation.imageSP',
      sortable: true,
    },
    {
      kind: 'accessor',
      name: t('productDetails.status'),
      accessor: 'druckdichaus.status',
      callback: (product: any) => {
        return <ProductStatusButtons product={product} />;
      },
    },
  ].filter(Boolean);

  return headers;
};
