import {Typography} from '@material-ui/core';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Form, {IField} from '../../components/Form/Form';
import {useStorlessFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {SupplierType} from '../../types/Supplier/SupplierType';
import Spinner from '../../components/Spinner/Spinner';
import {ICustomField, ICustomFieldType} from '../../types/CustomFields/CustomField';
import {getType} from '../../lib/customFields';

export interface INewSupplier {
  supplierId?: string;
  customFields?: ICustomField[];
  getSupplierDetails?: (id: string) => void;
}

const NewSupplier = ({supplierId, customFields, getSupplierDetails}: INewSupplier) => {
  const {t} = useTranslation();
  const supplierType = Object.values(SupplierType).map((item) => {
    return {value: item, label: item};
  });
  const [spinner, setSpinner] = useState(false);
  const [supplierPaymentMethods, setSupplierPaymentMethods] = useState<{value: string; label: string}[]>([]);
  const [otherConfig, getOtherConfig] = useStorlessFetch('feature_configuration');
  const [createSupplier, doCreateSupplier] = useStorlessFetch('create_supplier');
  const [updateSupplier, doUpdate] = useStorlessFetch('update_supplier');
  const [supplier, getSupplier] = useStorlessFetch('get_supplier_by_id');
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    name: '',
    server: '',
    email: '',
    user: '',
    password: '',
    _id: null,
    type: 'other',
    address: {
      address: '',
      zipCode: '',
      city: '',
      country: '',
    },
    companyName: '',
    phone: '',
    ediServer: '',
    ediUser: '',
    ediPassword: '',
    ediPort: 0,
    ediSecure: false,
    ediFolder: '',
    paymentMethod: '',
    paymentDuration: 0,
    vatPercentage: 0,
  });

  useEffect(() => {
    if (supplierId) {
      getSupplier({id: supplierId});
    }
    getOtherConfig({featureName: 'otherConfig'});
  }, []);

  useEffect(() => {
    if (createSupplier.loaded || updateSupplier.loaded) {
      setSpinner(false);
      dispatch(modalActions.closeModal(undefined, 'submited'));
    }
  }, [createSupplier.loading, updateSupplier.loading]);

  useEffect(() => {
    if (supplier.loaded && supplierPaymentMethods.length) {
      setInitialValues({...supplier.data});
    }
  }, [supplier.loading, supplierPaymentMethods]);

  useEffect(() => {
    if (createSupplier.data || updateSupplier.data) {
      getSupplierDetails && getSupplierDetails(createSupplier.data?._id || updateSupplier.data?._id);
    }

    return () => {
      createSupplier.data = null;
      updateSupplier.data = null;
      createSupplier.error = null;
      updateSupplier.error = null;
      createSupplier.loaded = null;
      updateSupplier.loaded = null;
      createSupplier.loading = null;
      updateSupplier.loading = null;
    };
  }, [createSupplier.data, updateSupplier.data]);

  useEffect(() => {
    if (otherConfig?.data?.data?.supplierPaymentMethods) {
      const data = otherConfig.data.data.supplierPaymentMethods.map((x: any) => ({
        label: x,
        value: x,
      }));
      setSupplierPaymentMethods(data);
    }
  }, [otherConfig]);

  const supplierFields: IField[] = [
    {
      label: t('suppliers.supplierInfo'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('suppliers.name'),
      placeholder: t('suppliers.name'),
      fieldKey: 'name',
      type: 'input',
      disabled: supplierId !== undefined ? true : false,
    },
    {
      label: t('suppliers.companyName'),
      placeholder: t('suppliers.companyName'),
      fieldKey: 'companyName',
      type: 'input',
    },
    {
      label: t('suppliers.email'),
      placeholder: t('suppliers.email'),
      fieldKey: 'email',
      type: 'input',
    },
    {
      label: t('suppliers.phone'),
      placeholder: t('suppliers.phone'),
      fieldKey: 'phone',
      type: 'input',
    },
    {
      label: t('suppliers.address'),
      placeholder: t('suppliers.address'),
      fieldKey: 'address.address',
      type: 'input',
    },
    {
      label: t('suppliers.zipCode'),
      placeholder: t('suppliers.zipCode'),
      fieldKey: 'address.zipCode',
      type: 'input',
    },
    {
      label: t('suppliers.city'),
      placeholder: t('suppliers.city'),
      fieldKey: 'address.city',
      type: 'input',
    },
    {
      label: t('suppliers.country'),
      placeholder: t('suppliers.country'),
      fieldKey: 'address.country',
      type: 'input',
    },
    {
      label: t('suppliers.ftpPriceListConfiguration'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('suppliers.server'),
      placeholder: t('suppliers.server'),
      fieldKey: 'server',
      type: 'input',
    },
    {
      label: t('suppliers.user'),
      placeholder: t('suppliers.user'),
      fieldKey: 'user',
      type: 'input',
    },
    {
      label: t('suppliers.password'),
      placeholder: t('suppliers.password'),
      fieldKey: 'password',
      type: 'input',
    },
    {
      label: t('suppliers.type'),
      placeholder: t('suppliers.type'),
      fieldKey: 'type',
      type: 'select',
      options: supplierType,
    },
    {
      label: t('suppliers.port'),
      placeholder: t('suppliers.port'),
      fieldKey: 'port',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('suppliers.csvUrl'),
      placeholder: t('suppliers.csvUrl'),
      fieldKey: 'csvUrl',
      type: 'input',
    },
    {
      label: t('suppliers.priceListFileName'),
      placeholder: t('suppliers.priceListFileName'),
      fieldKey: 'priceListFileName',
      type: 'input',
    },
    {
      label: t('suppliers.isSftp'),
      placeholder: t('suppliers.isSftp'),
      fieldKey: 'isSftp',
      type: 'checkbox',
    },
    {
      label: t('suppliers.csvPriceListConfiguration'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('suppliers.delimiter'),
      placeholder: t('suppliers.delimiter'),
      fieldKey: 'csvConfig.delimiter',
      type: 'input',
    },
    {
      label: t('suppliers.externalArticleId'),
      placeholder: t('suppliers.externalArticleId'),
      fieldKey: 'csvConfig.externalArticleId',
      type: 'input',
    },
    {
      label: t('suppliers.title'),
      placeholder: t('suppliers.title'),
      fieldKey: 'csvConfig.title',
      type: 'input',
    },
    {
      label: t('suppliers.quantity'),
      placeholder: t('suppliers.quantity'),
      fieldKey: 'csvConfig.quantity',
      type: 'input',
    },
    {
      label: t('suppliers.price'),
      placeholder: t('suppliers.price'),
      fieldKey: 'csvConfig.price',
      type: 'input',
    },
    {
      label: t('suppliers.ean'),
      placeholder: t('suppliers.ean'),
      fieldKey: 'csvConfig.ean',
      type: 'input',
    },
    {
      label: t('suppliers.mpn'),
      placeholder: t('suppliers.mpn'),
      fieldKey: 'csvConfig.mpn',
      type: 'input',
    },
    {
      label: t('suppliers.qualityLevel'),
      placeholder: t('suppliers.qualityLevel'),
      fieldKey: 'csvConfig.qualityLevel',
      type: 'input',
    },
    {
      label: t('suppliers.ftpOrdersConfiguration'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('newSupplier.ediServer'),
      placeholder: t('newSupplier.ediServer'),
      fieldKey: 'ediServer',
      type: 'input',
    },
    {
      label: t('newSupplier.ediUser'),
      placeholder: t('newSupplier.ediUser'),
      fieldKey: 'ediUser',
      type: 'input',
    },
    {
      label: t('newSupplier.ediPassword'),
      placeholder: t('newSupplier.ediPassword'),
      fieldKey: 'ediPassword',
      type: 'input',
    },
    {
      label: t('newSupplier.ediPort'),
      placeholder: t('newSupplier.ediPort'),
      fieldKey: 'ediPort',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('newSupplier.ediFolder'),
      placeholder: t('newSupplier.ediFolder'),
      fieldKey: 'ediFolder',
      type: 'input',
    },
    {
      label: t('newSupplier.ediSecure'),
      placeholder: t('newSupplier.ediSecure'),
      fieldKey: 'ediSecure',
      type: 'checkbox',
    },
    {
      label: t('suppliers.otherInfo'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('suppliers.excludeFromPricing'),
      placeholder: t('suppliers.excludeFromPricing'),
      fieldKey: 'excludeFromPricing',
      type: 'checkbox',
    },
    {
      label: t('newSupplier.paymentMethod'),
      placeholder: t('newSupplier.paymentMethod'),
      fieldKey: 'paymentMethod',
      type: 'select',
      options: supplierPaymentMethods,
    },
    {
      label: t('newSupplier.paymentDuration'),
      placeholder: t('newSupplier.paymentDuration'),
      fieldKey: 'paymentDuration',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('newSupplier.vatPercentage'),
      placeholder: t('newSupplier.vatPercentage'),
      fieldKey: 'vatPercentage',
      type: 'input',
      fieldKeyType: 'number',
    },
  ];

  if (customFields) {
    customFields.forEach((field: ICustomField) => {
      const type = getType(field.type);
      supplierFields.push({
        label: field.fieldName,
        placeholder: field.fieldName,
        fieldKey: `customFields.${field.fieldName}`,
        type: type,
        ...(type === 'input' ? {fieldKeyType: field.type === ICustomFieldType.NUMBER ? 'number' : 'string'} : {}),
      });
    });
  }

  return (
    <>
      <Typography className="modal-title">
        {supplierId ? t('newSupplier.updateSupplier') : t('newSupplier.createSupplier')}
      </Typography>
      {(!supplierId || initialValues?._id) && (
        <Form
          initialValues={initialValues}
          fields={supplierFields}
          values={initialValues}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={(values) => {
            setSpinner(true);
            if (supplierId) {
              delete values._id;
              doUpdate({id: supplierId, ...values});
            } else {
              doCreateSupplier(values);
            }
          }}
          onCancel={() => dispatch(modalActions.closeModal())}
        />
      )}
      {spinner && <Spinner />}
    </>
  );
};

export default NewSupplier;
