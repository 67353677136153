import React, {useContext, useState} from 'react';
import {IconButton} from '@material-ui/core';
import {Edit, HourglassEmpty, AccessAlarm, PersonAddOutlined, Delete} from '@material-ui/icons';
import CompleteTaskButton from '../../components/Todos/CompleteButton';
import moment from 'moment';
import {ITodoTask} from '../../types/Todo/ITodoTask';
import TodoContext from '../../screens/Todo/Context/TodoContext';
import {useTranslation} from 'react-i18next';
import {ScreenType} from '../../types/Todo/ITodoScreen';
import SmallCompleteTaskButton from './SmallCompleteButton';
import {iconButtonStyles} from './TodoListsSidebar';

interface SingleTodoViewProps {
  todo: ITodoTask;
}

interface LinkifyTextProps {
  text: string;
}

export const LinkifyText: React.FC<LinkifyTextProps> = ({text}) => {
  // Matches URLs including those starting with www. without http:// or https://
  const urlRegex = /\b(https?:\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/gi;

  const isURL = (part: string) => urlRegex.test(part);

  const ensureHttp = (url: string) => (url.startsWith('www.') ? `http://${url}` : url);

  const parts = text.split(' ').map((part) =>
    isURL(part) ? (
      <a href={ensureHttp(part)} key={part} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part
    ),
  );

  return (
    <div>
      {parts.map((part, index) => (
        <React.Fragment key={index}>{part} </React.Fragment>
      ))}
    </div>
  );
};

const SingleTodoView: React.FC<SingleTodoViewProps> = ({todo}) => {
  const {t} = useTranslation();
  const {setEditingTodo, screen, completeTodoTask, completeSubtask, activeTaskId, setActiveTaskId} =
    useContext(TodoContext);
  const iconStyles = iconButtonStyles();

  const handleTaskClick = () => {
    setActiveTaskId(todo._id);
  };

  const deleteTask = (id: string) => {
    if (confirm(t('todos.deleteSerialConfirmation'))) {
      completeTodoTask({id});
    }
  };

  const subtasks = todo.subtasks?.filter((singleTask: any) => singleTask.completed === false) || [];

  return (
    <div className={`single-task ${activeTaskId === todo._id ? 'single-task-active' : ''}`} onClick={handleTaskClick}>
      <div className="flex align-center single-todo-item" style={{marginBottom: '5px'}}>
        {screen.type !== ScreenType.SERIAL && (
          <IconButton style={{padding: 0}} onClick={() => completeTodoTask({id: todo._id})}>
            <CompleteTaskButton />
          </IconButton>
        )}
        <div className="task-title">
          <LinkifyText text={todo.name} />
        </div>

        <div className="list-buttons">
          <IconButton onClick={() => setEditingTodo(todo)} size="small" className={iconStyles.smallIconButton}>
            <Edit />
          </IconButton>
          {screen.type === ScreenType.SERIAL && (
            <IconButton
              style={{padding: '0px', color: '#f87171'}}
              onClick={() => {
                deleteTask(todo._id);
              }}
              className={iconStyles.smallIconButton}
            >
              <Delete />
            </IconButton>
          )}
        </div>
      </div>
      {subtasks?.length > 0 && (
        <div className="task-subtasks">
          {t('todos.subtasks')}:
          {subtasks.map((subtask) => (
            <div key={subtask.id} className="subtask">
              <IconButton style={{padding: 0}} onClick={() => completeSubtask({id: todo._id, subtaskId: subtask.id})}>
                <SmallCompleteTaskButton />
              </IconButton>
              <span style={{marginLeft: '6px'}}>
                <LinkifyText text={subtask.name} />
              </span>
            </div>
          ))}
        </div>
      )}
      {(todo.isOverdue || todo.dueTime || todo.assignedBy || screen.type === ScreenType.SEARCH) && (
        <div className="task-additional-row">
          {((todo.isOverdue && screen.type !== ScreenType.SEARCH) || screen.type === ScreenType.SEARCH) && (
            <div className="task-overdue">
              <HourglassEmpty style={{color: '#ef4444', marginRight: '1px'}} fontSize="small" />
              {moment(todo.dueDate).format('DD.MM.YYYY')}
            </div>
          )}
          {todo.dueTime && (
            <div className="task-due-time">
              <AccessAlarm style={{color: '#0284c7', marginRight: '3px'}} fontSize="small" />
              {todo.dueTime}
            </div>
          )}
          {todo.assignedBy && (
            <div className="task-assigned-by">
              <PersonAddOutlined style={{color: '#0284c7', marginRight: '3px'}} fontSize="small" />
              {todo.assignedBy}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleTodoView;
