import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useFetch} from '../../hooks/fetch';
import {useTranslation} from 'react-i18next';

export interface IEditCountryShippingProvider {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  id?: any;
}

const generateInitialValues = (fields: any[], dataObject: any) => {
  if (dataObject) {
    if (dataObject.tax === null) {
      dataObject.tax = 0;
    }

    if (dataObject.shippingFee === null) {
      dataObject.shippingFee = 0;
    }

    if (dataObject.amazonShippingFee === null) {
      dataObject.amazonShippingFee = 0;
    }

    if (dataObject.dhlCost === null) {
      dataObject.dhlCost = 0;
    }

    if (dataObject.upsCost === null) {
      dataObject.upsCost = 0;
    }

    if (dataObject.glsCost === null) {
      dataObject.glsCost = 0;
    }

    if (dataObject.bookingAccount === null) {
      dataObject.bookingAccount = '';
    }
    return dataObject;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      if (field.fieldKeyType === 'number') {
        defaults = {...defaults, [field.fieldKey]: 0};
      } else if (field.fieldKeyType === 'checkbox') {
        defaults = {...defaults, [field.fieldKey]: false};
      } else {
        defaults = {...defaults, [field.fieldKey]: ''};
      }
    });
    return defaults;
  }
};

const EditCountryShippingProvider = (props: IEditCountryShippingProvider) => {
  const {title, onSubmit, onCancel, id} = props;
  const [countryDetails, getCountryDetails] = useFetch('country_shipping_providers_details');
  const [initialValues, setInitialValues] = useState(null);
  const {t} = useTranslation();
  const shippingProviders = [
    {label: 'DHL', value: 'DHL'},
    {label: 'UPS', value: 'UPS'},
    {label: 'GLS', value: 'GLS'},
  ];

  const fields = [
    {
      label: t('countries.country'),
      placeholder: t('countries.country'),
      fieldKey: 'country',
      type: 'input',
    },
    {
      label: t('countries.countryCode'),
      placeholder: t('countries.countryCode'),
      fieldKey: 'countryCode',
      type: 'input',
    },
    {
      label: t('countries.countryCode3'),
      placeholder: t('countries.countryCode3'),
      fieldKey: 'countryCode3',
      type: 'input',
    },
    {
      label: t('countries.shippingProvider'),
      placeholder: t('countries.shippingProvider'),
      fieldKey: 'shippingProvider',
      type: 'select',
      options: shippingProviders,
    },
    {
      label: t('countries.tax'),
      placeholder: t('countries.tax'),
      fieldKey: 'tax',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('countries.shippingFee'),
      placeholder: t('countries.shippingFee'),
      fieldKey: 'shippingFee',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('countries.amazonShippingFee'),
      placeholder: t('countries.amazonShippingFee'),
      fieldKey: 'amazonShippingFee',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('countries.bookingAccount'),
      placeholder: t('countries.bookingAccount'),
      fieldKey: 'bookingAccount',
      type: 'input',
    },
    {
      label: t('countries.isInEurope'),
      placeholder: t('countries.isInEurope'),
      fieldKey: 'isInEurope',
      type: 'checkbox',
    },
    {
      label: t('countries.dhlShippingFee'),
      placeholder: t('countries.dhlShippingFee'),
      fieldKey: 'dhlCost',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('countries.upsShippingFee'),
      placeholder: t('countries.upsShippingFee'),
      fieldKey: 'upsCost',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('countries.glsShippingFee'),
      placeholder: t('countries.glsShippingFee'),
      fieldKey: 'glsCost',
      type: 'input',
      fieldKeyType: 'number',
    },
  ];

  useEffect(() => {
    if (id) {
      getCountryDetails({id});
    } else {
      const data = generateInitialValues(fields, null);
      setInitialValues(data);
    }

    return () => {
      countryDetails.data = null;
      countryDetails.loaded = null;
      countryDetails.error = null;
    };
  }, [id]);

  useEffect(() => {
    if (countryDetails.loaded && !countryDetails.error) {
      const data = generateInitialValues(fields, countryDetails?.data);
      setInitialValues(data);
    }

    return () => {
      countryDetails.data = null;
      countryDetails.loaded = null;
      countryDetails.error = null;
    };
  }, [countryDetails]);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      {initialValues ? (
        <Form
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
        ></Form>
      ) : null}
    </>
  );
};

export default EditCountryShippingProvider;
