import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import DataTable from '../../components/Table/Table';
import {useStorlessFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {ISupplier} from '../../types/Supplier/ISupplier';
import {useTranslation} from 'react-i18next';
import {Button, IconButton} from '@material-ui/core';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {CustomerReturnStatus} from '../../types/CustomerReturn/CustomerReturnStatus';
import {MODALS} from '../../components/Modal/ModalContents';
import {Clear} from '@material-ui/icons';

export interface IProps {
  supplier: ISupplier;
}

export default function CustomerComplaints({supplier}: IProps) {
  const [addProductToComplaint, doAddProductToComplaint] = useStorlessFetch('customer_return_add_product');
  const [createSupplierComplaintRes, createSupplierComplaint] = useStorlessFetch('supplier_complaint_create');
  const [changeSupplierResponse, postChangeSupplier] = useStorlessFetch('change_supplier_on_customer_complaint');
  const [deleteResponse, deleteCustomerReturn] = useStorlessFetch('customer_return_delete');
  const [refreshData, setRefreshData] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmitModal = async (values: any) => {
    const data = {...values};
    doAddProductToComplaint({supplierName: supplier.name, ...data});
    setRefreshData((prevState: boolean) => !prevState);
  };

  const onExport = () => {
    createSupplierComplaint({supplierName: supplier.name === 'druckdichaus' ? 'DDA' : supplier.name});
    setTimeout(() => setRefreshData((prevState) => !prevState), 2000);
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleDelete = async (id: string) => {
    if (confirm(t('general.areYouSure'))) {
      deleteCustomerReturn({id});
      setRefreshData((prevState: boolean) => !prevState);
    }
  };

  const handleChangeSupplier = async (event: React.MouseEvent<HTMLButtonElement>, itemId: string) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CHANGE_SUPPLIER_ON_CUSTOMER_COMPLAINT, {
        title: t('customerReturns.changeSupplier') || '',
        onSubmit: (values) => onSubmitChangeSupplierModal({...values, id: itemId}),
        onCancel: handleCancelModal,
      }),
    );
  };

  const onSubmitChangeSupplierModal = async (data: any) => {
    postChangeSupplier({...data});
  };

  useEffect(() => {
    if (changeSupplierResponse) {
      dispatch(modalActions.closeModal());
    }
  }, [changeSupplierResponse]);

  return (
    supplier && (
      <DataTable
        enableExportToCsv={true}
        csvFilename={supplier?.name}
        onExport={onExport}
        numberOfItemsPerPage={100}
        className="customers__table"
        headers={[
          {
            kind: 'accessor',
            name: t('supplierComplaints.product'),
            accessor: 'productTitle',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierComplaints.externalArticleId'),
            accessor: 'externalArticleId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierComplaints.quantity'),
            accessor: 'quantity',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderDetails.price'),
            accessor: 'price',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierComplaints.date'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('supplierComplaints.reasonForComplaint'),
            accessor: 'reasonForComplaint',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: '',
            accessor: '_id',
            type: DataTableItemType.CALLBACK,
            callback: (item: any) => {
              return (
                <div className="products__actions">
                  <Button
                    style={{padding: '2px 6px', width: '120px'}}
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleChangeSupplier(e, item._id)}
                    title="Change supplier"
                  >
                    {t('customerReturns.changeSupplier')}
                  </Button>
                  <IconButton onClick={() => handleDelete(item._id)} title="Delete">
                    <Clear fontSize="small" style={{fill: 'red'}}></Clear>
                  </IconButton>
                </div>
              );
            },
          },
        ]}
        options={{
          supplierName: supplier.name === 'druckdichaus' ? 'DDA' : supplier.name,
          status: CustomerReturnStatus.createdSupplierComplaint,
          exported: false,
        }}
        defaultSort={{key: 'createdAt', value: -1}}
        endpoint={'customer_returns'}
        refreshData={refreshData}
        actionButtons={
          <>
            <Button
              variant="contained"
              className="blue-button"
              onClick={() => {
                dispatch(
                  modalActions.addModal(MODALS.ADD_PRODUCT_TO_COMPLAINT, {
                    onSubmit: handleSubmitModal,
                    onCancel: handleCancelModal,
                  }),
                );
              }}
            >
              {t('customerReturns.addProductToCustomerComplaint')}
            </Button>
            <Button
              variant="contained"
              className="blue-button"
              onClick={() => {
                dispatch(
                  modalActions.addModal(MODALS.PREVIOUS_COMPLAINT_EXPORTS, {
                    onSubmit: handleSubmitModal,
                    onCancel: handleCancelModal,
                    supplierName: supplier.name,
                  }),
                );
              }}
            >
              {t('supplierComplaints.previousComplaints')}
            </Button>
          </>
        }
        title={t('supplierComplaints.title')}
      />
    )
  );
}
