import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {ShopType} from '../../types/Orders/ShopType';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {IDhlConfig} from '../../types/Shipment/interfaces/IDhlConfig';
import {useStorlessFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

export default function UndeliveredDhlOrders() {
  const [dhlConfigDetailsResponse, getDhlConfigDetails] = useStorlessFetch('feature_configuration');
  const [dhlConfig, setDhlConfig] = useState<IDhlConfig>(null as any);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getDhlConfigDetails({featureName: 'dhlIntegrationConfig'});
  }, []);

  useEffect(() => {
    if (dhlConfigDetailsResponse?.data && !dhlConfigDetailsResponse.error) {
      setDhlConfig(dhlConfigDetailsResponse?.data);
    } else if (dhlConfigDetailsResponse?.error) {
      dispatch(appActions.showSnackBar({text: dhlConfigDetailsResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      dhlConfigDetailsResponse.data = null;
      dhlConfigDetailsResponse.error = null;
      dhlConfigDetailsResponse.loaded = null;
    };
  }, [dhlConfigDetailsResponse]);

  return dhlConfig?.data?.eventTypes ? (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.trackingNumber'),
            accessor: 'trackingNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.status'),
            accessor: 'status',
            sortable: true,
            type: DataTableItemType.MULTIPLE_SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(OrderStatusType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.shopName'),
            accessor: 'shopName',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShopType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
            timeZone: 'Europe/Berlin',
          },
          {
            kind: 'accessor',
            name: t('orderDetails.orderTotalSum'),
            accessor: 'orderTotalSum',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.lastEventDate'),
            accessor: 'lastEventDate',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('orders.lastTrackingStatus'),
            accessor: 'lastTrackingStatus',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.entries(dhlConfig.data.eventTypes).map(([key]) => {
                return {value: key, label: t(`dhlConfiguration.eventTypes.${key}`)};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.lastEventMessage'),
            accessor: 'lastEventMessage',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              dhlConfig.data.eventMessages
                .sort((a, b) => a?.localeCompare(b))
                .map((x) => {
                  return {value: x, label: x};
                }),
            ),
          },
        ]}
        endpoint={'undelivered_dhl_orders'}
        target={'orders'}
        defaultSort={{key: 'date', value: -1}}
        title={t('general.undeliveredDhlOrders')}
      />
    </div>
  ) : (
    <></>
  );
}
