export enum AUTH_ROUTES {
  LOGIN = '/',
  FORGOT_PASSWORD = '/forgot-password',
  FORGOT_PASSWORD_CONFIRMATION = '/forgotMyPassword/confirmation',
}

export enum APP_ROUTES {
  HOME = '/',
  SUPPLIER = '/suppliers',
  SUPPLIER_ARTICLES = '/articles',
  NEW_SUPPLIER_ARTICLES = '/newArticles',
  MANUAL_ARTICLES = '/manual-articles',
  SUPPLIER_ARTICLE_DETAILS = '/articles/:id',
  IGNORED_SUPPLIER_ARTICLES = '/ignoredArticles',
  MATCHING_ARTICLES = '/matching-articles',
  MATCHING_ARTICLES_MPN = '/matching-articles-by-mpn',
  MATCHING_IGNORED_EANS = '/matching-ignored-eans',
  FORM_TEST = '/form',
  CHANGE_PASSWORD = '/change-password',
  PRODUCTS = '/products',
  INVENTORY_PRODUCTS = '/products-inventory',
  PRODUCT_DETAILS = '/products/:id',
  IMPORT_PRODUCTS = '/importProducts',
  ORDERS = '/orders',
  CREATE_ORDER = '/orders/create/:id',
  ORDER_DETAILS = '/orders/:id',
  CUSTOMERS = '/customers',
  CUSTOMER_DETAILS = '/customers/:id',
  EXPIRED_PRODUCTS = '/expiredProducts',
  END_OF_LIFE_PRODUCTS = '/end-of-life-products',
  PRICING = '/shopware-pricing',
  DEACTIVATED_PRODUCTS = '/deactivatedProducts',
  COUNTRY_SHIPPING_PROVIDERS = '/countryShippingProviders',
  COUNTRY_SHIPPING_PROVIDERS_DETAILS = '/countryShippingProviders/:id',
  UPS_CONFIG = '/upsConfig',
  DHL_CONFIG = '/dhlConfig',
  ORDER_PROBLEM_TYPES = '/orderProblemTypes',
  ORDER_PROBLEMS = '/orderProblems',
  SYNC_STATUSES = '/sync-status',
  SUPPLIER_ORDER_ITEMS = '/supplierOrderItems',
  SUPPLIER_ITEMS_WITHOUT_STOCK = '/supplier-items-without-stock',
  SUPPLIER_ORDERS = '/supplierOrders',
  SUPPLIER_ORDERS_DETAILS = '/supplierOrders/:id',
  UNFINISHED_SUPPLIER_ORDERS = '/unfinished-supplier-orders',
  UNFINISHED_SUPPLIER_ORDER_ITEMS = '/unfinished-supplier-order-items',
  CUSTOMER_RETURNS = '/customerReturns',
  SUPPLIER_DETAILS = '/suppliers/:id',
  ORDERS_READY_FOR_SHIPPING = '/orders-ready-for-shipping',
  PRIME_ORDERS_READY_FOR_SHIPPING = '/prime-orders-ready-for-shipping',
  DDA_ORDERS_READY_FOR_SHIPPING = '/dda-orders-ready-for-shipping',
  SP_ORDERS_READY_FOR_SHIPPING = '/sp-orders-ready-for-shipping',
  DHL_ORDERS_READY_FOR_SHIPPING = '/dhl-orders-ready-for-shipping',
  UPS_ORDERS_READY_FOR_SHIPPING = '/ups-orders-ready-for-shipping',
  GLS_ORDERS_READY_FOR_SHIPPING = '/gls-orders-ready-for-shipping',
  CUSTOM_LISTS_READY_FOR_SHIPPING_OVERVIEW = '/custom-lists-ready-for-shipping',
  CUSTOM_LIST_READY_FOR_SHIPPING_SETTINGS = '/custom-lists-ready-for-shipping/:name',
  CUSTOM_LIST_READY_FOR_SHIPPING = '/orders-ready-for-shipping/:name',
  CUSTOM_PRODUCT_LIST_READY_FOR_SHIPPING = '/orders-ready-for-shipping/product/:name',
  AMAZON_PRICING = '/amazon-pricing',
  COMPANY_CONFIG = '/company-config',
  SUPPLIER_ORDER_PROBLEMS = '/supplierOrderProblems',
  SUPPLIER_ORDERS_DUE = '/supplier-orders-due',
  ORDER_ITEMS_WITHOUT_PRODUCT = '/order-items-without-product',
  SERVICE_STATUS = '/service-status',
  AMAZON_TRACKING_NUMBERS_INFO = '/amazon-tracking-numbers-info',
  AMAZON_SUBMISSIONS_INFO = '/amazon-submissions-info',
  NON_EU_ORDERS = '/non-eu-orders',
  MERGE_PRODUCTS = '/products/merge',
  AMAZON_STOCK_SKUS = '/amazon-stock-skus',
  OFFERS = '/offers',
  ORDERS_WITH_EASYBILL_ISSUE = '/orders-easybill-issues',
  ORDERS_WITH_OVERDUE_INVOICES = '/orders-easybill-overdue-invoices',
  MANUALLY_COMPLETED_ORDERS = '/manually-completed-orders',
  MANUALLY_COMPLETED_ORDER_DETAILS = '/manually-completed-orders/:id',
  PAYMENT_REMINDER_LIST = '/payment-reminder-list',
  OVERDUE_PAYMENTS = '/critical-overdue-list',
  OPEN_INVOICES = '/open-invoices',
  MERGE_CUSTOMERS = '/customers-merge',
  USERS = '/users',
  SUPPLIER_ORDER_ITEMS_BACKLOG = '/supplier-order-items-backlog',
  SUPPLIER_ARTICLE_CSV_PROCESSING = '/supplier-article-csv-processing',
  EBAY_PRICING = '/ebay-pricing',
  EBAY_ORDERS_READY_FOR_SHIPPING = '/ebay-orders-ready-for-shipping',
  DATEV_PAYMENTS_LIST = '/datev-payment-lists',
  PRICING_ITEMS = '/pricing-items',
  GALAXUS_PRICING = '/galaxus-pricing',
  TODO_LIST = '/todo-list',
  TODO_LIST_UPCOMING = '/todo-list/upcoming',
  TODO_LIST_SERIAL = '/todo-list/serial',
  EBAY_MULTI_VARIANT_PRODUCT = '/ebay-multi-variant-product',
  EBAY_MULTI_VARIANT_PRODUCT_ID = '/ebay-multi-variant-product/:id',
  EBAY_MULTI_VARIANT_ITEMS = '/ebay-multi-variant-items',
  OPEN_SUPPLIER_ORDERS = '/open-supplier-orders',
  KAUFLAND_PRICING = '/kaufland-pricing',
  CUSTOM_CONFIG = '/custom-config',
  EBAY_TRACKING_NUMBERS_INFO = '/ebay-tracking-numbers-info',
  PRODUCTS_WITHOUT_MINIMUM_MARGIN = '/amazon/products-without-minimum-margin',
  PRODUCTS_SHOP_STATUSES = '/products-shop-statuses',
  PRODUCTS_WITH_CALCULATED_PRICES = '/products-with-calculated-price',
  SUPPLIER_COMPLAINTS = '/supplier-complaints',
  SUPPLIER_COMPLAINTS_DETAILS = '/supplier-complaints/:id',
  CHANGE_LOG = '/change-log',
  CUSTOM_FIELDS = '/custom-fields',
  UNDELIVERED_DHL_ORDERS = '/undelivered-dhl-orders',
  INACTIVE_AMAZON_PRODUCTS = '/inactive-amazon-products',
  SUPPLIER_PAYMENTS = '/supplier-payments',
  PRODUCTS_STOCK_COMPARISION = '/products-stock-comparison',
  METRO_PRICING = '/metro-pricing',
  FNAC_PRICING = '/fnac-pricing',
  EBAY_PRODUCTS_TO_REPUBLISH = '/ebay-products-to-republish',
  LABEL_SCANNER = '/label-scanner',
  STATIC_FILES = '/static-files',
  EBAY_IMAGES = '/ebay-images',
  PRODUCTS_STOCK_PER_DAY = '/products-stock-per-day',
  UNDELIVERED_UPS_ORDERS = '/undelivered-ups-orders',
}
