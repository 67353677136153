import jsPDF from 'jspdf';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {IScannedOrderItem} from '../../types/OrderItems/IScannedOrderItem';
import {IOrder} from '../../types/Orders/IOrder';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import TrackingNumberScannerComponent from './CustomerOrderScanner/TrackingNumberComponent';
import CustomerOrderScannerComponent from './CustomerOrderScanner/CustomerOrderScannerComponent';
import {OrderScanningProvider} from '../../screens/Orders/Context/OrderScanningContext';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {EventsName, socketFactory} from '../../lib/socketFactory';
import {MODALS} from '../Modal/ModalContents';
import {Util} from '../../modules/api/utils';
import useUser from '../../hooks/user';

export interface ICustomerOrderScanner {
  onScan: (value: string) => void;
  order: IOrder;
  onClose: () => void;
  hideDefaultCloseButton?: boolean;
  onTrackingCodeScanSuccess: (callback: any) => void;
}

const CustomerOrderScanner = ({
  order: providedOrder,
  onScan,
  onClose,
  onTrackingCodeScanSuccess,
}: ICustomerOrderScanner): JSX.Element => {
  const user = useUser() as any;
  const featureName = user?.email ? `customConfig-${user?.email}` : undefined;
  const [barcode, setBarcode] = useState('');
  const [order, setOrder] = useState(providedOrder);
  const [orderItemsScanned, setOrderItemsScanned] = useState(false);
  const [allOrderItemsScanned, setAllOrderItemsScanned] = useState(false);
  const [product, setProduct] = useState<IScannedOrderItem>(null as any);
  const [orderItems, setOrderItems] = useState<IScannedOrderItem[]>([]);
  const [printDeliveryBill, setPrintDeliveryBill] = useState<boolean>(false);
  const [trackingNumberPushed, pushTrackingNumber] = useFetch<any>('orders_push_tracking_number');
  const [orderItemsByOrderId, getOrderItemsByOrderId] = useFetch<any>('orders_get_order_items');
  const [handleOrderItems, doHandleOrderItems] = useFetch<any>('orders_handle_scanned_order_items');
  const [deliveryBillPdf, getDeliveryBillPdf] = useFetch<any>('orders_delivery_bill');
  const [customConfig, getCustomConfig] = useStorlessFetch('custom_config');
  const [proformaResponse, getProforma] = useFetch<any>('orders_proforma');
  const [generateShippingLabelResponse, generateShippingLabel] = useFetch<any>('generate_shipping_label');
  const [showTrackingNumberInput, setShowTrackingNumberInput] = useState(false);
  const [multiBoxInput, setMultiBoxInput] = useState(false);
  const [orderMarkedAsPickup, setOrderMarkedAsPickup] = useState(false);
  const [validationFailed, setValidationFailed] = useState(false);
  const [isParcel, setIsParcel] = useState((order?.parcels?.length || 0) > 0);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    resetState();
    getOrderItemsByOrderId({id: order._id});
    generateShippingLabel({id: order._id});
    getCustomConfig({featureName});
    socketFactory.listenOrderModifying(onOrderScanning);
    socketFactory.emitOrderModifying(order?.orderNumber || '');

    return () => {
      orderItemsByOrderId.data = null;
      socketFactory.emitStopOrderModifying(order?.orderNumber || '');
      socketFactory.removeEventListener(EventsName.ORDER_MODIFYING);
      onClose();
    };
  }, [order?._id]);

  useEffect(() => {
    if (generateShippingLabelResponse.data) {
      setOrder(generateShippingLabelResponse.data);
    }

    return () => {
      generateShippingLabelResponse.data = null;
      generateShippingLabelResponse.error = null;
    };
  }, [generateShippingLabelResponse]);

  useEffect(() => {
    const inputBarcode = document.getElementById('input-bar-code') as HTMLInputElement;
    if (inputBarcode) {
      inputBarcode.focus();
    }
  }, [multiBoxInput]);

  useEffect(() => {
    if (orderItems?.length && product) {
      if (multiBoxInput) {
        const articleBarCodes = product.barcodeArticleNumbers.multiBoxArticleNumbers;
        const productScanned = articleBarCodes.find((item) => item.barcodeArticleNumbers.includes(barcode));

        if (productScanned) {
          updateMultiBoxItemQuantity(product);
          setProduct(product);
          setOrderItems([...orderItems]);
          resetBarcodeInput();
          if (product.quantity === product.quantityScanned) {
            setMultiBoxInput(false);
          }
        }
      } else {
        const multiboxBarCodes = product.barcodeArticleNumbers.multiBoxArticleNumbers;
        const articleBarCodes = product.barcodeArticleNumbers.barcodeArticleNumbers;
        const productScanned = articleBarCodes.find((code) => code === barcode);
        const activateMultiBoxScanning = multiboxBarCodes.find((x) => x.barcodeArticleNumbers.includes(barcode));

        if (productScanned) {
          updateScannedItemQuantity(product);
          resetBarcodeInput();
          setOrderItems([...orderItems]);
          dispatch(appActions.showSnackBar({text: t('scanner.scanSuccess'), options: {severity: 'success'}}));
        }

        if (activateMultiBoxScanning) {
          product.usingMultibox = true;
          setMultiBoxInput(true);
          resetBarcodeInput();
        }
      }

      const allProductsAreScanned = orderItems.every((item) => item.quantity === item.quantityScanned);
      if (allProductsAreScanned) {
        setAllOrderItemsScanned(true);
      }
    }
  }, [barcode]);

  useEffect(() => {
    if (allOrderItemsScanned) {
      doHandleOrderItems({isParcel, scannedProducts: orderItems, id: order._id});

      return () => {
        setBarcode('');
        setAllOrderItemsScanned(false);
        setOrderItemsScanned(false);
        setOrderItems(null as any);
      };
    }
  }, [allOrderItemsScanned]);

  useEffect(() => {
    if (orderItemsScanned) {
      onScan(barcode);
      dispatch(appActions.showSnackBar({text: t('scanner.scanSuccess'), options: {severity: 'success'}}));
    }

    return () => {
      setBarcode('');
      setOrderItemsScanned(false);
    };
  }, [orderItemsScanned]);

  useEffect(() => {
    if (orderItemsByOrderId.data) {
      setOrderItems(orderItemsByOrderId.data);
    }
    return () => {
      orderItemsByOrderId.data = null;
    };
  }, [orderItemsByOrderId.data]);

  useEffect(() => {
    if (orderItems) {
      const productItem = orderItems.find((item) => item.quantity !== item.quantityScanned);
      setProduct(productItem as any);

      if (!productItem && !order.isTrackingNumberScanned) {
        setShowTrackingNumberInput(true);
        const inputTrackingNumber = document.getElementById('trackingNumber') as HTMLInputElement;
        if (inputTrackingNumber) {
          inputTrackingNumber.value = '';
          inputTrackingNumber.focus();
        }
      } else {
        setShowTrackingNumberInput(false);
      }
    }
  }, [orderItems]);

  useEffect(() => {
    if (handleOrderItems.data) {
      const {base64Pdf, gifImage, htmlImage} = handleOrderItems.data.shippingLabel || {};
      const shippingLabelBase64 = base64Pdf ?? gifImage ?? htmlImage;
      const validationFailedError = handleOrderItems.data.status === OrderStatusType.ValidationFailed ? true : false;

      setOrderItemsScanned(true);
      setOrder(handleOrderItems.data);
      handleOrderItems.data = null;

      validationFailedError ? setValidationFailed(true) : printShippingLabel(order, shippingLabelBase64);
      printDeliveryBill &&
        getDeliveryBillPdf({id: order._id, parcelIndex: order?.parcels?.length ? order?.parcels?.length : -1});
    }

    if (handleOrderItems.error) {
      dispatch(appActions.showSnackBar({text: handleOrderItems.error.name || 'Error!', options: {severity: 'error'}}));
      dispatch(modalActions.closeModal());
    }

    return () => {
      handleOrderItems.data = null;
      handleOrderItems.error = null;
      setOrderItemsScanned(false);
      setBarcode('');
    };
  }, [handleOrderItems]);

  useEffect(() => {
    if (deliveryBillPdf.data && printDeliveryBill) {
      const {openInNewTab, serviceUrl, deliveryBillPrinterName} = customConfig?.data?.data?.printerConfig;
      const blob = new Blob([new Uint8Array(deliveryBillPdf.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);

      setTimeout(() => {
        openInNewTab && window.open(blobURL);

        const base64String = btoa(String.fromCharCode.apply(null, deliveryBillPdf.data.data));
        Util.printWithPrinterService(serviceUrl, deliveryBillPrinterName, base64String);
      }, 200);
    }

    return () => {
      deliveryBillPdf.data = null;
    };
  }, [deliveryBillPdf.data]);

  useEffect(() => {
    if (proformaResponse.data) {
      const blob = new Blob([new Uint8Array(proformaResponse.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }

    return () => {
      proformaResponse.data = null;
    };
  }, [proformaResponse]);

  const resetState = () => {
    setShowTrackingNumberInput(false);
    setAllOrderItemsScanned(false);
    setOrderItems([]);
    setProduct(null as any);
    setBarcode('');
    setOrderItemsScanned(false);
    setPrintDeliveryBill(false);
    setMultiBoxInput(false);
    setOrderMarkedAsPickup(false);
    setValidationFailed(false);
    trackingNumberPushed.data = null;
    handleOrderItems.data = null;
    deliveryBillPdf.data = null;
    orderItemsByOrderId.data = null;
    generateShippingLabelResponse.data = null;
  };

  const onOrderScanning = (orderNumber: string) => {
    if (orderNumber === order?.orderNumber) {
      dispatch(
        modalActions.addChild(MODALS.CONFIRM_DIALOG, {
          title: t('general.warning'),
          content: t('general.orderIsBeingModified'),
          onOk: closeModal,
          hideDefaultCloseButton: true,
        }),
      );
    }
  };

  const closeModal = () => {
    dispatch(modalActions.closeModal());
  };

  const resetBarcodeInput = () => {
    const inputBarcode = document.getElementById('input-bar-code') as HTMLInputElement;
    if (inputBarcode) {
      inputBarcode.value = '';
      inputBarcode.focus();
      setBarcode('');
    }
  };

  const updateScannedItemQuantity = (item: IScannedOrderItem) => {
    if (isNaN(item.quantityScanned)) {
      item.quantityScanned = 0;
    }
    if (item.quantityScanned < item.quantity) {
      item.quantityScanned += 1;
    }
  };

  const getBlob = (base64Label = '') => {
    const byteCharacters = atob(base64Label);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/pdf;base64'});
    return blob;
  };

  const displayBase64PdfLabel = (shippingLabelBase64: string) => {
    const label = shippingLabelBase64;
    const blob = getBlob(label);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const getBase64Content = (order: IOrder, shippingLabelBase64: string, openInNewTab: any) => {
    const doc = new jsPDF({orientation: 'l'});
    let base64Content = '';
    doc.setProperties({
      title: `${order.orderNumber}-label`,
      subject: `${order.orderNumber}-label`,
    });
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    const imgData = 'data:image/gif;base64,' + shippingLabelBase64;

    doc.addImage(imgData, 'JPEG', 0, 0, width, height);
    openInNewTab && doc.autoPrint();
    openInNewTab && doc.output('dataurlnewwindow');

    const base64PdfDataUri = doc.output('datauristring');
    base64Content = base64PdfDataUri.replace(/^data:application\/pdf;filename=generated.pdf;base64,/, '');
    return base64Content;
  };

  const displayReturnLabel = (order: IOrder) => {
    if (!order.returnLabelInfo?.exchangeOrderPrintReturn) {
      return;
    }

    const {openInNewTab, serviceUrl, returnLabelPrinterName} = customConfig?.data?.data?.printerConfig;
    const {base64Pdf, gifImage, htmlImage, shippingProviderType} = order.returnLabelInfo;
    let shippingLabelBase64 = base64Pdf || gifImage || htmlImage || '';
    const timeOutInMs = 10;

    if (shippingProviderType === ShippingProviderType.UPS) {
      shippingLabelBase64 = getBase64Content(order, shippingLabelBase64, openInNewTab);
    } else if ([ShippingProviderType.GLS, ShippingProviderType.DHL].includes(shippingProviderType as any)) {
      openInNewTab && setTimeout(() => displayBase64PdfLabel(shippingLabelBase64), timeOutInMs);
    }

    Util.printWithPrinterService(serviceUrl, returnLabelPrinterName, shippingLabelBase64);

    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('scanner.pickupOrderAlertMessage'),
      }),
    );
  };

  const printShippingLabel = async (order: IOrder, shippingLabelBase64: string) => {
    const {openInNewTab, serviceUrl, shippingLabelPrinterName, customsPrinterName} =
      customConfig?.data?.data?.printerConfig;
    let base64Content = shippingLabelBase64;
    const timeOutInMs = 100;

    if (order.isPickupOrder) {
      return;
    }

    await Util.sleep(timeOutInMs);

    if (order.shippingType === ShippingProviderType.DHL || order.shippingType === ShippingProviderType.GLS) {
      openInNewTab && setTimeout(() => displayBase64PdfLabel(shippingLabelBase64), timeOutInMs);
    } else {
      base64Content = getBase64Content(order, shippingLabelBase64, openInNewTab);
    }

    await Util.sleep(timeOutInMs);

    Util.printWithPrinterService(serviceUrl, shippingLabelPrinterName, base64Content);

    // Print customs document
    const customsDoc = order?.shippingLabel?.customsDoc;
    if (customsDoc) {
      openInNewTab && setTimeout(() => displayBase64PdfLabel(customsDoc), timeOutInMs);
      Util.printWithPrinterService(serviceUrl, customsPrinterName, customsDoc);
    }

    displayReturnLabel(order);
  };

  const updateMultiBoxItemQuantity = (mainProduct: IScannedOrderItem) => {
    const multiBoxProductToUpdate = mainProduct.barcodeArticleNumbers.multiBoxArticleNumbers.find((mItem) =>
      mItem.barcodeArticleNumbers.find((code) => code === barcode),
    );

    if (multiBoxProductToUpdate) {
      if (isNaN(multiBoxProductToUpdate.quantityScanned)) {
        multiBoxProductToUpdate.quantityScanned = 0;
      }
      if (multiBoxProductToUpdate.quantityScanned < multiBoxProductToUpdate.numberOfProducts) {
        multiBoxProductToUpdate.quantityScanned += 1;
      }

      const isMainProductScanned = checkIfOneMainProductScanned();

      if (isMainProductScanned) {
        mainProduct.quantityScanned += 1;
        resetMultiBoxScannedQuantities();
      }
      dispatch(appActions.showSnackBar({text: t('scanner.scanSuccess'), options: {severity: 'success'}}));
    }

    function resetMultiBoxScannedQuantities() {
      mainProduct.barcodeArticleNumbers.multiBoxArticleNumbers.forEach((item) => {
        item.quantityScanned = 0;
      });
    }

    function checkIfOneMainProductScanned() {
      const articleScannedStatus: boolean[] = [];
      mainProduct.barcodeArticleNumbers.multiBoxArticleNumbers.forEach((item) => {
        if (item.quantityScanned === item.numberOfProducts) {
          articleScannedStatus.push(true);
        } else {
          articleScannedStatus.push(false);
        }
      });
      return articleScannedStatus.every((scanStatus) => scanStatus === true);
    }
  };

  return orderItemsByOrderId.data ? (
    <OrderScanningProvider
      value={{
        multiBoxInput,
        order,
        orderItems,
        orderMarkedAsPickup,
        printDeliveryBill,
        product,
        pushTrackingNumber,
        allOrderItemsScanned,
        trackingNumberPushed,
        validationFailed,
        doHandleOrderItems,
        orderItemsScanned,
        isParcel,
        resetState,
        setBarcode,
        setMultiBoxInput,
        setOrderItems,
        setOrderMarkedAsPickup,
        setPrintDeliveryBill,
        setProduct,
        setIsParcel,
        setShowTrackingNumberInput,
        getProforma,
        setOrder,
        setAllOrderItemsScanned,
      }}
    >
      <>
        {showTrackingNumberInput === true ? (
          <TrackingNumberScannerComponent onTrackingCodeScanSuccess={onTrackingCodeScanSuccess} />
        ) : (
          <CustomerOrderScannerComponent />
        )}
      </>
    </OrderScanningProvider>
  ) : (
    <div></div>
  );
};

export default CustomerOrderScanner;
