import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {formatCurrency} from '../../lib/helpers';
import {Button} from '@material-ui/core';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

export enum OpenInvoiceStatus {
  NOT_DUE = 'NOT_DUE',
  DUE = 'DUE',
}

interface OpenInvoicesActionsProps {
  row: any;
  setRefresh: (refresh: boolean) => void;
}

const OpenInvoicesActions: React.FC<OpenInvoicesActionsProps> = (props: any) => {
  const [markOrdersAsPaidResponse, markOrdersAsPaid] = useFetch<any>('mark_supplier_orders_as_paid');
  const {row, setRefresh} = props;
  const dispatch = useDispatch();
  const {invoicesForPay, totalForPay, invoicesNotDue, totalNotDue} = row;
  const allInvoices = [...invoicesForPay, ...invoicesNotDue];
  const getDefaultState = () => {
    const defaultState: Record<string, boolean> = {};

    invoicesForPay.forEach((invoice: any) => {
      defaultState[invoice.invoiceNumber] = false;
    });

    invoicesNotDue.forEach((invoice: any) => {
      defaultState[invoice.invoiceNumber] = false;
    });

    return defaultState;
  };
  const [checkedInvoices, setCheckedInvoices] = useState<Record<string, boolean>>(getDefaultState());
  const [, forceUpdate] = useState(0);
  const forceRender = () => forceUpdate((n) => n + 1);
  useEffect(() => {
    forceRender();
  }, [checkedInvoices, invoicesForPay, invoicesNotDue]);

  const {t} = useTranslation();

  const handleCheckboxChange = (event: any, invoiceNumber: any) => {
    const isChecked = event.target.checked;

    setCheckedInvoices((prevState) => ({
      ...prevState,
      [invoiceNumber]: isChecked,
    }));
  };

  const prevent = (e: any) => {
    if (e.target.classList.contains('link')) {
      if (e.target.target === '_blank') {
        window.open(e.target.href, '_blank');
      }
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const copyToClipboard = () => {
    const selectedInvoices = Object.keys(checkedInvoices).filter((invoiceNumber) => checkedInvoices[invoiceNumber]);

    let invoicesToCopy = [];

    invoicesToCopy = allInvoices.filter((invoice: any) => selectedInvoices.includes(invoice.invoiceNumber));

    const commaSeparatedInvoiceNumbers = invoicesToCopy.map((invoice: any) => invoice.invoiceNumber).join(', ');

    navigator.clipboard.writeText(commaSeparatedInvoiceNumbers);

    return invoicesToCopy;
  };

  const copyToClipboardAndMarkAsPaid = () => {
    const invoicesToCopy = copyToClipboard();
    const invoiceIds = invoicesToCopy.map((invoice: any) => invoice.orderId);

    markOrdersAsPaid({ids: invoiceIds, total: totalSum});
  };

  const totalSum = useMemo(() => {
    const selectedInvoices = Object.keys(checkedInvoices).filter((invoiceNumber) => checkedInvoices[invoiceNumber]);
    const invoices = allInvoices.filter((invoice) => selectedInvoices.includes(invoice.invoiceNumber));

    return invoices.reduce((acc, invoice: any) => acc + invoice.total, 0);
  }, [checkedInvoices, allInvoices]);

  const totalForPaySum = useMemo(() => {
    const selectedInvoices = Object.keys(checkedInvoices).filter((invoiceNumber) => checkedInvoices[invoiceNumber]);
    const invoices = invoicesForPay.filter((invoice: any) => selectedInvoices.includes(invoice.invoiceNumber));
    return invoices.reduce((acc: any, invoice: any) => acc + invoice.total, 0);
  }, [checkedInvoices, invoicesForPay]);

  const totalNotDueSum = useMemo(() => {
    const selectedInvoices = Object.keys(checkedInvoices).filter((invoiceNumber) => checkedInvoices[invoiceNumber]);
    const invoices = invoicesNotDue.filter((invoice: any) => selectedInvoices.includes(invoice.invoiceNumber));
    return invoices.reduce((acc: any, invoice: any) => acc + invoice.total, 0);
  }, [checkedInvoices, invoicesNotDue]);

  useEffect(() => {
    if (markOrdersAsPaidResponse?.error) {
      dispatch(appActions.showSnackBar({text: markOrdersAsPaidResponse.error.name, options: {severity: 'error'}}));
    } else if (markOrdersAsPaidResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setRefresh();
    }

    return () => {
      markOrdersAsPaidResponse.data = null;
      markOrdersAsPaidResponse.error = null;
      markOrdersAsPaidResponse.loaded = null;
    };
  }, [markOrdersAsPaidResponse]);

  return (
    <div onClick={prevent} style={{maxWidth: '800px'}}>
      {invoicesForPay.length > 0 && (
        <>
          <table>
            <thead>
              <tr>
                <th style={{width: '3%'}}></th>
                <th style={{width: '15%'}}>{t('supplierOrders.invoiceNumber')}</th>
                <th style={{width: '15%'}}>{t('supplierOrders.status')}</th>
                <th style={{width: '15%'}}>{t('supplierOrders.invoiceDate')}</th>
                <th style={{width: '10%'}}>{t('supplierOrders.dueDate')}</th>
                <th style={{width: '15%'}}>{t('supplierOrders.deliveryDate')}</th>
                <th style={{width: '10%'}}>{t('supplierOrders.total')}</th>
              </tr>
            </thead>
            <tbody>
              {invoicesForPay.map((invoice: any) => (
                <tr key={invoice.invoiceNumber}>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!checkedInvoices[invoice.invoiceNumber]} // Ensure boolean value
                      onChange={(e) => handleCheckboxChange(e, invoice.invoiceNumber)}
                    />
                  </td>
                  <td>
                    <a className="link" href={'/supplierOrders/' + invoice.orderId} target="_blank" rel="noreferrer">
                      {invoice.invoiceNumber}
                    </a>
                  </td>
                  <td>{invoice.status}</td>
                  <td>{invoice.invoiceDateFormatted}</td>
                  <td>{invoice.dueDateFormatted}</td>
                  <td>{invoice.deliveryDateFormatted}</td>
                  <td>{formatCurrency(invoice.total)}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} style={{textAlign: 'right'}}></td>
                <td>
                  <strong>{t('supplierOrders.shouldBePaid')}:</strong>
                </td>
                <td>
                  <strong>{formatCurrency(totalForPay)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </>
      )}
      {invoicesNotDue.length > 0 && (
        <>
          <table>
            <thead>
              <tr>
                <th style={{width: '3%'}}></th>
                <th style={{width: '15%'}}>{t('supplierOrders.invoiceNumber')}</th>
                <th style={{width: '15%'}}>{t('supplierOrders.status')}</th>
                <th style={{width: '15%'}}>{t('supplierOrders.invoiceDate')}</th>
                <th style={{width: '10%'}}>{t('supplierOrders.dueDate')}</th>
                <th style={{width: '15%'}}>{t('supplierOrders.deliveryDate')}</th>
                <th style={{width: '10%'}}>{t('supplierOrders.total')}</th>
              </tr>
            </thead>
            <tbody>
              {invoicesNotDue.map((invoice: any) => (
                <tr key={invoice.invoiceNumber}>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!checkedInvoices[invoice.invoiceNumber]} // Ensure boolean value
                      onChange={(e) => handleCheckboxChange(e, invoice.invoiceNumber)}
                    />
                  </td>
                  <td>
                    <a target="_blank" className="link" href={'/supplierOrders/' + invoice.orderId} rel="noreferrer">
                      {invoice.invoiceNumber || invoice.orderId}
                    </a>
                  </td>
                  <td>{invoice.status}</td>
                  <td>{invoice.invoiceDateFormatted}</td>
                  <td>{invoice.dueDateFormatted}</td>
                  <td>{invoice.deliveryDateFormatted}</td>
                  <td>{formatCurrency(invoice.total)}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} style={{textAlign: 'right'}}></td>
                <td>
                  <strong>
                    {t('supplierOrders.shouldNotBePaid')}
                    <br />
                    {t('supplierOrders.total')}:
                  </strong>
                </td>
                <td>
                  <strong>{formatCurrency(totalNotDue)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </>
      )}
      <div style={{marginBottom: '10px'}}>
        <span style={{fontSize: '1.4rem'}}>
          {t('supplierOrders.selected')}: {formatCurrency(totalSum)}
        </span>{' '}
        <br />
        {t('supplierOrders.selectedForPay')}: {formatCurrency(totalForPaySum)} &nbsp;&nbsp;
        {t('supplierOrders.selectedNotDue')}: {formatCurrency(totalNotDueSum)}
      </div>

      <Button onClick={() => copyToClipboard()} variant="contained" color="primary">
        {t('general.copyToClipboard')}
      </Button>
      <Button
        onClick={() => copyToClipboardAndMarkAsPaid()}
        variant="contained"
        color="primary"
        style={{marginLeft: '15px'}}
      >
        {t('supplierOrders.copyAndPay')}
      </Button>
      <br />
    </div>
  );
};

export default OpenInvoicesActions;
