import React, {useEffect, useState} from 'react';
import {Done} from '@material-ui/icons';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {Box, Button, Grid, Link, Tab, Tabs, Typography} from '@material-ui/core';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {RunSync, SyncStatus} from '../SyncStatuses/SyncStatus';
import moment from 'moment';
import {Sync} from '@material-ui/icons';
import {SyncTypes} from '../SyncStatuses/SyncStatuses';
import {ISupplier} from '../../types/Supplier/ISupplier';
import CONFIG from '../../lib/config';
import {appActions} from '../../modules/app/actions';
import {TabContext, TabPanel} from '@material-ui/lab';
import CustomerComplaints from './CustomerComplaints';
import SupplierOrdersBySupplier from './SupplierOrdersBySupplier';
import SupplierComplaintsBySupplier from './SupplierComplaintsBySupplier';
import useDataFetcher from '../../hooks/dataFetcher';
import {EntityType, ICustomField} from '../../types/CustomFields/CustomField';
import {CustomField} from '../../components/Product/CustomField';
import SupplierPaymentsBySupplier from './SupplierPaymentsBySupplier';
import {SupplierConnectionType} from '../../types/Supplier/SupplierConnectionType';

const SupplierDetails = (props: any) => {
  const {
    match: {
      params: {id},
    },
  } = props;

  const {data: customFields} = useDataFetcher('get_custom_field_entity_fields', {
    entityType: EntityType.SUPPLIER,
  });
  const [deletePriceListInfoRes, deletePriceListInfo] = useFetch<any>('delete_pricelist_info');
  const [supplierDetails, getSupplierDetails] = useFetch<any>('get_supplier_by_id');
  const [syncStatus, getSyncStatus] = useStorlessFetch('sync_statuses_name');
  const [matchMpnsAndEansRes, doMatchMpnsAndEans] = useFetch<any>('match_mpns_and_eans');
  const [supplier, setSupplier] = useState<ISupplier>(null as any);
  const handleTabChange = (event: any, newValue: any) => setTabValue(newValue);
  const [tabValue, setTabValue] = useState<any>('0');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onDeletePriceList = () => {
    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('syncStatus.deletePriceList'),
        onNo: () => {
          dispatch(modalActions.closeModal());
        },
        onYes: () => {
          deletePriceListInfo({id});
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const matchMpnsAndEans = () => {
    if (confirm(t('general.areYouSure'))) {
      doMatchMpnsAndEans({id: supplier._id});
    }
  };

  useEffect(() => {
    if (matchMpnsAndEansRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (matchMpnsAndEansRes?.error) {
      dispatch(appActions.showSnackBar({text: matchMpnsAndEansRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      matchMpnsAndEansRes.data = null;
      matchMpnsAndEansRes.error = null;
    };
  }, [matchMpnsAndEansRes]);

  useEffect(() => {
    getSupplierDetails({id});
  }, [id]);

  useEffect(() => {
    if (supplierDetails.data) {
      setSupplier(supplierDetails.data);
    }
  }, [supplierDetails]);

  useEffect(() => {
    if (supplier) {
      getSyncStatus({name: supplier.name});
    }
  }, [supplier]);

  useEffect(() => {
    if (deletePriceListInfoRes?.data) {
      getSupplierDetails({id});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (deletePriceListInfoRes?.error) {
      dispatch(appActions.showSnackBar({text: deletePriceListInfoRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      deletePriceListInfoRes.data = null;
      deletePriceListInfoRes.error = null;
    };
  }, [deletePriceListInfoRes]);

  const syncStatusData = syncStatus.data ? syncStatus.data[0] : null;
  const onClickHandler = () => dispatch(modalActions.addModal(MODALS.NEW_SUPPLIER, {supplierId: id, customFields}));

  return (
    supplier && (
      <div className="supplier-details">
        <div className="center-content">
          <div className="supplier-details__header">
            <p>
              {t('supplierDetails.screenTitle')} - {supplierDetails?.data?.name}
            </p>
            <div className="supplier-details__header__buttons">
              <Button variant="contained" className="blue-button" onClick={onClickHandler}>
                {t('general.edit')}
              </Button>
            </div>
          </div>
          <br />
          <div>
            <Grid container spacing={3}>
              <Grid container item xs={12} sm={6} spacing={1} alignItems="flex-start">
                <Grid item xs={12} sm={12}>
                  <div className="supplier-details__data-container__title">
                    <LocalShippingIcon style={{fontSize: 25}} />
                    <p> {t('supplierDetails.supplierInfo')}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.companyName')}</Typography>
                  {supplier.companyName ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.email')}</Typography>
                  {supplier.email ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.phone')}</Typography>
                  {supplier.phone ?? '-'}
                </Grid>

                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.address')}</Typography>
                  {supplier.address?.address ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.zipCode')}</Typography>
                  {supplier.address?.zipCode ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.city')}</Typography>
                  {supplier.address?.city ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.country')}</Typography>
                  {supplier.address?.country ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('suppliers.excludeFromPricing')}</Typography>
                  {supplier.excludeFromPricing ? <Done /> : '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('newSupplier.paymentMethod')}</Typography>
                  {supplier.paymentMethod ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('newSupplier.paymentDuration')}</Typography>
                  {supplier.paymentDuration ?? '-'}
                </Grid>
                <Grid item xs={12} sm={4} style={{paddingLeft: 15}}>
                  <Typography style={{fontWeight: 500}}>{t('newSupplier.vatPercentage')}</Typography>
                  {supplier.vatPercentage ?? '-'}
                </Grid>
                {customFields?.map((field: ICustomField) => {
                  return (
                    <Grid item xs={12} sm={4} style={{paddingLeft: 15}} key={field.fieldName}>
                      <Typography style={{fontWeight: 500}}>{field.fieldName}</Typography>
                      <CustomField type={field.type} value={supplier?.customFields?.[field.fieldName] ?? ''} />
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container item xs={12} sm={6} spacing={1} alignItems="flex-start">
                <Grid item xs={12} sm={12}>
                  <div className="supplier-details__data-container__title">
                    <Sync style={{fontSize: 25}} />
                    <p> {t('syncStatus.status')}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  <div className="modal__text_list">
                    <Typography style={{fontWeight: 500}}>{t('syncStatus.status')}: </Typography>
                    <SyncStatus row={{status: syncStatusData?.status}} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  <div className="modal__text_list">
                    <RunSync
                      row={{syncName: SyncTypes.SUPPLIER_ARTICLES, supplierName: supplier.name}}
                      callback={() => getSupplierDetails({id})}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  <Typography style={{fontWeight: 500}}>{t('syncStatus.startedAt')}: </Typography>
                  {moment(syncStatusData?.startedAt).format('DD. MMM YYYY HH:mm:ss')}
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  <Typography style={{fontWeight: 500}}>{t('syncStatus.finishedAt')}: </Typography>
                  {syncStatusData?.finishedAt
                    ? moment(syncStatusData?.finishedAt).format('DD. MMM YYYY HH:mm:ss')
                    : '-'}
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  <Typography style={{fontWeight: 500}}>{t('syncStatus.updatedFileDate')}: </Typography>
                  {supplier?.priceListFileInfo?.updatedFileDate
                    ? moment(supplier?.priceListFileInfo?.updatedFileDate?.toString()).format('DD. MMM YYYY HH:mm:ss')
                    : '-'}
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  <Typography style={{fontWeight: 500}}>Error: </Typography>
                  {syncStatusData?.error ? t(`syncError.${syncStatusData?.error}`) : '-'}
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  <Button variant="contained" className="blue-button" onClick={onDeletePriceList}>
                    {t('syncStatus.deletePriceList')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                  {supplier.type !== SupplierConnectionType.Other && (
                    <Button variant="contained" className="blue-button" onClick={matchMpnsAndEans}>
                      {t('suppliers.matchMpnsAndEans')}
                    </Button>
                  )}
                </Grid>
                {supplier?.priceListFileInfo?.lastChangedDate ? (
                  <Grid item xs={12} sm={6} style={{paddingLeft: 20}}>
                    <Link href={`${CONFIG.syncApi}static/${supplier.name?.toLowerCase()}-preisliste.csv`}>
                      {t('syncStatus.downloadPriceList')}
                    </Link>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <br />
          </div>
        </div>

        <br />
        <TabContext value={tabValue}>
          <Box>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{style: {display: 'none'}}}
              className="tab-container"
            >
              <Tab
                className={tabValue == 0 ? 'tab-item-active' : 'tab-item'}
                label={t('supplierComplaints.title')}
                value="0"
              />
              <Tab
                className={tabValue == 1 ? 'tab-item-active' : 'tab-item'}
                label={t('general.supplierOrders')}
                value="1"
              />
              <Tab
                className={tabValue == 2 ? 'tab-item-active' : 'tab-item'}
                label={t('supplierComplaints.supplierComplaints')}
                value="2"
              />
              <Tab
                className={tabValue == 3 ? 'tab-item-active' : 'tab-item'}
                label={t('general.supplierPayments')}
                value="3"
              />
            </Tabs>
          </Box>
          <TabPanel value="0">
            <CustomerComplaints supplier={supplier} />
          </TabPanel>
          <TabPanel value="1">
            <SupplierOrdersBySupplier supplier={supplier} />
          </TabPanel>
          <TabPanel value="2">
            <SupplierComplaintsBySupplier supplierId={id} />
          </TabPanel>
          <TabPanel value="3">
            <SupplierPaymentsBySupplier supplierId={id} />
          </TabPanel>
        </TabContext>
      </div>
    )
  );
};

export default SupplierDetails;
