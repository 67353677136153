import React, {useState, ChangeEvent, useEffect} from 'react';
import {Box, Button, IconButton, Paper, Typography, withStyles} from '@material-ui/core';
import {Add as AddIcon, Close as CloseIcon} from '@material-ui/icons';

const styles = (theme: any): any => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  galleryContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    width: '100%',
    height: '200px',
    marginTop: theme.spacing(2),
    border: '1px solid #ccc',
    padding: theme.spacing(1),
  },
  imageContainer: {
    position: 'relative',
    margin: theme.spacing(1),
  },
  image: {
    width: 'auto',
    height: '150px',
    maxHeight: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  addImageButton: {
    marginBottom: theme.spacing(2),
  },
});

const CustomImageGallery = ({classes, additionalImages, onImageChange}: any) => {
  const [images, setImages] = useState<string[]>(additionalImages || []);

  useEffect(() => {
    if (additionalImages && additionalImages.length > 0) {
      setImages(additionalImages);
    }
  }, [additionalImages]);

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const selectedImages = Array.from(files);
      const base64Promises = selectedImages.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(base64Promises).then((base64Images) => {
        const newImages = [...images, ...base64Images];
        setImages(newImages);
        onImageChange(newImages);
      });
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    onImageChange(newImages);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6">Additional Images</Typography>
      <Button
        variant="outlined"
        component="span"
        className={classes.addImageButton}
        startIcon={<AddIcon />}
        onClick={() => document.getElementById('add-image-input')?.click()}
      >
        Add Image
      </Button>
      <Paper className={classes.galleryContainer}>
        {images.map((image, index) => (
          <Box key={index} className={classes.imageContainer}>
            <img src={image} alt={`Image ${index}`} className={classes.image} />
            <IconButton className={classes.closeButton} onClick={() => handleRemoveImage(index)}>
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </Paper>
      <input
        accept="image/*"
        style={{display: 'none'}}
        id="add-image-input"
        type="file"
        onChange={handleImageUpload}
        multiple
      />
    </div>
  );
};

export default withStyles(styles)(CustomImageGallery);
