import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {useFetch} from '../../hooks/fetch';
import {CircularProgress} from '@material-ui/core';

interface IOption {
  label: string;
  value: string;
}

interface IAutocomplete {
  id: string;
  placeholder: string;
  label: string;
  onInputChange?: (value: string) => void;
  onChange: (value: string) => void;
  options?: IOption[];
  defaultValue: string;
  endpoint?: string;
  disabled?: boolean;
  required?: boolean;
  filters?: any;
}

const AutocompleteComponent = (props: IAutocomplete) => {
  const {id, placeholder, label, required, onChange, options, defaultValue, disabled} = props;
  const [selectedValue, setSelectedValue] = React.useState<IOption>();
  const [dataOptions, getDataOptions] = useFetch<any>(props.endpoint as any);
  const [componentOptions, setComponentOptions] = React.useState<IOption[]>([]);
  const filterOptions = createFilterOptions({matchFrom: 'any', limit: 100});
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    if (props.endpoint) {
      getDataOptions({filters: props.filters || {}});
    } else if (props.options) {
      setComponentOptions(options!);
    }
  }, []);

  useEffect(() => {
    if (dataOptions?.data) {
      setComponentOptions(dataOptions.data);
    }
  }, [dataOptions]);

  useEffect(() => {
    if (defaultValue && !selectedValue) {
      const selected = componentOptions.find((option) => option.value === defaultValue);
      if (selected) {
        setInputValue(selected.label);
        setSelectedValue(selected);
        onChange(selected.value);
      }
    }
  }, [defaultValue, selectedValue, componentOptions, onChange]);

  const handleOnChange = (event: any, newValue: any) => {
    if (newValue) {
      setSelectedValue(newValue);
      setInputValue(newValue.label);
      onChange(newValue.value);
    } else {
      onChange('');
      setSelectedValue(null as any);
      setInputValue('');
    }
  };

  return (
    <Autocomplete
      filterOptions={filterOptions}
      className="input"
      size="small"
      placeholder={placeholder}
      defaultValue={defaultValue ? componentOptions.find((option) => option.value === defaultValue) : null}
      openOnFocus={true}
      onChange={handleOnChange}
      id={`${id}Autocomplete`}
      options={componentOptions}
      disabled={disabled}
      getOptionLabel={(option: any) => option?.label}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{...params.inputProps, value: inputValue}}
          label={placeholder}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {dataOptions.loading ? <CircularProgress color="inherit" size={10} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteComponent;
