import {Typography} from '@material-ui/core';
import {Button} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface IConfirmDIalog {
  title: string;
  content: any;
  width?: string;
  onYes?: () => void;
  onNo?: () => void;
  onOk?: () => void;
  hideDefaultCloseButton?: boolean;
}

const ConfirmDialog = (props: IConfirmDIalog) => {
  const {title, content, width, onYes, onNo, onOk} = props;
  const {t} = useTranslation();

  return (
    <div style={{...(width && {width: width})}}>
      <Typography className="modal-title">{title}</Typography>
      <div>
        <p className="modal-fields">{content}</p>
        <div className="action-buttons" style={{float: 'right', padding: '5px 10px 0px 0px'}}>
          {onYes ? (
            <Button onClick={onYes} className="blue-button">
              {t('general.yes')}
            </Button>
          ) : null}
          {onNo ? (
            <Button onClick={onNo} className="yellow-button">
              {t('general.no')}
            </Button>
          ) : null}
          {onOk ? (
            <Button onClick={onOk} className="blue-button">
              {t('general.ok')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
