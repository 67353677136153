export interface IProductScreen {
  displayedFields: {
    'productInformation.compatibility': boolean;
    'productInformation.manufacturer': boolean;
    'productInformation.productType': boolean;
    'productInformation.colour': boolean;
    'productInformation.numberOfItems': boolean;
    'productInformation.content': boolean;
    'productInformation.pageYield': boolean;
    'productInformation.doublePackage': boolean;
    'productInformation.packType': boolean;
    'productInformation.minimumStock': boolean;
    'productInformation.minimumStockReach': boolean;
    'productInformation.image': boolean;
    'productInformation.minimumStockOrder': boolean;
    'productInformation.imageDDA': boolean;
    'productInformation.imageSP': boolean;
    calculatedPrice: boolean;
    averagePrice: boolean;
    shopwareId: boolean;
    bechlemId: boolean;
    productId: boolean;
    stockDDA: boolean;
    title: boolean;
    manualTitle: boolean;
    'defectRate.defectRate': boolean;
  };
  displayedCustomFields: {
    [customField: string]: boolean;
  };
}

export const initProductScreenConfig = () => ({
  displayedFields: {
    'productInformation.compatibility': true,
    'productInformation.manufacturer': true,
    'productInformation.productType': true,
    'productInformation.colour': false,
    'productInformation.numberOfItems': false,
    'productInformation.content': false,
    'productInformation.pageYield': false,
    'productInformation.doublePackage': false,
    'productInformation.packType': false,
    'productInformation.minimumStock': false,
    'productInformation.minimumStockReach': false,
    'productInformation.image': false,
    'productInformation.minimumStockOrder': false,
    'productInformation.imageDDA': false,
    'productInformation.imageSP': false,
    calculatedPrice: false,
    averagePrice: false,
    shopwareId: true,
    bechlemId: false,
    productId: true,
    stockDDA: false,
    title: true,
    manualTitle: true,
    'defectRate.defectRate': false,
  },
  displayedCustomFields: {},
});
