export interface ISupplierOrderScreen {
  displayedFields: {
    status: boolean;
    orderNumberText: boolean;
    confirmed: boolean;
    supplierName: boolean;
    totalGross: boolean;
    additionalCost: boolean;
    createdAt: boolean;
    deliveryDate: boolean;
    deliveredAt: boolean;
    invoiceUploaded: boolean;
    'invoice.invoiceNumber': boolean;
    'invoice.date': boolean;
    'invoice.dueDate': boolean;
    'invoice.uploadedAt': boolean;
    'invoice.paidAt': boolean;
    paymentMethod: boolean;
    orderConfirmationNumber: boolean;
  };
}

export const initSupplierOrderScreenConfig = () => ({
  displayedFields: {
    status: true,
    orderNumberText: true,
    confirmed: true,
    supplierName: true,
    totalGross: true,
    additionalCost: false,
    createdAt: true,
    deliveryDate: false,
    deliveredAt: true,
    invoiceUploaded: true,
    'invoice.invoiceNumber': false,
    'invoice.date': true,
    'invoice.dueDate': false,
    'invoice.uploadedAt': false,
    'invoice.paidAt': false,
    paymentMethod: false,
    orderConfirmationNumber: false,
  },
});
