import {Button, Grid, TextField} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useRef} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';
import {OrderType} from '../../../types/Orders/OrderType';
import PickupOrderButton from './PickupOrderButton';
import ShippingAddressInfo from './ShippingAddressInfo';
import _ from 'lodash';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';
import {history} from '../../../lib/history';
import {MODALS} from '../../Modal/ModalContents';
import {useStorlessFetch} from '../../../hooks/fetch';
import {APP_ROUTES} from '../../../routers/routes';

interface Props {
  onTrackingCodeScanSuccess: (callback?: any) => void;
}

const TrackingNumberComponent = ({onTrackingCodeScanSuccess}: Props): ReactElement => {
  const [patchOrderResponse, patchOrder] = useStorlessFetch('orders_patch');
  const isTrackingNumberBeingScanned = useRef<boolean>(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {
    order,
    validationFailed,
    trackingNumberPushed,
    orderMarkedAsPickup,
    resetState,
    pushTrackingNumber,
    setShowTrackingNumberInput,
    allOrderItemsScanned,
    orderItems,
    orderItemsScanned,
    // getProforma,
    setOrder,
    setAllOrderItemsScanned,
  } = useContext(OrderScanningContext);

  const onChangeTrackingNumber = (e: any) => {
    if (isTrackingNumberBeingScanned.current) return;
    const scannedValue = e.target.value;
    const parcelTrackingNumbers = order.parcels?.map((item) => item.trackingNumber) || [];
    // Parcel number is GLS fix
    const trackingNumbers = [...parcelTrackingNumbers, order.trackingNumber, order.shippingLabel?.parcelNumber];
    const allProductsScanned =
      allOrderItemsScanned || orderItems.every((item) => item.quantity === item.quantityScanned);
    const isRegularOrderBeingScanned = allProductsScanned && trackingNumbers.includes(scannedValue);
    const isPrimeOrderBeingScanned =
      order.isPrime && scannedValue.length > 12 && trackingNumbers.includes(scannedValue);
    const allOrderItemsInParcelAreScanned = !allProductsScanned && trackingNumbers.includes(scannedValue);
    isTrackingNumberBeingScanned.current = true;

    if (isRegularOrderBeingScanned || isPrimeOrderBeingScanned) {
      // getProformaPdf();
      pushTrackingNumber({id: order._id});
    } else if (allOrderItemsInParcelAreScanned) {
      setShowTrackingNumberInput(false);
    } else {
      dispatch(appActions.showSnackBar({text: t('scanner.scanErrorTrackingNumber'), options: {severity: 'error'}}));
    }

    isTrackingNumberBeingScanned.current = false;
  };

  useEffect(() => {
    if (order.returnLabelInfo?.exchangeOrderPrintReturn && orderItemsScanned) {
      setTimeout(() => {
        dispatch(
          modalActions.addChild(MODALS.CONFIRM_DIALOG, {
            title: t('scanner.addReturnLabel'),
            content: t('scanner.addReturnLabelContent'),
            onOk: () => {
              dispatch(modalActions.closeModal());
            },
            width: '600px',
          }),
        );
      }, 600);
    }
  }, [orderItemsScanned]);

  useEffect(() => {
    isTrackingNumberBeingScanned.current = false;

    if (trackingNumberPushed.data) {
      const successMessage = orderMarkedAsPickup ? t('scanner.scanSuccessTrackingNumber') : t('general.success');
      dispatch(appActions.showSnackBar({text: successMessage, options: {severity: 'success'}}));
      const allProductsAreScanned = orderItems.every((item) => item.quantity === item.quantityScanned);

      if (allOrderItemsScanned || allProductsAreScanned) {
        closeModal();
      } else {
        redirectToScanningForm();
      }
    } else if (trackingNumberPushed.data === false) {
      dispatch(appActions.showSnackBar({text: t('scanner.sendErrorTrackingNumber'), options: {severity: 'error'}}));
    }
  }, [trackingNumberPushed]);

  useEffect(() => {
    if (patchOrderResponse.data) {
      setAllOrderItemsScanned(1);
    }
  }, [patchOrderResponse]);

  const redirectToScanningForm = () => setShowTrackingNumberInput(false);

  const closeModal = () => {
    onTrackingCodeScanSuccess(resetState);
  };

  // const getProformaPdf = () => {
  //   if (order.shippingAddress?.countryCode?.toUpperCase() === 'GB' && order.orderType === OrderType.Amazon) {
  //     getProforma({id: order._id});
  //   }
  // };

  const handleCancelEditOrderModal = () => dispatch(modalActions.closeModal(MODALS.EDIT_ORDER));

  const handleSubmitEditOrderModal = async (values: any) => {
    const updatedOrderData = _.omitBy(values, (value, key) => (order as any)[key] === value);
    const data = {...updatedOrderData, id: order._id};
    patchOrder(data);
    setOrder({...order, ...data});
    handleCancelEditOrderModal();
  };

  const handleEditOrderClick = async () => {
    dispatch(
      modalActions.addChild(MODALS.EDIT_ORDER, {
        title: t('orderDetails.editOrder') || '',
        onSubmit: handleSubmitEditOrderModal,
        onCancel: handleCancelEditOrderModal,
        orderObject: order,
        editingAfterValidationFailed: true,
      }),
    );
  };

  return (
    <Grid container spacing={1} style={{marginTop: '10px', padding: '5px', marginBottom: '10px'}}>
      <Grid item xs={12} sm={6}>
        <ShippingAddressInfo order={order} paddingLeft={'0px'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CloseIcon
          onClick={() => dispatch(modalActions.closeModal())}
          style={{float: 'right', cursor: 'pointer', margin: '10px'}}
        />
        <p style={{textAlign: 'right', marginRight: '10px'}}>
          {t('orderDetails.orderType')}: {order.orderType}
        </p>
      </Grid>
      {!order.isPickupOrder ? (
        <>
          <Grid item xs={12} sm={12} style={{margin: '40px 0px 10px 0px', textAlign: 'center'}}>
            {validationFailed || order.status === OrderStatusType.ValidationFailed ? (
              <div>
                <a
                  style={{color: 'red', display: 'inline-flex', marginLeft: '10px'}}
                  href={`${APP_ROUTES.ORDERS}/${order._id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Fix {order.shippingType} Validation Failed</p>
                </a>
                <Button variant="contained" className="blue-button" onClick={handleEditOrderClick}>
                  {t('orderDetails.editOrder')}
                </Button>
              </div>
            ) : null}
            <span>
              {order.orderType === OrderType.Jtl
                ? t('scanner.sendJtlTrackingNumber')
                : t('scanner.sendAmazonTrackingNumber')}
            </span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              onChange={_.debounce(onChangeTrackingNumber, 500)}
              id="trackingNumber"
              placeholder={t('scanner.trackingNumberLabel')}
              label={t('scanner.trackingNumber')}
              variant="outlined"
              autoFocus
              disabled={isTrackingNumberBeingScanned.current}
            />
          </Grid>
          <Grid alignItems="center" item xs={12} sm={12} style={{textAlign: 'center'}}>
            <PickupOrderButton />
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={12} style={{margin: '40px 0px 10px 0px', textAlign: 'center', fontWeight: 'bold'}}>
          <div>{order.isPickupOrder ? t('scanner.pickupOrderMessage') : t('scanner.trackingNumberAlreadySent')}</div>
          <div style={{marginTop: '20px'}}>
            <Button
              variant="contained"
              className="blue-button"
              onClick={() => {
                pushTrackingNumber({id: order._id, isPickupOrder: true});
                dispatch(modalActions.closeModal());
                history.goBack();
              }}
            >
              {t('general.ok')}
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default TrackingNumberComponent;
