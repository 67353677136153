import React, {useEffect, useState} from 'react';
import Form, {IField} from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {ISupplierOrder} from '../../types/SupplierOrders/ISupplierOrder';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';
import {ICustomField, ICustomFieldType} from '../../types/CustomFields/CustomField';
import {getType} from '../../lib/customFields';
import {useStorlessFetch} from '../../hooks/fetch';

export interface IEditSupplierOrderProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  orderObject?: ISupplierOrder;
  customFields: ICustomField[];
}

const generateInitialValues = (fields: any[], orderObject: any) => {
  if (orderObject) {
    const defaults = _.omit(orderObject, ['_id', '__v']);
    return defaults;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      if (field.type === DataTableItemType.DATE) {
        defaults = {...defaults, [field.fieldKey]: null};
      } else {
        defaults = {...defaults, [field.fieldKey]: ''};
      }
    });
    return defaults;
  }
};

const EditSupplierOrder = (props: IEditSupplierOrderProps) => {
  const [supplierPaymentMethods, setSupplierPaymentMethods] = useState<{value: string; label: string}[]>([]);
  const [otherConfig, getOtherConfig] = useStorlessFetch('feature_configuration');
  const {title, onSubmit, onCancel, orderObject, customFields} = props;
  const {t} = useTranslation();

  useEffect(() => {
    getOtherConfig({featureName: 'otherConfig'});
  }, []);

  useEffect(() => {
    if (otherConfig?.data?.data?.supplierPaymentMethods) {
      const data = otherConfig.data.data.supplierPaymentMethods.map((x: any) => ({
        label: x,
        value: x,
      }));
      setSupplierPaymentMethods(data);
    }
  }, [otherConfig]);

  const fields: IField[] = [
    {
      label: t('supplierOrderDetails.additionalCost'),
      placeholder: t('supplierOrderDetails.additionalCost'),
      fieldKey: 'additionalCost',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('supplierOrderDetails.invoiceNumber'),
      placeholder: t('supplierOrderDetails.invoiceNumber'),
      fieldKey: 'invoice.invoiceNumber',
      type: 'input',
    },
    {
      label: t('supplierOrderDetails.invoiceDate'),
      placeholder: t('supplierOrderDetails.invoiceDate'),
      fieldKey: 'invoice.date',
      type: DataTableItemType.DATE,
    },
    {
      label: t('supplierOrderDetails.paymentMethod'),
      placeholder: t('supplierOrderDetails.paymentMethod'),
      fieldKey: 'paymentMethod',
      type: 'select',
      options: supplierPaymentMethods,
    },
    {
      label: t('supplierOrders.paidAt'),
      placeholder: t('supplierOrders.paidAt'),
      fieldKey: 'invoice.paidAt',
      type: DataTableItemType.DATE,
    },
    {
      label: t('supplierOrderDetails.confirmed'),
      placeholder: t('supplierOrderDetails.confirmed'),
      fieldKey: 'confirmed',
      type: 'checkbox',
    },
    {
      label: t('supplierOrderDetails.orderConfirmationNumber'),
      placeholder: t('supplierOrderDetails.orderConfirmationNumber'),
      fieldKey: 'orderConfirmationNumber',
      type: 'input',
    },
  ];

  if (customFields.length > 0) {
    customFields.forEach((field: ICustomField) => {
      const type = getType(field.type);
      fields.push({
        label: field.fieldName,
        placeholder: field.fieldName,
        fieldKey: `customFields.${field.fieldName}`,
        type: type,
        ...(type === 'input' ? {fieldKeyType: field.type === ICustomFieldType.NUMBER ? 'number' : 'string'} : {}),
      });
    });
  }

  const initialValues = generateInitialValues(fields, orderObject);

  const supplier = orderObject?.supplier;
  let errorMessage = '';

  if (supplier) {
    const paymentMethod = supplier.paymentMethod;
    const vatPercentage = supplier.vatPercentage;
    const paymentDuration = supplier.paymentDuration;
    if (!paymentMethod) {
      errorMessage += t('supplierOrderDetails.noPaymentMethodMessage');
    }

    if (!paymentDuration) {
      if (paymentDuration !== 0) {
        errorMessage += t('supplierOrderDetails.noPaymentDurationMessage');
      }
    }

    if (!vatPercentage) {
      if (vatPercentage !== 0) {
        errorMessage += t('supplierOrderDetails.noVatPercentageMessage');
      }
    }
  }

  return (
    <>
      <Form
        title={title}
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></Form>
      {errorMessage && (
        <div style={{backgroundColor: 'red', color: 'white', padding: '10px', maxWidth: '100%'}}>{errorMessage}</div>
      )}
    </>
  );
};

export default EditSupplierOrder;
