import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {appActions} from '../../../modules/app/actions';
import {useStorlessFetch} from '../../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';

export default function EbayConfig({configObjectProp}: IProps) {
  const [updateCustomConfigRes, updateCustomConfig] = useStorlessFetch('update_custom_config');
  const [configObject, setConfigObject] = useState<ConfigObject | null>(null);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    setConfigObject(configObjectProp);
  }, []);

  const onConfigObjectSave = () => {
    if (configObject) {
      updateCustomConfig({featureName: 'eBayConfig', customConfig: {...configObject}});
    }
  };

  const IconEditButton = () => (
    <IconButton onClick={() => setEditing((prevState) => !prevState)}>
      <EditIcon htmlColor={editing ? '#5cabef' : 'none'} style={{fontSize: 20}} />
    </IconButton>
  );

  const updateObjectState = (value: any, fieldName: keyof ConfigObject) => {
    setConfigObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (updateCustomConfigRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setEditing(false);
    } else if (updateCustomConfigRes.error) {
      dispatch(appActions.showSnackBar({text: updateCustomConfigRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      updateCustomConfigRes.data = null;
      updateCustomConfigRes.error = null;
    };
  }, [updateCustomConfigRes, dispatch, t]);

  return (
    configObject && (
      <div className="order-details">
        <div className="order-info">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={6}>
              <div className="order-info-title">Config</div>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right', paddingRight: '7px'}}>
              <IconEditButton />
              {editing && (
                <Button className="blue-button" variant="contained" onClick={onConfigObjectSave}>
                  {t('general.save')}
                </Button>
              )}
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
              <span className="order-info-label">{t('pricing.taxPercentage')}</span>
              {editing ? (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={configObject?.taxPercentage}
                  InputProps={{inputProps: {min: 0, step: 0.01}}}
                  onChange={(event) => {
                    updateObjectState(+event.target.value, 'taxPercentage');
                  }}
                />
              ) : (
                <div className="order-info-label">{configObject?.taxPercentage}</div>
              )}
            </Grid>

            <Grid item xs={12} sm={6} style={{paddingRight: '10px'}} className="order-info-item">
              <span className="order-info-label">{t('pricing.feePercentage')}</span>
              {editing ? (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={configObject?.fee ?? ''}
                  InputProps={{inputProps: {min: 0, step: 0.01}}}
                  onChange={(event) => updateObjectState(+event.target.value, 'fee')}
                />
              ) : (
                <div className="order-info-label">{configObject?.fee}</div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} style={{paddingRight: '10px'}} className="order-info-item">
              <span className="order-info-label">{t('pricing.shippingCost')}</span>
              {editing ? (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={configObject?.shippingCost ?? ''}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => updateObjectState(+event.target.value, 'shippingCost')}
                />
              ) : (
                <div className="order-info-label">{configObject?.shippingCost}</div>
              )}
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
              <span className="order-info-label">{t('pricing.priceType')}</span>
              <div className="product-info-input">
                <FormControl component="fieldset" disabled={!editing}>
                  <RadioGroup
                    row
                    aria-label="priceType"
                    name="priceType"
                    value={configObject?.priceType}
                    onChange={(event) => updateObjectState(event.target.value, 'priceType')}
                  >
                    <FormControlLabel value="net" control={<Radio />} label="Net price" />
                    <FormControlLabel value="gross" control={<Radio />} label="Gross price" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} style={{paddingRight: '10px'}} className="order-info-item">
              <span className="order-info-label">{t('pricing.multiVariantTitleMaxChars')}</span>
              {editing ? (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={configObject?.multiVariantTitleMaxChars ?? ''}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => updateObjectState(+event.target.value, 'multiVariantTitleMaxChars')}
                />
              ) : (
                <div className="order-info-label">{configObject?.multiVariantTitleMaxChars}</div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} style={{paddingRight: '10px'}} className="order-info-item">
              <span className="order-info-label">{t('pricing.multiVariantManufacturerMaxChars')}</span>
              {editing ? (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={configObject?.multiVariantManufacturerMaxChars ?? ''}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => updateObjectState(+event.target.value, 'multiVariantManufacturerMaxChars')}
                />
              ) : (
                <div className="order-info-label">{configObject?.multiVariantManufacturerMaxChars}</div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} style={{paddingRight: '10px'}} className="order-info-item">
              <span className="order-info-label">{t('pricing.multiVariantChildMaxChars')}</span>
              {editing ? (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={configObject?.multiVariantChildMaxChars ?? ''}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => updateObjectState(+event.target.value, 'multiVariantChildMaxChars')}
                />
              ) : (
                <div className="order-info-label">{configObject?.multiVariantChildMaxChars}</div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
              <span className="order-info-label">{t('pricing.pricingSyncOptimization')}</span>
              <Checkbox
                disabled={!editing}
                color="primary"
                checked={configObject?.pricingSyncOptimization}
                onChange={(event) => {
                  updateObjectState(event.target.checked, 'pricingSyncOptimization');
                }}
              ></Checkbox>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  );
}

interface ConfigObject {
  fee?: number;
  shippingCost?: number;
  multiVariantTitleMaxChars?: number;
  multiVariantManufacturerMaxChars?: number;
  multiVariantChildMaxChars?: number;
  priceType?: string;
  taxPercentage?: number;
  pricingSyncOptimization?: boolean;
}

interface IProps {
  configObjectProp: ConfigObject;
}
