import {TableRow, TableCell, TextField, Select, MenuItem} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import ReturnOrderScanningContext from '../../../screens/Orders/Context/ReturnOrderScanningContext ';
import {IScannedOrderItem} from '../../../types/OrderItems/IScannedOrderItem';
import {CustomerReturnStatus} from '../../../types/Orders/CustomerReturnStatus';

interface IProps {
  product: IScannedOrderItem;
}

const MultiBoxReturnOrderItemsTable = ({product}: IProps): ReactElement => {
  const {scannedProducts, setScannedProducts} = useContext(ReturnOrderScanningContext);
  return product?.scannedAsMultiBox ? (
    <>
      {product.barcodeArticleNumbers.multiBoxArticleNumbers.map((multiBoxProduct) => (
        <TableRow key={multiBoxProduct.productId}>
          <TableCell>- MultiBox</TableCell>
          <TableCell>{multiBoxProduct.barcodeArticleNumbers[0]}</TableCell>
          <TableCell>{multiBoxProduct.productTitle}</TableCell>
          <TableCell>
            <Select
              id="returnStatus"
              value={multiBoxProduct.returnType}
              onChange={(e: any) => {
                if (e.target.value) {
                  multiBoxProduct.returnType = e.target.value;
                  setScannedProducts([...scannedProducts]);
                }
              }}
              defaultValue={CustomerReturnStatus.returnedToStock}
            >
              {Object.values(CustomerReturnStatus)?.map((item: any) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </TableCell>
          <TableCell>
            {multiBoxProduct.returnType === CustomerReturnStatus.createdSupplierComplaint && (
              <TextField
                type="text"
                variant="outlined"
                value={multiBoxProduct.complaintMessage}
                onChange={(e) => {
                  multiBoxProduct.complaintMessage = e.target.value;
                  setScannedProducts([...scannedProducts]);
                }}
              ></TextField>
            )}
          </TableCell>
          <TableCell>{multiBoxProduct.numberOfProducts}</TableCell>
          <TableCell>
            <TextField
              type="number"
              variant="outlined"
              value={multiBoxProduct.returnQuantityScanned}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: multiBoxProduct.numberOfProducts * product.quantity,
                },
              }}
              onChange={(e) => {
                const quantity = +e.target.value;
                if (quantity >= 0 && quantity <= multiBoxProduct.numberOfProducts * product.quantity) {
                  multiBoxProduct.returnQuantityScanned = quantity;
                  setScannedProducts([...scannedProducts]);
                }
              }}
            ></TextField>
          </TableCell>
        </TableRow>
      ))}
    </>
  ) : (
    <></>
  );
};

export default MultiBoxReturnOrderItemsTable;
