import React, {createContext} from 'react';
import {IScannedOrderItem} from '../../../types/OrderItems/IScannedOrderItem';

const ReturnOrderScanningContext = createContext({} as IReturnOrderScanningContext);
export const ReturnOrderScanningProvider = ReturnOrderScanningContext.Provider;
export default ReturnOrderScanningContext;

interface IReturnOrderScanningContext {
  product: IScannedOrderItem;
  scannedProducts: IScannedOrderItem[];
  initialScannedProducts: IScannedOrderItem[];
  returnOrderItemStatus: string;
  reasonForComplaint: string;
  orderId: string;
  refundShippingCosts: boolean;
  skipCreationOfCreditNote: boolean;
  discount: number;
  setReturnOrderItemStatus: (data: any) => void;
  setReasonForComplaint: (data: string) => void;
  setBarcode: (data: string) => void;
  setProduct: (data: IScannedOrderItem) => void;
  setScannedProducts: (data: IScannedOrderItem[]) => void;
  setReturnedOrderScannedConfirmed: (data: boolean) => void;
  doHandleReturnedOrderItems: (data: any) => void;
  setRefundShippingCosts: (data: any) => void;
  setSkipCreationOfCreditNote: (data: any) => void;
  setDiscount: (discount: number) => void;
  handleReturnedOrderItems: any;
}
