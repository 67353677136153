import React, {useState, useEffect, ChangeEvent, useMemo} from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Grid,
  Button,
  Box,
  LinearProgress,
} from '@material-ui/core';
import AutocompleteComponent from '../../components/Autocomplete/Autocomplete';
import {useStorlessFetch} from '../../hooks/fetch';
import {IProduct} from '../../types/Product/IProduct';
import EbayVariation from './EbayVariation';
import {useEbayStyles} from './style';
import _ from 'lodash';
import {EbayCompatibleTypes, IEbayMultiVariant} from '../../types/Product/IEbayMultiVariant';
import {useTranslation} from 'react-i18next';
import PublishVariantToEbayButton from '../../components/Ebay/PublishVariantToEbayButton';
import {history} from '../../lib/history';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {ProductCompatibility} from '../../types/Product/ProductCompatibility';
import CustomImageGallery from './EbayMultiImage';
import DescriptionCounter from '../../components/Product/ShopDescriptions/DescriptionCounter';
import {Variant} from '../../types/Ebay/variant';

export default function EbayMultiVariantItemScreen(props?: any) {
  const [products, getProducts] = useStorlessFetch('ebay_for_multi_variant_item');
  const [multiVariantProduct, getMultiVariantProduct] = useStorlessFetch('ebay_multi_variant_product');
  const [createItemRes, createItem] = useStorlessFetch('ebay_create_multi_variant');
  const [eBayConfig, getEbayConfig] = useStorlessFetch('feature_configuration');
  const [mainProduct, setMainProduct] = useState<IProduct | null>(null);
  const [mainProductId, setMainProductId] = useState<string>('');
  const [possibleProducts, setPossibleProducts] = useState<Variant[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [compatibleTypes, setCompatibleTypes] = useState<string[]>(Object.values(EbayCompatibleTypes));
  const [config, setConfig] = useState<any>(null);
  const [formData, setFormData] = useState({
    product_id: '',
    title: '',
    selectedColors: [] as string[],
    manufacturerNumbers: '',
    selectedVariations: [] as Variant[],
    inventoryItemGroupKey: '',
    uploadedImage: '',
    selectedPrinterModels: [] as string[],
    product: null,
    additionalImages: [] as string[],
    selectedCompatibleType: '',
    selectedProducts: {} as any,
  });
  const classes = useEbayStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {
    match: {
      params: {id},
    },
  } = props;

  const setMultiVariantFormData = (data: IEbayMultiVariant) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        inventoryItemGroupKey: data.inventoryItemGroupKey,
        manufacturerNumbers: data.manufacturerNumbers,
        selectedPrinterModels: data.printerModels,
        selectedVariations: data.variants,
        uploadedImage: data.uploadedImage,
        product_id: data.product?._id,
        selectedColors: data.colors,
        title: data.title,
        additionalImages: data.additionalImages,
        selectedCompatibleType: data.compatibleType,
        selectedProducts: data.selectedProducts || {},
      } as any;
    });
  };

  const updateVariantsWithTitle = (targetVariant: Variant) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedVariations: prevData.selectedVariations.map((variant) => {
        return variant.product_id === targetVariant.product_id
          ? {...variant, title: prevData.selectedProducts?.[variant.product_id]?.title || variant.aliasTitle}
          : variant;
      }),
    }));
  };

  const updateSelectedProductTitle = (updatedTitle: string, targetVariant: Variant) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedProducts: {
        ...(prevData.selectedProducts || {}),
        [targetVariant.product_id]: {
          ...(prevData.selectedProducts[targetVariant.product_id] || {}),
          title: updatedTitle,
        },
      },
    }));
  };

  const updateSelectedProductPrice = (updatedPrice: number, targetVariant: Variant) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedProducts: {
        ...(prevData.selectedProducts || {}),
        [targetVariant.product_id]: {
          ...(prevData.selectedProducts[targetVariant.product_id] || {}),
          fixedPrice: updatedPrice,
        },
      },
    }));
  };

  useEffect(() => {
    getEbayConfig({featureName: 'eBayConfig'});
  }, []);

  useEffect(() => {
    if (id) {
      getMultiVariantProduct({id});
    }
  }, [id]);

  useEffect(() => {
    if (eBayConfig.data) {
      setConfig(eBayConfig.data.data?.customConfig);
    }
  }, [eBayConfig]);

  useEffect(() => {
    if (createItemRes?.data && !createItemRes?.data?.error) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      history.push(`/ebay-multi-variant-items`);
    }

    if (createItemRes?.data?.error) {
      const multiVariantId = createItemRes?.data?.multiVariant?._id;
      multiVariantId && history.push(`/ebay-multi-variant-product/${multiVariantId}`);

      dispatch(
        appActions.showSnackBar({text: createItemRes?.data?.error?.message || 'Error', options: {severity: 'error'}}),
      );
    }

    return () => {
      createItemRes.data = null;
      createItemRes.error = null;
      createItemRes.loading = null;
    };
  }, [createItemRes]);

  useEffect(() => {
    if (multiVariantProduct?.data?.product) {
      setMainProductId(multiVariantProduct.data.product?._id);
      setMainProduct(multiVariantProduct.data.product);
      if (!multiVariantProduct?.data?.selectedProducts) {
        setFormData((prevState) => ({
          ...prevState,
          selectedProducts: {},
        }));
      }
    }
  }, [multiVariantProduct]);

  useEffect(() => {
    if (mainProductId) {
      getProducts({id: mainProductId, IGK_KEY: multiVariantProduct?.data?.inventoryItemGroupKey});
      !id && setFormData((prevState) => ({...prevState, product_id: mainProductId}));
    }

    if (multiVariantProduct?.data) {
      const data = multiVariantProduct.data as IEbayMultiVariant;

      setMultiVariantFormData(data);
    }

    return () => {
      multiVariantProduct.data = null;
      multiVariantProduct.error = null;
    };
  }, [mainProductId]);

  useEffect(() => {
    if (products.data) {
      const product = formData.product || products.data.mainProduct;
      const matchingProducts = products.data.matchingProducts.map((x: Variant) => ({...x, aliasTitle: x.title}));

      setPossibleProducts(matchingProducts);
      setMainProduct(product || mainProduct);
      setColors(products.data.colors);
      setFormData({
        ...formData,
        title: formData.title || product.manualTitle || product.title,
        inventoryItemGroupKey: `${product.IGK_KEY}`,
        selectedVariations: formData.selectedVariations || [],
        selectedColors: formData.selectedColors || [],
        selectedCompatibleType: formData.selectedCompatibleType,
        selectedPrinterModels: formData.selectedPrinterModels?.length
          ? formData.selectedPrinterModels
          : product.printerModels,
      });
    }
  }, [products.data]);

  const handleToggleVariation = (variation: Variant) => {
    setFormData((prevData) => {
      const updatedVariations = [...prevData.selectedVariations];

      if (updatedVariations.find((x) => x.product_id === variation.product_id)) {
        return {
          ...prevData,
          selectedVariations: updatedVariations.filter((v) => v.product_id !== variation.product_id),
        };
      } else {
        const newVariations = variation.printerModels
          ?.filter((x) => formData.selectedPrinterModels?.includes(x))
          .map((item) => {
            return {
              ...variation,
              printerModels: [],
              printerModel: item,
              initialPrinterModelName: item,
            };
          });

        return {
          ...prevData,
          selectedVariations: [...updatedVariations, ...(newVariations || [])].map((x, i) => ({
            ...x,
            sku: `${mainProduct?.IGK_KEY}_${i + 1}`,
            ean: x.compatibility === ProductCompatibility.COMPATIBLE ? 'nicht zutreffend' : x.ean,
          })),
        };
      }
    });
  };

  const handleSubmit = () => {
    createItem(formData);
  };

  const onMainProductChange = (value: string): void => {
    setMainProductId(value);
    if (!id) {
      setFormData((prevState) => ({
        ...prevState,
        selectedColors: [] as string[],
        manufacturerNumbers: '',
        selectedVariations: [] as Variant[],
        uploadedImage: '',
        selectedPrinterModels: [] as string[],
        additionalImages: [] as string[],
        selectedCompatibleType: '',
      }));
    }
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setFormData((prevState) => ({
            ...prevState,
            uploadedImage: e?.target?.result as string,
          }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setFormData((prevData) => {
      const data = prevData.selectedVariations.filter((x) =>
        formData.selectedPrinterModels?.includes(x.initialPrinterModelName),
      );
      const currentPrinterModels = prevData.selectedVariations.map((x) => x.initialPrinterModelName);
      const newPrinterModels = formData.selectedPrinterModels.filter((item) => !currentPrinterModels.includes(item));
      const newVariations: Variant[] = [];

      possibleProducts
        .filter((x) => prevData.selectedVariations.find((y) => y.initialTitle === x.initialTitle))
        .forEach((item) => {
          newPrinterModels.forEach((model) => {
            newVariations.push({
              ...item,
              printerModels: [],
              printerModel: model,
              initialPrinterModelName: model,
            });
          });
        });

      return {
        ...prevData,
        selectedVariations: [...data, ...newVariations].map((x, i) => ({
          ...x,
          sku: `${multiVariantProduct?.data?.inventoryItemGroupKey}_${i + 1}`,
          ean: x.compatibility === ProductCompatibility.COMPATIBLE ? 'nicht zutreffend' : x.ean,
        })),
      };
    });
  }, [formData.selectedPrinterModels]);

  const handleMainProductPrinterModelChange = (model: string) => {
    setFormData((prevState) => {
      if (prevState.selectedPrinterModels.includes(model)) {
        return {
          ...prevState,
          selectedPrinterModels: prevState.selectedPrinterModels.filter((item) => item !== model),
        };
      } else {
        return {...prevState, selectedPrinterModels: [...prevState.selectedPrinterModels, model]};
      }
    });
  };

  const handleVariationUpdate = (index: number, updatedVariation: Variant) => {
    setFormData((prevData) => {
      const updatedSelectedVariations = [...prevData.selectedVariations];
      updatedSelectedVariations[index] = updatedVariation;
      return {
        ...prevData,
        selectedVariations: updatedSelectedVariations,
      };
    });
  };

  const selectedVariationsInfo = () => {
    return (
      <>
        ({t('eBayMultiVariant.selectedVariations')}:{' '}
        <b style={{color: `${formData.selectedVariations.length <= 250 ? 'inherit' : 'red'}`}}>
          {formData.selectedVariations.length || 0} / 250
        </b>
        )
      </>
    );
  };

  const memoizedSelectedVariations = useMemo(() => {
    return formData.selectedVariations.map((variant, index) => (
      <EbayVariation
        key={`${variant.initialTitle}${variant.initialPrinterModelName}`}
        index={index}
        variant={variant}
        onUpdate={handleVariationUpdate}
      />
    ));
  }, [formData.selectedVariations]);

  const isDisabled =
    formData.selectedVariations.length <= 0 ||
    formData.manufacturerNumbers.length <= 0 ||
    !formData.uploadedImage ||
    createItemRes.loading;

  const handlePriceChange = (variant: Variant) => {
    setFormData((prevData) => {
      const updatedSelectedVariations = prevData.selectedVariations.map((variant) =>
        variant.product_id === variant.product_id
          ? {...variant, fixedPrice: prevData.selectedProducts[variant.product_id]?.fixedPrice}
          : variant,
      );

      return {
        ...prevData,
        selectedVariations: updatedSelectedVariations,
      };
    });

    setPossibleProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.product_id === variant.product_id
          ? {...product, price: formData.selectedProducts[variant.product_id].price}
          : product,
      ),
    );
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Paper className="product-details">
        <div className="product-header">
          <div className="product-header-title">
            <span className="left-title">
              {t('general.products')} /{' '}
              <span className="right-title">
                {!id ? t('eBayMultiVariant.createTitle') : t('eBayMultiVariant.updateTitle')}
              </span>
            </span>
          </div>

          <div className="product-header-buttons">
            <PublishVariantToEbayButton
              multiVariant={multiVariantProduct?.data}
              getMultiVariant={getMultiVariantProduct}
            />
          </div>
        </div>

        <form>
          <Grid container spacing={2}>
            <Grid item xs={4} style={{marginTop: '15px'}}>
              <FormControl variant="outlined" className={classes.variantRow} disabled>
                <AutocompleteComponent
                  id="mainProduct"
                  placeholder={t('eBayMultiVariant.mainProduct')}
                  label={t('eBayMultiVariant.mainProduct')}
                  defaultValue={mainProductId}
                  onChange={onMainProductChange}
                  endpoint="product_autocomplete_list"
                  disabled={id != undefined}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl variant="outlined" className={classes.variantRow}>
                <TextField
                  className="input"
                  size="small"
                  variant="outlined"
                  label={t('eBayMultiVariant.title')}
                  fullWidth
                  value={formData.title}
                  onChange={(e: any) => setFormData({...formData, title: e.target.value})}
                  margin="normal"
                />

                <DescriptionCounter text={formData.title || ''} maxChars={config?.multiVariantTitleMaxChars} />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl variant="outlined" className={classes.variantRow}>
                <div className={classes.imageUploadContainer}>
                  <label htmlFor="image-upload">
                    <Button variant="outlined" component="span" className={classes.imageUploadButton}>
                      {t('eBayMultiVariant.chooseImage')}
                    </Button>
                    <input
                      accept="image/*"
                      className={classes.imageUpload}
                      id="image-upload"
                      type="file"
                      onChange={handleImageUpload}
                    />
                  </label>
                  {formData.uploadedImage && (
                    <Box className={classes.imageBox}>
                      <img src={formData.uploadedImage} alt="Uploaded" className={classes.uploadedImage} />
                    </Box>
                  )}
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.variantRow}>
                <CustomImageGallery
                  additionalImages={formData.additionalImages as any}
                  onImageChange={(newImages: any) => setFormData({...formData, additionalImages: newImages})}
                />
              </FormControl>
            </Grid>

            <Grid item xs={mainProduct?.productInformation.compatibility === ProductCompatibility.COMPATIBLE ? 3 : 4}>
              <FormControl variant="outlined" className={classes.variantRow}>
                <TextField
                  style={{margin: '0 auto'}}
                  className="input"
                  size="small"
                  variant="outlined"
                  label={t('eBayMultiVariant.inventoryItemGroupKey')}
                  fullWidth
                  value={formData.inventoryItemGroupKey}
                  margin="normal"
                  disabled={true}
                />
              </FormControl>
            </Grid>

            <Grid item xs={mainProduct?.productInformation.compatibility === ProductCompatibility.COMPATIBLE ? 3 : 4}>
              <FormControl variant="outlined" className={classes.variantRow}>
                <InputLabel style={{height: '38px'}} id="color-label">
                  {t('eBayMultiVariant.selectColors')}
                </InputLabel>
                <Select
                  style={{height: '38px'}}
                  labelId="color-label"
                  id="color-select"
                  multiple
                  className="input"
                  value={formData.selectedColors}
                  onChange={(e: any) => setFormData({...formData, selectedColors: e.target.value as string[]})}
                  label={t('eBayMultiVariant.selectColors')}
                  renderValue={(selected: any) => (
                    <div>
                      {(selected as string[]).map((color) => (
                        <Chip key={color} label={color} />
                      ))}
                    </div>
                  )}
                >
                  {colors.map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {mainProduct?.productInformation.compatibility === ProductCompatibility.COMPATIBLE && (
              <Grid item xs={3}>
                <FormControl variant="outlined" className={classes.variantRow}>
                  <InputLabel style={{height: '38px'}} id="color-label">
                    {t('eBayMultiVariant.selectedCompatibleType')}
                  </InputLabel>
                  <Select
                    style={{height: '38px'}}
                    labelId="color-label"
                    id="color-select"
                    className="input"
                    value={formData.selectedCompatibleType}
                    onChange={(e: any) => setFormData({...formData, selectedCompatibleType: e.target.value})}
                    label={t('eBayMultiVariant.selectedCompatibleType')}
                    renderValue={(selected: any) => selected}
                  >
                    {compatibleTypes.map((color) => (
                      <MenuItem key={color} value={color}>
                        {color}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={mainProduct?.productInformation.compatibility === ProductCompatibility.COMPATIBLE ? 3 : 4}>
              <FormControl variant="outlined" className={classes.variantRow}>
                <TextField
                  style={{margin: '0 auto'}}
                  className="input"
                  size="small"
                  variant="outlined"
                  placeholder={t('eBayMultiVariant.manufacturerNumbers')}
                  label={t('eBayMultiVariant.manufacturerNumbers')}
                  fullWidth
                  value={formData.manufacturerNumbers}
                  margin="normal"
                  onChange={(e) => {
                    setFormData({...formData, manufacturerNumbers: e.target.value});
                  }}
                />
                <DescriptionCounter
                  text={formData.manufacturerNumbers || ''}
                  maxChars={config?.multiVariantManufacturerMaxChars}
                />
              </FormControl>
            </Grid>

            {!createItemRes.loading && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    {t('eBayMultiVariant.possibleProducts')} {selectedVariationsInfo()}
                  </Typography>
                  <div className={classes.chipContainer}>
                    <>
                      {possibleProducts?.map((variant: Variant, index: number) => {
                        return (
                          <Grid
                            container
                            key={index}
                            style={{
                              border: '1px solid rgb(193, 199, 208)',
                              margin: '0px',
                            }}
                            spacing={1}
                          >
                            <Grid item xs={6} style={{paddingTop: '8px'}}>
                              <Chip
                                style={{maxWidth: '100%'}}
                                key={index}
                                label={variant.title}
                                clickable
                                color={
                                  formData.selectedVariations.find((x) => x.product_id === variant.product_id)
                                    ? 'primary'
                                    : 'default'
                                }
                                onClick={() => handleToggleVariation(variant)}
                              />
                            </Grid>

                            <Grid item xs={6} style={{paddingTop: '1px', display: 'flex', alignItems: 'center'}}>
                              <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                <TextField
                                  className="input"
                                  size="small"
                                  variant="outlined"
                                  value={formData.selectedProducts?.[variant.product_id]?.title || variant.aliasTitle}
                                  style={{marginRight: '10px', flex: 1}}
                                  onChange={(e: any) => {
                                    updateSelectedProductTitle(e.target.value, variant);
                                  }}
                                />
                                <DescriptionCounter
                                  text={formData.selectedProducts?.[variant.product_id]?.title || variant.aliasTitle}
                                  maxChars={config?.multiVariantChildMaxChars}
                                />
                                <Button
                                  variant="contained"
                                  className={'blue-button'}
                                  style={{marginRight: '10px'}}
                                  onClick={() => updateVariantsWithTitle(variant)}
                                >
                                  {t('eBayMultiVariant.update')}
                                </Button>
                              </div>
                            </Grid>
                            <Grid item xs={6} style={{paddingTop: '5px'}}>
                              <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                <TextField
                                  label="Price"
                                  type="number"
                                  inputProps={{step: 0.01}}
                                  value={formData.selectedProducts?.[variant.product_id]?.fixedPrice || 0}
                                  onChange={(e: any) => {
                                    updateSelectedProductPrice(+e.target.value, variant);
                                  }}
                                  variant="outlined"
                                />
                                <Button
                                  variant="contained"
                                  className={'blue-button'}
                                  style={{marginRight: '10px'}}
                                  onClick={() => handlePriceChange(variant)}
                                >
                                  {t('eBayMultiVariant.update')}
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <span>*Click on Submit to sync the prices and other changes to eBay</span>
                    </>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    {t('eBayMultiVariant.possiblePrinterModels')} {selectedVariationsInfo()}
                  </Typography>
                  <div className={classes.chipContainer}>
                    {mainProduct?.printerModels?.map((model, index) => (
                      <Chip
                        key={index}
                        label={model}
                        clickable
                        color={formData.selectedPrinterModels?.includes(model) ? 'primary' : 'default'}
                        onClick={() => handleMainProductPrinterModelChange(model)}
                      />
                    ))}
                  </div>
                </Grid>
              </>
            )}
          </Grid>

          {!createItemRes?.loading && (
            <>
              <Typography variant="h6" gutterBottom>
                {selectedVariationsInfo()}
              </Typography>

              <div className={classes.selectedVariationsScrollContainer}>{memoizedSelectedVariations}</div>
            </>
          )}

          {createItemRes?.loading && <LinearProgress />}
          <Button
            variant="contained"
            className={!isDisabled ? 'blue-button' : 'inactive-button'}
            onClick={handleSubmit}
            style={{marginTop: '16px', float: 'right'}}
            disabled={isDisabled}
          >
            {t('general.submit')}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
