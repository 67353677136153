import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {IPricingFactors, IShopwareVariable} from '../../types/ShopConfig/IShopConfig';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {ShopType} from '../../types/Orders/ShopType';
import EditIcon from '@material-ui/icons/Edit';

const ShopwarePricing = () => {
  const [pricingFactorsData, getPricingFactorsData] = useFetch<any>('pricing_get');
  const [sortedProducts, sortProducts] = useState(null);
  const [sendUpdatePrices, sendUpdates] = useFetch<any>('pricing_update');
  const [sendUpdatedShippingPricesResponse, sendUpdatedShippingPrices] = useFetch<any>('pricing_update_shipping');
  const [shopConfigRes, updateShopConfig] = useStorlessFetch('pricing_shopware_shop_tax_update');
  const [shopConfigFactorGroups, getShopConfigFactorGroups] = useFetch<any>('shop_config_factors');
  const [shopFactorGroups, setShopFactorGroups] = useState<IPricingFactors[]>(null as any);
  const [pricingFactorGroup, setPricingFactorGroup] = useState<IPricingFactors>(null as any);
  const [pricingFactors, setPricingFactors] = useState<IShopwareVariable[]>([]);
  const [csvFileResponse, getCsvFile] = useStorlessFetch('get_shopware_differences_csv');
  const [editing, setEditing] = useState(false);
  const [configObject, setConfigObject] = useState<any>(null);

  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (csvFileResponse.data) {
      const url = window.URL.createObjectURL(new Blob([csvFileResponse.data]));
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.style.display = 'none';
      link.setAttribute('download', `differences - ${pricingFactorGroup.shop}.csv`);

      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }

    return () => {
      csvFileResponse.data = null;
    };
  }, [csvFileResponse]);

  useEffect(() => {
    getShopConfigFactorGroups();
  }, []);

  useEffect(() => {
    if (shopConfigFactorGroups.data && !pricingFactorGroup) {
      setShopFactorGroups(shopConfigFactorGroups.data);
      setPricingFactorGroup(shopConfigFactorGroups.data[0]);
    }
  }, [shopConfigFactorGroups]);

  useEffect(() => {
    if (pricingFactorGroup) {
      getPricingFactorsData({id: pricingFactorGroup.id, type: pricingFactorGroup.type});
      setConfigObject({
        taxPercentage: pricingFactorGroup.taxPercentage,
        priceType: pricingFactorGroup.priceType,
      });
    }
  }, [pricingFactorGroup]);

  useEffect(() => {
    if (pricingFactorsData.data) {
      setPricingFactors(pricingFactorsData.data);
    }
  }, [pricingFactorsData]);

  useEffect(() => {
    if (pricingFactors) {
      const data = groupBy(pricingFactors, 'compatibility');
      sortProducts(data);
    }
  }, [pricingFactors]);

  useEffect(() => {
    if (sendUpdatePrices.error) {
      dispatch(appActions.showSnackBar({text: sendUpdatePrices.error.name, options: {severity: 'error'}}));
    }

    if (sendUpdatePrices.loaded && !sendUpdatePrices.error) {
      dispatch(appActions.showSnackBar({text: t('pricing.dataSent'), options: {severity: 'success'}}));
    }
  }, [sendUpdatePrices]);

  useEffect(() => {
    if (sendUpdatedShippingPricesResponse.error) {
      dispatch(
        appActions.showSnackBar({
          text: sendUpdatedShippingPricesResponse.error.name,
          options: {severity: 'error'},
        }),
      );
    }

    if (sendUpdatedShippingPricesResponse.loaded && !sendUpdatedShippingPricesResponse.error) {
      dispatch(appActions.showSnackBar({text: t('pricing.dataSent'), options: {severity: 'success'}}));
    }
  }, [sendUpdatedShippingPricesResponse]);

  useEffect(() => {
    if (shopConfigRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setEditing(false);
    } else if (shopConfigRes.error) {
      dispatch(appActions.showSnackBar({text: shopConfigRes.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      shopConfigRes.data = null;
      shopConfigRes.error = null;
    };
  }, [shopConfigRes]);

  const groupBy = (items: any[], property: any) => {
    return items.reduce((accumulator: any, current: any) => {
      const key = current[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(current);
      return accumulator;
    }, {});
  };

  const sendPrices = (item: any) => {
    if (item.compatibility === 'undefined') {
      item.compatibility = undefined;
    }
    item.shopConfigId = pricingFactorGroup.id;
    sendUpdates(item);
  };

  const productTypeSortCondition = (a: any, b: any) => (a[0] > b[0] ? 1 : -1);
  const manufacturerConditionSort = (a: any, b: any) => (a.manufacturer > b.manufacturer ? 1 : -1);

  const onShopConfigChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const shopFactorGroup = shopFactorGroups.find(
      (item: IPricingFactors) => item.id + item.type === e.target.value,
    ) as any;
    setPricingFactorGroup(shopFactorGroup);
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    sendUpdatedShippingPrices({...values, shopConfigId: pricingFactorGroup.id});
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_SHOP_SHIPPING_DATA, {
        title: pricingFactorGroup.shop || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        usedShippingFee: pricingFactorGroup.usedShippingFee ?? 0,
        freeShippingLimit: pricingFactorGroup.freeShippingLimit ?? 0,
        actualShippingFee: pricingFactorGroup.actualShippingFee ?? 0,
        shopConfigId: pricingFactorGroup.id,
      }),
    );
  };

  const getDifferences = async () => {
    const shopName = pricingFactorGroup.shop === ShopType.DRUCKDICHAUS ? 'shop1' : 'shop2';

    getCsvFile({shopName});
  };

  const handleUpdateShopFactors = async () => {
    dispatch(
      modalActions.addModal(MODALS.UPDATE_SHOP_FACTORS, {
        featureName: pricingFactorGroup.shop,
        shopConfigId: pricingFactorGroup.id,
        shopwareType: pricingFactorGroup.type,
        callback: () => {
          getPricingFactorsData({id: pricingFactorGroup.id, type: pricingFactorGroup.type});
          dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const updateObjectState = (value: any, fieldName: string, nestedObjectName?: string) => {
    setConfigObject((prevState: any) => {
      const updateData = nestedObjectName
        ? {
            [nestedObjectName]: {
              ...prevState[nestedObjectName],
              [fieldName]: value,
            },
          }
        : {[fieldName]: value};

      return {...prevState, ...updateData};
    });
  };

  const IconEditButton = () => (
    <IconButton
      onClick={() => {
        setEditing((prevState) => {
          return !prevState;
        });
      }}
    >
      <EditIcon htmlColor={editing ? '#5cabef' : 'none'} style={{fontSize: 20}} />
    </IconButton>
  );

  const onConfigObjectSave = () => {
    updateShopConfig({
      shopConfigId: pricingFactorGroup.id,
      ...configObject,
    });
  };

  return shopFactorGroups && configObject ? (
    <div className="pricing">
      <span style={{display: 'inline-flex', alignItems: 'center'}}>
        <span>Shop:</span>
        <RadioGroup
          row
          aria-label="Shop"
          name="ShopConfigs"
          onChange={onShopConfigChange}
          defaultValue={shopFactorGroups[0].id + shopFactorGroups[0].type}
          title="Shop"
        >
          {shopFactorGroups.map((item: IPricingFactors) => (
            <span key={item.shop + item.type}>
              <Radio value={item.id + item.type} /> {item.shop} {item.additionalType ? `(${item.additionalType})` : ''}
            </span>
          ))}
        </RadioGroup>
      </span>

      {pricingFactorGroup && (
        <>
          <div className="flex">
            <Button variant="contained" className="blue-button" onClick={() => handleClick()}>
              {t('shopwarePricing.changeShippingInformationButton')}
            </Button>
            {pricingFactorGroup.type === 'shopware' && (
              <Button variant="contained" className="blue-button" onClick={() => getDifferences()}>
                {t('shopwarePricing.getDifferences')}
              </Button>
            )}
            <Button variant="contained" color="primary" className="blue-button" onClick={handleUpdateShopFactors}>
              {t('pricing.updateShopFactors')}
            </Button>
          </div>

          <div className="order-details">
            <div className="order-info">
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={6}>
                  <div className="order-info-title">Config</div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  alignContent="flex-end"
                  alignItems="flex-end"
                  style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
                >
                  <IconEditButton />
                  {editing && (
                    <Button className="blue-button" variant="contained" onClick={onConfigObjectSave}>
                      {t('general.save')}
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">{t('pricing.taxPercentage')}</span>
                  {editing ? (
                    <TextField
                      className="product-info-input"
                      variant="standard"
                      size="small"
                      type="number"
                      value={pricingFactorsData?.taxPercentage}
                      InputProps={{inputProps: {min: 0, step: 0.01}}}
                      onChange={(event) => {
                        updateObjectState(+event.target.value, 'taxPercentage');
                      }}
                    />
                  ) : (
                    <div className="order-info-label">{configObject?.taxPercentage}</div>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                  <span className="order-info-label">{t('pricing.priceType')}</span>
                  <div className="product-info-input">
                    <FormControl component="fieldset" disabled={!editing}>
                      <RadioGroup
                        row
                        aria-label="priceType"
                        name="priceType"
                        value={configObject?.priceType}
                        onChange={(event) => updateObjectState(event.target.value, 'priceType')}
                      >
                        <FormControlLabel value="net" control={<Radio />} label="Net price" />
                        <FormControlLabel value="gross" control={<Radio />} label="Gross price" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="order-info">
              <div className="pricing">
                {Object.entries(sortedProducts || [])
                  .sort(([key, value], [key2, value2]) => {
                    return key2.localeCompare(key);
                  })
                  .map((item: any, index: any) => (
                    <Accordion className="productsContent" key={pricingFactorGroup.shop + index} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h1 className="productType" key={pricingFactorGroup.shop + pricingFactorGroup.type + index}>
                          {item[0] !== 'undefined' ? item[0] : t('pricing.undefined')}
                        </h1>
                      </AccordionSummary>
                      <div>
                        {(Object.entries(groupBy(item[1], 'productType')) || [])
                          .sort(productTypeSortCondition)
                          .map((itemElement: any, inx: any) => (
                            <Accordion key={pricingFactorGroup.shop + pricingFactorGroup.type + inx}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <h2
                                  className="productType"
                                  key={pricingFactorGroup.shop + pricingFactorGroup.type + index}
                                  style={{width: '100%'}}
                                >
                                  {itemElement[0]}
                                </h2>
                              </AccordionSummary>
                              <div className="productPricing productHead">
                                <p className="manufacturer">{t('pricing.manufacturer')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.shopFactor')}</p>
                                <p></p>
                              </div>
                              {itemElement[1]
                                .sort(manufacturerConditionSort)
                                .map((singleProduct: IShopwareVariable, index: any) => (
                                  <div
                                    className="productPricing"
                                    key={pricingFactorGroup.shop + pricingFactorGroup.type + index}
                                  >
                                    <p className="manufacturer">{singleProduct.manufacturer}</p>
                                    <p style={{textAlign: 'right'}}>
                                      <input
                                        id={'shopFactor' + index}
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        value={singleProduct.shopFactor}
                                        onChange={(e: any) => {
                                          const newPricingFactors = [...pricingFactors];
                                          const pricingFactor = newPricingFactors.find(
                                            (item: IShopwareVariable) =>
                                              item.productType === singleProduct.productType &&
                                              item.manufacturer === singleProduct.manufacturer &&
                                              item.compatibility === singleProduct.compatibility,
                                          );
                                          if (pricingFactor) {
                                            pricingFactor.shopFactor = e.target.value;
                                            setPricingFactors(newPricingFactors);
                                          }
                                        }}
                                      />
                                    </p>
                                    <p>
                                      <Button
                                        variant="contained"
                                        className="blue-button"
                                        onClick={() => {
                                          sendPrices({
                                            shopConfig: pricingFactorGroup.shop,
                                            type: pricingFactorGroup.type,
                                            manufacturer: singleProduct.manufacturer,
                                            productType: itemElement[0],
                                            compatibility: item[0],
                                            shopFactor: singleProduct.shopFactor,
                                            dealerFactor: singleProduct.dealerFactor,
                                          });
                                        }}
                                      >
                                        {t('pricing.submitChanges')}
                                      </Button>
                                    </p>
                                  </div>
                                ))}
                            </Accordion>
                          ))}
                      </div>
                    </Accordion>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
};
export default ShopwarePricing;
