import {Card, Box, Tabs, Tab, Grid} from '@material-ui/core';
import {TabContext, TabPanel} from '@material-ui/lab';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ExcludedSuppliersTable from '../../components/Product/ExcludedSuppliersTable';
import ProductActivityLog from '../../components/Product/ProductActivityLog';
import ProductMultiBoxOrdersTable from '../../components/Product/ProductMultiBoxOrdersTable';
import ProductOrdersTable from '../../components/Product/ProductOrdersTable';
import ProductPrinterIds from '../../components/Product/ProductPrinterIds';
import ProductRequirementsInfo from '../../components/Product/ProductRequirementsInfo';
import ProductSkus from '../../components/Product/ProductSkus';
import ProductSupplierOrders from '../../components/Product/ProductSupplierOrders';
import ShopDescriptions from '../../components/Product/ShopDescriptions/ShopDescriptions';
import SupplierArticlesTable from '../../components/Product/SupplierArticlesTable';
import useUser from '../../hooks/user';
import {ShopType} from '../../types/Orders/ShopType';
import {IProduct} from '../../types/Product/IProduct';
import {USER_ROLE} from '../../types/User/UserRole';
import ProductStatusTable from '../../components/Product/ProductStatusTable';
import ErrorLogsTable from '../../components/Product/ErrorLogTable';
import IncludedSuppliersTable from '../../components/Product/IncludedSuppliersTable';
import DefectRate from '../../components/Product/DefectRate';

interface IProps {
  product: IProduct;
  id: string;
  getProductDetails: () => void;
}

const ProductDetailsTabContainer = ({product, getProductDetails, id}: IProps) => {
  const [tabValue, setTabValue] = React.useState<any>();
  const handleTabChange = (event: any, newValue: any) => setTabValue(newValue);
  const user = useUser() as any;
  const hasAdminPermission = user?.roles?.some((role: any) => [USER_ROLE.ADMIN, USER_ROLE.STANDARD].includes(role));
  const hasProductDescriptionPermission = user?.roles?.some((role: any) =>
    [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS].includes(role),
  );
  const {t} = useTranslation();

  const ShopDescriptionsTable = () => (
    <Grid container item sm={12} xs={12}>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <ShopDescriptions productId={product.productId} label={ShopType.DRUCKDICHAUS} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ShopDescriptions productId={product.productId} label={ShopType.SUPPLIES_PARTNER} />
        </Grid>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    setTabValue(hasAdminPermission ? '0' : '9');
  }, []);

  return tabValue !== undefined ? (
    <Card variant="outlined">
      <TabContext key="tabContext" value={tabValue}>
        <Box>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabValue}
            onChange={handleTabChange}
            selectionFollowsFocus
            TabIndicatorProps={{style: {display: 'none'}}}
            className="tab-container"
          >
            {hasAdminPermission && (
              <Tab
                className={tabValue == 0 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.supplierArticles')}
                value="0"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 1 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.excludedSuppliers')}
                value="1"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 2 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.customerOrders')}
                value="2"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 3 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.multiBoxOrders')}
                value="3"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 4 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.supplierOrders')}
                value="4"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 5 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.printerIds')}
                value="5"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 6 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.skus')}
                value="6"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 7 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.activityLog')}
                value="7"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 8 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.additionalInfo')}
                value="8"
              />
            )}
            {hasProductDescriptionPermission && (
              <Tab
                className={tabValue == 9 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.descriptions')}
                value="9"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 10 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.shops')}
                value="10"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 11 ? 'tab-item-active' : 'tab-item'}
                label={t('general.errorLogs')}
                value="11"
              />
            )}
            {hasAdminPermission && (
              <Tab
                className={tabValue == 12 ? 'tab-item-active' : 'tab-item'}
                label={t('productDetails.defectRateTitle')}
                value="12"
              />
            )}
          </Tabs>
        </Box>
        <TabPanel value="0" className="tab-panel">
          {hasAdminPermission && <SupplierArticlesTable product={product} />}
        </TabPanel>
        <TabPanel value="1" className="tab-panel">
          {hasAdminPermission && (
            <>
              <ExcludedSuppliersTable product={product} getProductDetails={getProductDetails} />
              <div style={{marginBottom: '15px'}}></div>
              <IncludedSuppliersTable product={product} getProductDetails={getProductDetails} />
            </>
          )}
        </TabPanel>
        <TabPanel value="2" className="tab-panel">
          {hasAdminPermission && <ProductOrdersTable productId={id} />}
        </TabPanel>
        <TabPanel value="3" className="tab-panel">
          {hasAdminPermission && <ProductMultiBoxOrdersTable productId={id} />}
        </TabPanel>
        <TabPanel value="4" className="tab-panel">
          {hasAdminPermission && <ProductSupplierOrders productId={id} />}
        </TabPanel>
        <TabPanel value="5" className="tab-panel">
          {hasAdminPermission && <ProductPrinterIds product={product} />}
        </TabPanel>
        <TabPanel value="6" className="tab-panel">
          {hasAdminPermission && <ProductSkus product={product} />}
        </TabPanel>
        <TabPanel value="7" className="tab-panel">
          {hasAdminPermission && <ProductActivityLog productId={id} />}
        </TabPanel>
        <TabPanel value="8" className="tab-panel">
          {hasAdminPermission && <ProductRequirementsInfo product={product} />}
        </TabPanel>
        <TabPanel value="9" className="tab-panel">
          {hasProductDescriptionPermission && <ShopDescriptionsTable />}
        </TabPanel>
        <TabPanel value="10" className="tab-panel">
          {hasAdminPermission && <ProductStatusTable product={product} getProductDetails={getProductDetails} />}
        </TabPanel>
        <TabPanel value="11" className="tab-panel">
          {hasAdminPermission && (
            <ErrorLogsTable
              entityId={product?._id || ''}
              onDeleteCallBack={() => {
                getProductDetails();
              }}
            />
          )}
        </TabPanel>
        <TabPanel value="12" className="tab-panel">
          {hasAdminPermission && <DefectRate product={product} />}
        </TabPanel>
      </TabContext>
    </Card>
  ) : (
    <></>
  );
};

export default ProductDetailsTabContainer;
