import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const SupplierPayments = () => {
  const {t} = useTranslation();
  return (
    <div className="standard-page" style={{marginTop: '15px'}}>
      <DataTable
        className="order-details__table"
        headers={[
          {
            kind: 'accessor',
            name: t('activityLog.createdAt'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('supplierOrders.supplier'),
            accessor: 'data.supplierName',
            sortable: true,
            type: DataTableItemType.TEXT,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderDetails.invoiceNumbers'),
            accessor: 'data.invoices',
            sortable: true,
            type: DataTableItemType.TEXT,
          },
          {
            kind: 'accessor',
            name: t('supplierOrders.total'),
            accessor: 'data.total',
            sortable: false,
            type: DataTableItemType.NUMBER,
            format: 'price',
          },
        ]}
        endpoint={'activity_log_list'}
        options={{actionType: 'INVOICES_PAID'}}
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('general.supplierPayments')}
      />
    </div>
  );
};

export default SupplierPayments;
