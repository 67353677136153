import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Grid, IconButton, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Remove} from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ChipsInput from '../../components/ChipsInput/ChipsInput';
import ProductDetailsContext from './Context/ProductDetailsContext';
import {useStorlessFetch, useStorlessUpload} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {StockUpdate} from '../../components/Product/StockUpdate';
import {USER_ROLE} from '../../types/User/UserRole';
import withRoleGuard from '../../guards/Role-Guard';
import _ from 'lodash';
import {ICustomField} from '../../types/CustomFields/CustomField';
import {CustomField} from '../../components/Product/CustomField';
import {Util} from '../../modules/api/utils';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';

export const ProductCardDetails = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [updateProductRes, updateProduct] = useStorlessFetch('product_put');
  const [updateShopProductImageRes, updateShopProductImage] = useStorlessFetch('product_shop_image');
  const [getProductRes, getProduct] = useStorlessFetch('product_details');
  const {product, updateProductState, customFields, getProductDetails} = useContext(ProductDetailsContext);
  const [originalData, setOriginalData] = useState<any>(product);
  const {productInformation, shopProps} = product;
  const [editing, setEditing] = useState(false);
  const [updateImageShop, setUpdateImageShop] = useState('');
  const [uploadFile, doUploadFile] = useStorlessUpload('upload_optimized');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (updateProductRes.error) {
      dispatch(appActions.showSnackBar({text: updateProductRes.error?.name, options: {severity: 'error'}}));
    } else if (updateProductRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      getProductDetails({id: product._id});
    }
  }, [updateProductRes]);

  useEffect(() => {
    if (uploadFile.data) {
      const updatedProduct = {
        id: product._id,
        ...(updateImageShop === 'dda' ? {'productInformation.imageDDA': uploadFile.data} : {}),
        ...(updateImageShop === 'sp' ? {'productInformation.imageSP': uploadFile.data} : {}),
      };

      if (updateImageShop === 'dda') {
        productInformation.imageDDA = uploadFile.data;
      }

      if (updateImageShop === 'sp') {
        productInformation.imageSP = uploadFile.data;
      }
      updateShopProductImage(updatedProduct);
    }

    return () => {
      uploadFile.data = null;
      uploadFile.loaded = null;
      uploadFile.error = null;
    };
  }, [uploadFile]);

  useEffect(() => {
    if (getProductRes.data) {
      if (getProductRes.data.updatedAt !== product.updatedAt) {
        dispatch(
          modalActions.addModal(MODALS.CONFIRM_DIALOG, {
            title: t('general.areYouSure'),
            content: t('general.productReload'),
            onYes: () => window.location.reload(),
            onNo: () => dispatch(modalActions.closeModal()),
          }),
        );
      } else {
        updateProductData();
      }
    }

    return () => {
      getProductRes.data = null;
      getProductRes.error = null;
    };
  }, [getProductRes]);

  const updateProductData = () => {
    const fieldsToExclude = [
      'inMultiBoxProductIds',
      'multiBoxProduct',
      'multiBoxProducts',
      'priceOrQuantityUpdated',
      'requirements',
      'supplierArticle',
      'createdAt',
      'updatedAt',
      '__v',
      '_id',
    ];
    const data = _.omit(product, fieldsToExclude);
    if ((data?.calculatedPrice || 0) > 0) {
      if (
        (data?.calculatedPrice || 0) < (data?.averagePrice || 0) ||
        (data?.calculatedPrice || 0) < (data?.cheapestSupplierPrice || 0)
      ) {
        if (confirm(t('productDetails.priceWarning'))) {
          updateProduct({id: product._id, ...data});
        } else {
          updateProductState(originalData.calculatedPrice, 'calculatedPrice');
        }
      } else {
        updateProduct({id: product._id, ...data});
      }
    }
    setEditing(false);
  };

  const IconEditButton = () => (
    <IconButton
      onClick={() => {
        setEditing((prevState) => {
          return !prevState;
        });
      }}
    >
      <EditIcon htmlColor={editing ? '#5cabef' : 'none'} style={{fontSize: 20}} />
    </IconButton>
  );

  const IconEditButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], IconEditButton, false);
  const onProductUpdate = async () => {
    getProduct({id: product._id});
  };

  const handleShopImageUpload = (event: any) => {
    const uploadType = event.currentTarget.dataset.uploadtype;
    setUpdateImageShop(uploadType);

    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    const newName = `${product.productId}_${updateImageShop}.${uploadedFile.name.split('.').pop()}`;
    const fileForUpload = new File([uploadedFile], newName, {type: uploadedFile.type});
    doUploadFile({file: fileForUpload});
  };

  return (
    <div className="product-info">
      <Grid container>
        <Grid item xs={6} sm={12} md={3}>
          <Grid item xs={12} className="product-info-title" style={{marginLeft: '30px'}}>
            {t('productDetails.productInfo')}
          </Grid>
          <div className="product-info-image">
            <img src={`${productInformation?.image ?? '/images/no_image2.webp'}`} />
          </div>
          {!productInformation?.image && (
            <div className="flex" style={{padding: '6px'}}>
              <div style={{padding: '2px'}}>
                <Button
                  style={{marginBottom: '10px', width: '100%'}}
                  variant="contained"
                  color="primary"
                  data-uploadtype="dda"
                  onClick={handleShopImageUpload}
                >
                  DDA Image
                </Button>
                {productInformation?.imageDDA && <img src={productInformation.imageDDA} width={'100%'} />}
              </div>
              <div style={{padding: '2px'}}>
                <Button
                  style={{marginBottom: '10px', width: '100%'}}
                  variant="contained"
                  color="primary"
                  data-uploadtype="sp"
                  onClick={handleShopImageUpload}
                >
                  SP image
                </Button>
                {productInformation?.imageDDA && <img src={productInformation?.imageSP} width={'100%'} />}
              </div>
              <input type="file" ref={inputRef} style={{display: 'none'}} onChange={handleFileChange} />
            </div>
          )}
        </Grid>
        <Grid item xs={6} sm={12} md={3}>
          <Grid item xs={12} className="product-info-title">
            {t('productDetails.productData')}
          </Grid>
          <Grid container>
            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.productType')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.productType || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.productType}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'productType', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.manufacturer')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.manufacturer || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.manufacturer}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'manufacturer', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.compatibility')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.compatibility || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.compatibility}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'compatibility', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.colour')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.colour || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.colour}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'colour', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.doublePackage')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.doublePackage || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.doublePackage}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'doublePackage', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.numberOfItems')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.numberOfItems || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={productInformation.numberOfItems}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => {
                    updateProductState(+event.target.value, 'numberOfItems', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.pageYield')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.pageYield || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.pageYield}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'pageYield', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.numberOfLabels')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.pageYieldContent || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.pageYieldContent}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'numberOfLabels', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.content')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.content || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.content}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'content', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.packType')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.packType || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  value={productInformation.packType}
                  onChange={(event) => {
                    updateProductState(event.target.value, 'packType', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid container>
              <Grid item xs={12} className="product-info-title">
                {t('productDetails.productStatus')}
              </Grid>

              <Grid item xs={12} className="product-info-item">
                <div className="product-info-label">{t('productDetails.shopwareId')}:</div>
                {!editing ? (
                  <div className="product-info-text">{product?.shopwareId || '-'}</div>
                ) : (
                  <TextField
                    className="product-info-input"
                    variant="standard"
                    size="small"
                    value={product.shopwareId}
                    onChange={(event) => {
                      updateProductState(event.target.value, 'shopwareId');
                    }}
                  />
                )}
              </Grid>

              <Grid item xs={12} className="product-info-item">
                <div className="product-info-label">{`${shopProps?.[0].shopName}`}:</div>
                <div className="product-info-text">{shopProps?.[0].status || '-'}</div>
              </Grid>

              <Grid item xs={12} className="product-info-item">
                <div className="product-info-label">{`${shopProps?.[1].shopName}`}:</div>
                <div className="product-info-text">{shopProps?.[1].status || '-'}</div>
              </Grid>

              <Grid item xs={12} className="product-info-item">
                <div className="product-info-label">eBay:</div>
                <div className="product-info-text">
                  {product.eBayProps?.listingId ? t('productDetails.published') : t('productDetails.unPublished')}
                </div>
              </Grid>

              {product.eBayProps?.listingId ? (
                <Grid item xs={12} className="product-info-item">
                  <div className="product-info-label">{t('productDetails.ebayLastPublishedDate')}</div>
                  <div className="product-info-text">
                    {Util.convertUTCDateToLocalDate(product.eBayProps?.lastPublishedDate as any) || ''}
                  </div>
                </Grid>
              ) : null}

              <Grid item xs={12} className="product-info-item">
                <div className="product-info-label">{t('productDetails.disableBechlemSync')}:</div>
                {!editing ? (
                  <div className="product-info-text">{product?.disableBechlemSync ? <CheckIcon /> : <Remove />}</div>
                ) : (
                  <Checkbox
                    color="primary"
                    checked={product?.disableBechlemSync}
                    onChange={(e) => {
                      updateProductState(e.target.checked, 'disableBechlemSync');
                    }}
                  ></Checkbox>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={12} md={3} className="product-vertical-line">
          <Grid container>
            <Grid item xs={12} className="product-info-title">
              {t('productDetails.pricing')}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.calculatedPrice')}:</div>

              {!editing ? (
                <div className="product-info-text">{product?.calculatedPrice || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={product.calculatedPrice}
                  InputProps={{inputProps: {min: 0, step: 0.01}}}
                  onChange={(event) => {
                    updateProductState(+event.target.value, 'calculatedPrice');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.average-price')}:</div>
              <div className="product-info-text">{product?.averagePrice || '-'}</div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className="product-info-title">
              {t('productDetails.stock')}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.stockDDA')}:</div>
              <div className="product-info-text"> {!editing ? product?.stockDDA || '-' : <StockUpdate />}</div>
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.amazonStock')}:</div>
              {!editing ? (
                <div className="product-info-text">{product?.amazonStock || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={product.amazonStock}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => {
                    updateProductState(+event.target.value, 'amazonStock');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.minimumStock')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.minimumStock || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={productInformation.minimumStock}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => {
                    updateProductState(+event.target.value, 'minimumStock', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.minimumStockOrder')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.minimumStockOrder || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={productInformation.minimumStockOrder}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => {
                    updateProductState(+event.target.value, 'minimumStockOrder', 'productInformation');
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.minimumStockReach')}:</div>
              {!editing ? (
                <div className="product-info-text">{productInformation?.minimumStockReach || '-'}</div>
              ) : (
                <TextField
                  className="product-info-input"
                  variant="standard"
                  size="small"
                  type="number"
                  value={productInformation.minimumStockReach}
                  InputProps={{inputProps: {min: 0, step: 1}}}
                  onChange={(event) => {
                    updateProductState(+event.target.value, 'minimumStockReach', 'productInformation');
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="product-info-title">
              {t('general.customFields')}
            </Grid>

            {customFields?.map((field: ICustomField) => {
              return (
                <Grid item xs={12} className="product-info-item" key={field.fieldName}>
                  <div className="product-info-label">{field.fieldName}:</div>
                  <div className="product-info-text">
                    <CustomField type={field.type} value={product?.customFields?.[field.fieldName] ?? ''} />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={6} sm={12} md={3}>
          <Grid container>
            <Grid item xs={6} className="product-info-title">
              {t('productDetails.otherInfo')}
            </Grid>

            <Grid
              item
              xs={6}
              alignContent="flex-end"
              alignItems="flex-end"
              style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
            >
              <IconEditButtonWithGuard />
              {editing && (
                <Button className="blue-button" variant="contained" onClick={onProductUpdate}>
                  {t('general.save')}
                </Button>
              )}
            </Grid>

            <Grid item xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.productId')}:</div>
              <div className="product-info-text">{product?.productId || '-'}</div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className="product-info-title">
              {t('productDetails.mpnInfo')}
            </Grid>
            {!editing &&
              product?.mpns?.map((mpn, index) => {
                return (
                  <Grid key={mpn} item xs={12} className="product-info-item">
                    <div className="product-info-label">{`MPN ${index + 1}`}:</div>
                    <div className="product-info-text">{mpn || '-'}</div>
                  </Grid>
                );
              })}

            {editing && (
              <Grid item xs={12} className="product-info-item">
                <div className="array-item">
                  <ChipsInput
                    value={product?.mpns || []}
                    onChange={(data: any) => {
                      updateProductState(data, 'mpns');
                    }}
                  />
                </div>
              </Grid>
            )}
          </Grid>

          <Grid container>
            <Grid item xs={12} className="product-info-title">
              {t('productDetails.eanInfo')}
            </Grid>
            {!editing &&
              product?.eans?.map((ean, index) => {
                return (
                  <Grid key={ean} item xs={12} className="product-info-item">
                    <div className="product-info-label">{`EAN ${index + 1}`}:</div>
                    <div className="product-info-text">{ean || '-'}</div>
                  </Grid>
                );
              })}

            {editing && (
              <Grid item xs={12} className="product-info-item">
                <div className="array-item">
                  <ChipsInput
                    value={product?.eans || []}
                    onChange={(data) => {
                      updateProductState(data, 'eans');
                    }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
